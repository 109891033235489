import { Box } from "@mui/system";
import { ValidationFilter } from "components/ValidationFilter";
import { DropPlantID } from "../../../../components";

export const MeasurementsFilterSection = (props) => {
  return (
    <Box display="flex" justifyContent="space-evenly ">
      <DropPlantID />
      <ValidationFilter />
    </Box>
  );
};
