import { useMutation, useQueryClient } from "react-query";
import { useTranslation } from 'react-i18next';

import { useAxios } from "api/Axios";
import { useMessagesStore } from "stores";

export const useCreatePlantMutation = (data) => {
  const { t } = useTranslation();
  const axios = useAxios();

  const queryClient = useQueryClient();

  const addMessage = useMessagesStore((store) => store.addMessage);

  const mutation = useMutation(
    (data) => {
      const options = {
        method: "POST",
        data: JSON.stringify(data),
        url: "/app/v1/plants/",
      };

      return axios(options).then((res) => res.data);
    },
    {
      onSuccess: (data) => {
        addMessage(t("mutations_plant_success_created"));

        queryClient.fetchQuery({ queryKey: "filteredPlants" });
        queryClient.fetchQuery({ queryKey: "/app/v1/plants/lotsAndIds" });
      },
      onError: (error) => {
        addMessage(t("mutations_plant_error_created"), "error");
      },
    }
  );

  return mutation;
};
