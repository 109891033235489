import { Box, Divider, List } from "@mui/material";

import { PageContainer } from "components";
import { ProfileSection } from "./components";
import { AlertsSection } from "./components";
//css import
import './profile.css';

export const ProfileView = (props) => {
  const {
    currentUser,
    initialIndicators,
    croppedImage,
    setCroppedImage,
    onProfileSave,
    onIndicatorsSave,
    isLoadingProfile,
    isLoadingIndicators,
  } = props;

  return (
    <PageContainer>
      {currentUser && initialIndicators && (
        <Box display="flex" width="100%">
          <List
            className="profile-list"
          >
            <ProfileSection
              currentUser={currentUser}
              croppedImage={croppedImage}
              setCroppedImage={setCroppedImage}
              onProfileSave={onProfileSave}
              isLoadingProfile={isLoadingProfile}
            />
            <Box marginY="2rem">
              <Divider variant="middle" />
            </Box>
            <AlertsSection
              currentUser={currentUser}
              onIndicatorsSave={onIndicatorsSave}
              initialIndicators={initialIndicators}
              isLoadingIndicators={isLoadingIndicators}
            />
          </List>
        </Box>
      )}
    </PageContainer>
  );
};
