import { useAxios } from "api/Axios";
import { useQuery } from "react-query";

import { useNotificationsStore } from "stores";

export const useUnreadNotificationsQuery = () => {
  const axios = useAxios();
  const setUnreadNotifs = useNotificationsStore(
    (state) => state.setUnreadNotifs
  );

  const query = useQuery(
    "/app/v1/notifications/unread_number",
    () =>
      axios
        .get(`/app/v1/notifications/unread_number`, {
          params: {
            skip: undefined,
            limit: undefined,
            sort: undefined,
            order: undefined,
            filter: JSON.stringify({read: false}),
          },
        })
        .then((res) => res),
    {
      enabled: false,
      onSuccess: (res) => {
        setUnreadNotifs(res.data);
      },
    }
  );
  return query;
};
