import { useEffect, useState } from "react";
import { styled, Box, ClickAwayListener } from "@mui/material";
import { useClickOutside } from "@mantine/hooks";

import Isotipo from "../../assets/images/isotipo/PNG/modal.png";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

export const ShowImage = (props) => {
  const {
    item,
    currentMeasurement,
    setZoom,
    zoom,
    disabledZoom = false,
  } = props;
  const disableZoom = () => setZoom(false);
  const ref = useClickOutside(() => disableZoom);
  const changeZoom = () => {
    if (item.rgb_image && !disabledZoom) setZoom(!zoom);
  };

  useEffect(() => {
    setZoom(false);
  }, [currentMeasurement, setZoom]);

  const [aspectRatio, setAspectRatio] = useState(undefined);

  useEffect(() => {
    if (item?.rgb_image) {
      const image = new Image();
      image.onload = () => {
        if (image.width < image.height) {
          setAspectRatio("4/3");
        } else {
          setAspectRatio(undefined);
        }
      };
      image.src = item.rgb_image;
    }
  }, [item]);

  const MyImage = styled("img")(({ theme, ar }) => {
    return {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      aspectRatio: ar,
      objectFit: "cover",
      height: "100%",
      width: "auto",
      cursor: disabledZoom ? "default" : "zoom-in",
      "&:hover": {
        backgroundColor: "primary.main",
        opacity: [0.9, 0.8, 0.7],
      },
    };
  });

  return (
    <>
      <ClickAwayListener onClickAway={disableZoom}>
        <Box sx={{ position: "relative" }}>
          {zoom ? (
            <Box
              display="flex"
              justifyContent="center"
              alignContent="center"
              height="550px"
              width="700"
              sx={{ cursor: "move" }}
              ref={ref}
            >
              <TransformWrapper className="transform-wrapper">
                <TransformComponent height="550px" width="700">
                  <img
                    src={item.rgb_image}
                    alt="test"
                    height="550px"
                    width="auto"
                  />
                </TransformComponent>
              </TransformWrapper>
            </Box>
          ) : (
            <Box
              height="250px"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <MyImage
                src={item.rgb_image ? item.rgb_image : Isotipo}
                alt=""
                ar={aspectRatio}
                onClick={() => changeZoom()}
              ></MyImage>
            </Box>
          )}
        </Box>
      </ClickAwayListener>
    </>
  );
};
