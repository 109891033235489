import { LoginForm } from "./components/LoginForm";
import { Box, Paper } from "@mui/material";

import { createTheme, ThemeProvider, styled } from "@mui/material/styles";

import LogoFondoClaro from "assets/images/logotipo/transparente/logo_fondoclaro.png";
//css import
import './login.css';

const loginTheme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#7a837d",
    },
    secondary: {
      main: "#bcfa90",
    },
    background: {
      default: "#f0f3f8",
    },
  },
  typography: {
    fontFamily: "Lato",
  },
});

const MainContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: "1rem",
  height: "100vh",
  padding: "1rem 2rem",
  background: "linear-gradient(.38turn,rgb(236 215 80) 30%,rgb(67 148 34))",
}));

const CustomPaper = styled(Paper)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: "1rem",

  padding: "2rem",
  paddingTop: "3rem",
  maxWidth: 500,
  borderRadius: "1rem",
  background: "rgb(235 245 225)",
}));

const LogoContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
}));

const LoginView = ({ handleLogin, isLoading, isError, setIsError }) => {
  return (
    <ThemeProvider theme={loginTheme}>
      <MainContainer>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          gap="5rem"
        >
          <CustomPaper>
            <LogoContainer>
              <img
                className="max-mid-w"
                src={LogoFondoClaro}
                alt="Daiana Logo"
              />
            </LogoContainer>

            <LoginForm
              handleLogin={handleLogin}
              isLoading={isLoading}
              isError={isError}
              setIsError={setIsError}
            />
          </CustomPaper>
        </Box>
      </MainContainer>
    </ThemeProvider>
  );
};

export default LoginView;
