import { useEffect, useState } from "react";
import { DashboardView } from "./DashboardView";
//css imports
import './dashboard.css';
import { useStatisticsStore } from "stores";

import {
  useHealthyPercentagesQuery,
  useMainPercentagesQuery,
  useTotalCountDiseasePercentagesQuery,
  useTotalCountsQuery,
} from "api/queries";

export const DashboardContainer = () => {
  // Stats for global state ------------------------------------------------------------
  const {
    mainPercentages,
    totalUploadedImages,
    diseasePercentagesTags,
    totalNumberOfImagesUploadedChartData,
    healthyPercentagesChartData,
    timeInterval,
    setTimeInterval,
  } = useStatisticsStore();

  const { start_timestamp, end_timestamp } = timeInterval;

  const [dateRangeValue, setDateRangeValue] = useState([
    new Date(start_timestamp),
    new Date(end_timestamp),
  ]);

  useEffect(() => {
    const [start, end] = dateRangeValue;
    if (start && end && start < end) {
      setTimeInterval(
        start.setHours(0, 0, 0, 0),
        end.setHours(23, 59, 59, 999)
      );
    }
  }, [setTimeInterval, dateRangeValue]);

  // Queries --------------------------------------------------------------------------

  const { refetch: refetchTodayMetrics } = useMainPercentagesQuery({
    start_timestamp,
    end_timestamp,
  });
  const { refetch: refetchTodayMeasurements } =
    useTotalCountDiseasePercentagesQuery({
      start_timestamp,
      end_timestamp,
    });
  const { refetch: refetchTotalCountsQuery } = useTotalCountsQuery({
    start_timestamp,
    end_timestamp,
  });
  const { refetch: refetchHealthyPercentagesQuery } =
    useHealthyPercentagesQuery({
      start_timestamp,
      end_timestamp,
    });

  useEffect(() => {
    refetchTodayMetrics();
    refetchTodayMeasurements();
    refetchTotalCountsQuery();
    refetchHealthyPercentagesQuery();
  }, [
    refetchTodayMetrics,
    refetchTodayMeasurements,
    refetchTotalCountsQuery,
    refetchHealthyPercentagesQuery,
    timeInterval,
  ]);
  return (
    <DashboardView
      mainPercentages={mainPercentages}
      totalUploadedImages={totalUploadedImages}
      diseasePercentagesTags={diseasePercentagesTags}
      healthyPercentagesChartData={healthyPercentagesChartData}
      dateRangeValue={dateRangeValue}
      setDateRangeValue={setDateRangeValue}
      totalNumberOfImagesUploadedChartData={
        totalNumberOfImagesUploadedChartData
      }
    />
  );
};
