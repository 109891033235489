import { useState } from "react";

import { NoSelectedElemetsModal } from "components/modals/NoSelectedElemetsModal";

export const ToogleNotificationButton = (props) => {
  const {
    notifications,
    markNotificationsAsRead,
    read = false,
    title,
    Icon,
  } = props;

  const onClick = async () => {
    
    if (notifications.length > 0) {
      markNotificationsAsRead({ notifications, read });
    } else {
      setOpen(true);
    }
  };
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  return (
    <>
      <div onClick={onClick} className="cancelButton, plantButtons">
        <>{Icon}</>
        <p className="download-btn-text">{title}</p>
      </div>
      <NoSelectedElemetsModal
        open={open}
        handleClose={handleClose}
        elementType="plants"
      />
    </>
  );
};
