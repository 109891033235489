import { Typography } from "@mui/material";
import { Box } from "@mui/system";

export const Section = ({ title, children }) => {
  return (
    <Box paddingX="1rem">
      <Typography
        variant="h4"
        color="primary"
        fontWeight="600"
        fontSize="1.5rem"
        paddingY="1rem"
      >
        {title}
      </Typography>
      {children}
    </Box>
  );
};
