import { Box, Divider } from "@mui/material";

import { PageContainer } from "components";

import { RecordsFilterSection, RecordsTableSection } from "./components";

export const RecordsView = (props) => {
  const {
    setDateRangeValue,
    dateRangeValue,
    filteredRecords,
    totalRecords,
    currentPage,
    setCurrentPage,
    setSelectionModel,
    setSorting
  } = props;
  return (
    <PageContainer>
      <Box width="100%" maxHeight="100%" padding="1rem">
        <RecordsFilterSection />
        <Box marginY="1rem">
          <Divider variant="middle" />
        </Box>
        <RecordsTableSection
          setDateRangeValue={setDateRangeValue}
          dateRangeValue={dateRangeValue}
          filteredRecords={filteredRecords}
          totalRecords={totalRecords}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          setSelectionModel={setSelectionModel}
          setSorting={setSorting}
        />
      </Box>
    </PageContainer>
  );
};
