import "./measuremenTags.css";
import { useTranslation } from "react-i18next";
import { getKeyByValue } from "utils/traslate";

const MeasuremenTags = (props) => {
  const { tags } = props;
  const tagsNames = tags.map((tag) => tag.name);
  const { t } = useTranslation();

  const indexD = tagsNames.findIndex((tag) => tag === "disease");
  const indexH = tagsNames.findIndex((tag) => tag === "healthy");

  if (indexD !== -1) {
    tagsNames.splice(indexD, 1);
    tagsNames.splice(0, 0, "disease");
  }
  if (indexH !== -1) {
    tagsNames.splice(indexH, 1);
    tagsNames.splice(0, 0, "healthy");
  }

  return (
    <>
      <div className="tags-measur">
        {tagsNames.filter((el, index) => (index < 3)).map((tag) => {
          if (tag === "disease" || tag === "healthy")
            return <div className={`tag-${tag} text-${tag}`}>{t(getKeyByValue(tag))}</div>;
          return <div className="tag">{t(getKeyByValue(tag))}</div>;
        }
        )}
      </div>
      {tagsNames.length > 3 && (
        <div className="tag tag-bold">+{tagsNames.length - 3}</div>
      )}
    </>
  );
};

export default MeasuremenTags;
