import { useAxios } from "api/Axios";
import { useQuery } from "react-query";
import { useTagsStore } from "stores";

export const useTagsInternationalizationQuery = () => {
  const axios = useAxios();
  const setTagsInternationalizationDict = useTagsStore(
    (store) => store.setTagsInternationalizationDict
  );

  const query = useQuery(
    "/app/v1/app_conf/tags_internationalization_dict",
    () =>
      axios
        .get("/app/v1/app_conf/tags_internationalization_dict")
        .then((res) => res),
    {
      enabled: false,
      onSuccess: (res) => {
        setTagsInternationalizationDict(res.data);
      },
      onError: (res) => {
        setTagsInternationalizationDict({});
      },
    }
  );
  return query;
};
