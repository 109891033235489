import { Box } from "@mui/system";
import { DropPlantID } from "../../../../components";

export const RecordsFilterSection = (props) => {
  return (
    <Box display="flex" justifyContent="space-evenly ">
      <DropPlantID />
    </Box>
  );
};
