import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { ShowPlantModal } from "components/modals/ShowPlantModal";
import { NoSelectedElemetsModal } from "components/modals/NoSelectedElemetsModal";
import { usePlantsStore } from "stores";

export const ShowPlantsButton = (props) => {
  const { t } = useTranslation();
  const checkedPlants = usePlantsStore((store) => store.checkedPlants);
  const [open, setOpen] = useState(false);
  const [noPlantOpen, setNoPlantOpen] = useState(false);
  
  const onClick = async () => {
    if (checkedPlants.length > 0) setOpen(true);
    else setNoPlantOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleNoPlantClose = () => {
    setNoPlantOpen(false);
  };
  return (
    <>
      <div onClick={onClick} className="cancelButton, plantButtons">
        <p>{t("plants_ShowPlantsButton_view")}</p>
      </div>
      <ShowPlantModal open={open} handleClose={handleClose}/>
      <NoSelectedElemetsModal
        open={noPlantOpen}
        handleClose={handleNoPlantClose}
        elementType="plants"
      />
    </>
  );
};
