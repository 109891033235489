import { useAxios } from "api/Axios";
import { useQuery } from "react-query";
import { useStatisticsStore } from "stores";

export const useMainPercentagesQuery = (params = {}) => {
  const axios = useAxios();

  const setMainPercentages = useStatisticsStore(
    (store) => store.setMainPercentages
  );
  const resetMainPercentages = useStatisticsStore(
    (store) => store.resetMainPercentages
  );

  const start = new Date();
  start.setHours(0, 0, 0, 0);

  const start_timestamp = params?.start_timestamp || start.getTime();
  const end_timestamp = params?.end_timestamp || new Date().getTime();
  const timezone =
    params?.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone;

  params = {
    start_timestamp,
    end_timestamp,
    timezone,
  };

  const query = useQuery(
    "/app/v1/statistics/main_percentages",
    () =>
      axios
        .get("/app/v1/statistics/main_percentages", {
          params,
        })
        .then((res) => res.data),
    {
      enabled: false,
      onSuccess: (data) => {
        setMainPercentages(data);
      },
      onError: () => {
        resetMainPercentages();
      },
    }
  );

  return query;
};
