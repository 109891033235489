import { useState } from "react";
import { useTranslation } from "react-i18next";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { Typography } from "@mui/material";

export const LanguageToggler = () => {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);

  const handleChange = (event, newLanguage) => {
    i18n.changeLanguage(newLanguage);
    setLanguage(newLanguage);
  };

  const style = {
    fontFamily: "Dosis",
    color: "white",
    borderRadius: "20px",
    borderColor: "rgba(255,255,255,0.5)",
  };

  return (
    <ToggleButtonGroup
      color="secondary"
      value={language}
      exclusive
      onChange={handleChange}
    >
      <ToggleButton value="es" sx={style}>
        <img src="https://flagcdn.com/16x12/es.png" alt="Spain" />
        <Typography marginLeft={1}>ES</Typography>
      </ToggleButton>
      <ToggleButton value="en" sx={style}>
        <img src="https://flagcdn.com/16x12/us.png" alt="United States" />
        <Typography marginLeft={1}>EN</Typography>
      </ToggleButton>
    </ToggleButtonGroup>
  );
};
