import { useForm } from "react-hook-form";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { Box, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { useTranslation } from "react-i18next";

export const LoginForm = ({ handleLogin, isLoading, isError, setIsError }) => {
  const { t } = useTranslation();
  
  const schema = yup.object().shape({
    username: yup.string().required(t("login_LoginForm_usernameReq")),
    password: yup.string().required(t("login_LoginForm_passReq")),
  });

  const onSubmit = (data) => {
    const { username, password } = data;
    handleLogin(username, password);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Box display="flex" flexWrap="wrap" gap="1rem" marginTop="2rem">
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="username"
          label={t("login_LoginForm_username")}
          autoComplete="username"
          autoFocus
          disabled={isLoading}
          {...register("username")}
          onChangeCapture={() => setIsError(false)}
          error={!!errors.username || isError}
          helperText={errors.username ? errors.username?.message : " "}
        />

        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="password"
          type="password"
          label={t("common_profile_passwordLabel")}
          autoComplete="current-password"
          disabled={isLoading}
          {...register("password")}
          onChangeCapture={() => setIsError(false)}
          error={!!errors.password || isError}
          helperText={errors.password ? errors.password?.message : " "}
        />

        <LoadingButton
          className="m-top-2r"
          type="submit"
          fullWidth
          loading={isLoading}
          variant="contained"
          color="primary"
          disabled={isLoading}
        >
          {isLoading ? t("login_LoginForm_loading") : t("login_LoginForm_login")}
        </LoadingButton>
      </Box>
    </form>
  );
};
