import create from "zustand";
import { devtools } from "zustand/middleware";

export const usePlantsStore = create(
  devtools(
    (set) => ({
      filtersData: [],
      setFiltersData: (filtersData) => set((state) => ({ filtersData })),

      filteredPlants: [],
      setFilteredPlants: (filteredPlants) =>
        set((state) => ({ filteredPlants })),

      sortingValue: [],
      setSortingValue: (sortedV) => set((state) => (
        {sortingValue: sortedV}
      )),

      checkedPlants: [],
      setCheckedPlants: (selectedPlantsIds) =>
        set((state) => ({
          checkedPlants: state.filteredPlants.filter((plant) =>
            selectedPlantsIds.includes(plant.id)
          ),
        })),
      resetCheckedPlants: () => set((state) => ({ checkedPlants: [] })),

      totalPlants: 0,
      setTotalPlants: (totalPlants) => set((state) => ({ totalPlants })),
      currentPage: 0,

      setCurrentPage: (currentPage) => set((state) => ({ currentPage })),
      resetCurrentPage: () => set((state) => ({ currentPage: 0 })),
    }),
    { name: "PlantsStore " }
  )
);
