import { Box, styled, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNotificationsStore } from '../../stores/useNotificationsStore';
import { useTranslation } from "react-i18next";
import './sidebarItem.css';

const ItemContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flex: 1,
  flexDirection: "row",
  width: "100%",
  maxHeight: "5rem",
  padding: "1rem",
  "&:hover": {
    background: theme.palette.primary.light,
    cursor: "pointer",
  },
}));

export const SidebarItem = (props) => {
  const { icon, text, selected, onClick } = props;
  const { unreadNotifs } = useNotificationsStore()
  const { t } = useTranslation();

  return (
    <ItemContainer onClick={onClick}>
      <Box
        display="flex"
        alignItems="center"
        gap="1rem"
        sx={{
          paddingX: "1rem",
          color: selected ? "secondary.main" : "background.paper",
        }}
      >
        {icon && text === t("common_Sidebar_notifications") && unreadNotifs > 0 ? <div className="relative">
          <FontAwesomeIcon icon={icon} size="lg" />
          <div className="wrapper">
            <span className='badge'>{unreadNotifs}</span>
          </div>
        </div> : <FontAwesomeIcon icon={icon} size="lg" />}
        <Typography
          component="div"
          variant="h5"
          display="flex"
          alignItems="center"
          sx={{
            fontFamily: "Dosis",
          }}
        >
          {text}
        </Typography>
      </Box>
    </ItemContainer>
  );
};
