import { Divider, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import { getKeyByValue } from '../../utils/traslate';
import { PercentageWidget, TagsWidget } from "./components";

export const CurrentMetricsCard = (props) => {
  const { t } = useTranslation();

  const { mainPercentages, totalUploadedImages, diseasePercentagesTags } =
    props;
  
  const diseasePercentagesTagsTranslated = diseasePercentagesTags.map((disease) => {
    const namekey = getKeyByValue(disease.name)
    return { ...disease, name: t(namekey) }
  });

  return (
    <Box display="flex" flexDirection="column" minWidth="360px">
      <Paper className="currentMetricsCard-paper ">
        <Box display="flex" flexDirection="column" padding="0.5rem" gap="1rem">
          <Typography
            color="primary.gradientDark"
            component="span"
            variant="h5"
            fontWeight={600}
          >
            {t("dashboard_CurrentMetricsCard_title")}
          </Typography>
          <PercentageWidget
            mainPercentages={mainPercentages}
            legend1={t("dashboard_CurrentMetricsCard_legend1")}
            legend2={t("dashboard_CurrentMetricsCard_legend2")}
            legend3={t("dashboard_CurrentMetricsCard_legend3")}
            label={t("dashboard_CurrentMetricsCard_measurementsLabel")}
            title={t("dashboard_CurrentMetricsCard_measurementsTitle")}
          // horizontal
          />
        </Box>

        <Divider variant="middle" />

        <Box padding="1rem">
          <TagsWidget
            title={t("dashboard_CurrentMetricsCard_uploadedImagesTitle")}
            total={totalUploadedImages}
            totalLabel={t("dashboard_CurrentMetricsCard_uploadedImages")}
            tags={diseasePercentagesTagsTranslated}
          // horizontal
          />
        </Box>
      </Paper>
    </Box>
  );
};
