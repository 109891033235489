import React from "react";
import Dialog from "@mui/material/Dialog";

import { styled } from "@mui/material";
import { ShowMeasurementsCarousel } from "../carousel/ShowMeasurementsCarousel";
import { useTranslation } from "react-i18next";
import { classes } from "../../utils/customClasses";

const StyledDialog = styled(Dialog)(({ theme }) => {
  return {
    ".MuiDialog-paper": {
      maxWidth: "60rem",
      width: "60rem",
      // textAlign: "center",
      display: "flex",
      justifyContent: "center",
    },
  };
});

const styles = {
  content: {
    ...classes.content,
  },
  h3: {
    ...classes.h3,
  },
  resLabel: {
    ...classes.resLabel,
  },
  okButton: {
    ...classes.okButton,
  },
  disabled: {
    ...classes.disabled,
  },
  cancelar: {
    ...classes.cancelar,
  },
  errorBox: {
    ...classes.errorBox,
  },
  errorRow: {
    ...classes.errorRow,
  },
  buttonContainer: {
    ...classes.buttonContainer,
  },
};

export const ShowMeasurementModal = (props) => {
  const { t } = useTranslation();
  const { open = false, handleClose = () => {} } = props;
  return (
    <StyledDialog open={open} onClose={handleClose} style={styles.p}>
      <ShowMeasurementsCarousel />

      <div style={styles.buttonContainer}>
        <div
          style={styles.okButton}
          onClick={() => {
            handleClose();
          }}
        >
          <p style={styles.p}>{t("common_close")}</p>
        </div>
      </div>
    </StyledDialog>
  );
};
