import { useTranslation } from "react-i18next";
//css imports
import "./showPlantsCarousel.css";
import { classes } from "../../utils/customClasses";

export const PlantCarousel = (props) => {
    const { t } = useTranslation();
    return (
      <div className="plants-carousel-container">
        <h2 className="common-plants-title">{t("common_Plant_details")}</h2>
        <div className="plants-carousel-inner-container">
          <div style={customStyles.inputContainer}>
            <div style={customStyles.inputDivLeft}>
              {t("common_header_plantid")}
              <label style={customStyles.label}>{props.item.custom_id}</label>
            </div>
            <div style={customStyles.inputDivRight}>
              {t("common_header_planting_date")}
              <label style={customStyles.label}>{props.item.planting_date}</label>
            </div>
            <div style={customStyles.inputDivLeft}>
              {t("common_header_lot")}
              <label style={customStyles.label}>{props.item.lot}</label>
            </div>
            <div style={customStyles.inputDivRight}>
              {t("common_header_facility")}
              <label style={customStyles.label}>{props.item.facility}</label>
            </div>
            <div style={customStyles.inputDivLeft}>
              {t("common_header_genetic")}
              <label style={customStyles.label}>{props.item.genetic}</label>
            </div>
            <div style={customStyles.inputDivRight}>
              {t("common_header_zone")}
              <label style={customStyles.label}>{props.item.zone}</label>
            </div>
            <div style={customStyles.inputDivLeft}>
              {t("common_header_table")}
              <label style={customStyles.label}>{props.item.table}</label>
            </div>
            <div style={customStyles.inputDivRight}>
              {t("common_header_plate")}
              <label style={customStyles.label}>{props.item.plate}</label>
            </div>
            <div style={customStyles.inputDivLeft}>
              {t("common_header_stage")}
              <label style={customStyles.label}>{props.item.stage}</label>
            </div>
            <div style={customStyles.inputDivRight}>
              {t("common_header_sex")}
              <label style={customStyles.label}>{props.item.sex}</label>
            </div>
            <div style={customStyles.inputDivLeft}>
              {t("common_header_treatment")}
              <label style={customStyles.label}>{props.item.treatment}</label>
            </div>
            <div style={customStyles.inputDivRight}>
              {t("plants_PlantsTable_description")}
              <label style={customStyles.label}>{props.item.description}</label>
            </div>
          </div>
        </div>
        <div style={customStyles.qrContainer}>
          <img style={customStyles.qr} src={props.item.qr} alt="" />
        </div>
      </div>
    );
  }

  const customStyles = {
    h3: {
      color: "#6a7ca1",
    },
    inputContainer: {
      ...classes.inputContainer,
      marginTop: "5%",
    },
    inputDivLeft: {
      ...classes.inputDivTopBorder,
      width: "45%",
    },
    qrContainer: {
      width: "45%",
      color: "#6a7ca1",
      margin: "auto",
      ...classes.defaultProps,
    },
    inputDivRight: {
      ...classes.inputDivTopBorder,
      width: "50%",
    },
    label: {
      ...classes.label,
    },
    qr: {
      width: "50%",
      aspectRatio: "1/1",
      backgroundColor: "black",
    },
  };