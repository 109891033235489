import { useState } from "react";
import { GridOverlay, DataGrid, esES } from "@mui/x-data-grid";
import { DateRangePicker } from "@mantine/dates";
import { IconButton, styled, Typography } from "@mui/material";
import { Box } from "@mui/system";
import EditIcon from "@mui/icons-material/Edit";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import DoneIcon from "@mui/icons-material/Done";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";

import {
  DownloadMeasurementsButton,
  DownloadImagesButton,
  ShowImagesButton,
} from "./buttons/index.js";
import "./measurementsTable.css";
import Isotipo from "../../../../assets/images/isotipo/PNG/modal.png";
import { EditMeasurementModal } from "components/modals/EditMeasurementModal";
import { MeasurementDetails } from "components/modals/MeasurementDetails";
import { Section } from "components/Section.js";
import ProgressBar from "components/ProgressBar.js";
import MeasuremenTags from "../MeasuremenTags.js";

const StyledDataGrid = styled(DataGrid)(({ theme }) => {
  return {
    ".MuiDataGrid-columnHeaders": {
      color: "white",
      backgroundColor: theme.palette.dataGrid.header,
    },

    ".MuiDataGrid-sortIcon": {
      color: "white",
    },
  };
});

const StyledButtonsBox = styled(Box)(({ theme }) => {
  return {
    ".leftButtons": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-evenly",
      alignItems: "center",
    },

    ".rightButtons": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      minWidth: "fit-content",
    },
    ".measurementsButtons": {
      backgroundColor: "#a4a4c2",
      marginLeft: "5px",
      width: "fit-content",
      height: "5.5vh",
      padding: "0 .5em",
      color: "white",
      borderRadius: "5px",
      cursor: "pointer",
      textAlign: "center",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "1em",
    },
  };
});

const getUIDate = (params) => {
  return DateTime.fromMillis(params.value).toFormat("yyyy-MM-dd");
};

export const MeasurementsTable = (props) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const {
    dateRangeValue,
    setDateRangeValue,
    filteredMeasurements,
    totalMeasurements,
    loading,
    currentPage,
    changePage,
    setCheckedMeasurements,
    tagsDictionary,
    editMeasurement,
    validateMeasurement,
    setSorting,
  } = props;
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openValidationModal, setOpenValidationModal] = useState(false);
  const [selectedMeasurement, setSelectedMeasurement] = useState({
    tag_measurements: [],
    description: "",
  });
  
  const CustomNoRowsOverlay = () => {
    return (
      <GridOverlay>
        <div className="no-records">
          <Box>
            <img src={Isotipo} alt="" />
          </Box>
          <Box sx={{ mt: 1 }}>
            {t("measurements_MeasurementsTableSection_noMeasurements")}
          </Box>
        </div>
      </GridOverlay>
    );
  };

  const localeEs = esES.components.MuiDataGrid.defaultProps.localeText;
  const columns = [
    {
      field: "custom_id",
      headerName: t("measurements_headers_custom_id"),
      flex: 1,
      headerClassName: "my_header",
    },
    {
      field: "timestamp",
      headerName: t("measurements_headers_timestamp"),
      flex: 1,
      valueGetter: getUIDate,
    },
    {
      field: "development_state",
      headerName: t("measurements_headers_development_state"),
      flex: 0.5,
    },
    {
      field: "salubrity",
      headerName: t("measurements_headers_salubrity"),
      flex: 0.75,
      renderCell: (cellValues) => {
        return (
          <ProgressBar
            component="span"
            predictions={cellValues.row.prediction_measurements}
          />
        );
      },
      sortable: false,
    },
    {
      field: "tag_measurements",
      headerName: t("measurements_headers_tags"),
      flex: 1.5,
      sortable: false,
      renderCell: (cellValues) => {
        return (
          <MeasuremenTags
            component="span"
            tags={cellValues.row.tag_measurements}
          />
        );
      },
    },
    {
      field: "validated",
      headerName: "Validated",
      flex: 0.5,
      sortable: false,
    },
    {
      field: "description",
      headerName: t("measurements_headers_description"),
      flex: 0.75,
    },
    {
      field: "actions",
      headerName: t("common_action"),
      flex: 0.5,
      sortable: false,
      renderCell: (cellValues) => {
        return (
          <>
            <IconButton
              color="primary"
              component="span"
              onClick={() => {
                setSelectedMeasurement({ ...cellValues.row });
                setOpenDetailsModal(true);
              }}
            >
              <ZoomInIcon />
            </IconButton>
            <IconButton
              color="primary"
              component="span"
              onClick={() => {
                setSelectedMeasurement({ ...cellValues.row });
                setOpenModal(true);
              }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              color="primary"
              component="span"
              onClick={() => {
                setSelectedMeasurement({ ...cellValues.row });
                setOpenValidationModal(true);
              }}
            >
              <DoneIcon />
            </IconButton>
          </>
        );
      },
      disableClickEventBubbling: true,
    },
  ];

  const handleCloseModals = () => {
    setOpenDetailsModal(false);
    setOpenModal(false);
    setOpenValidationModal(false);
  };

  const handleSubmit = (values) => {
    editMeasurement({ ...values, id: selectedMeasurement.id });
    setOpenModal(!openModal);
  };

  const onSubmitTags = (values, description, diseaseBoolean) => {
    validateMeasurement(values, selectedMeasurement.id, description, diseaseBoolean);
    /* handleCloseModals(); */
    setOpenValidationModal(!openValidationModal);
  };

  return (
    <Section title={t("measurements_MeasurementsTableSection_title")}>
      <StyledButtonsBox className="btns-table">
        <div className="rightButtons">
          <ShowImagesButton />
          <DownloadImagesButton />
          <DownloadMeasurementsButton />
        </div>
        <Box
          display="flex"
          gap="1rem"
          alignItems="center"
          justifyContent="right"
        >
          <Typography
            color="primary.gradientDark"
            fontSize="1.2rem"
            fontWeight={500}
          >
            {t("dashboard_ChartsSection_dateFilter")}
          </Typography>
          <DateRangePicker
            placeholder={t("common_RangeDayPicker_select")}
            locale={currentLanguage}
            style={{ minWidth: 280 }}
            maxDate={new Date()}
            allowSingleDateInRange={true}
            value={dateRangeValue}
            onChange={setDateRangeValue}
          />
        </Box>
      </StyledButtonsBox>

      <Box height="520px">
        <StyledDataGrid
          components={{
            NoRowsOverlay: CustomNoRowsOverlay,
          }}
          rowHeight={40}
          rows={filteredMeasurements}
          rowCount={parseInt(totalMeasurements)}
          columns={columns}
          loading={loading}
          pageSize={10}
          rowsPerPageOptions={[10]}
          disableSelectionOnClick
          disableColumnMenu
          pagination
          paginationMode="server"
          sortingMode="server"
          page={currentPage}
          onPageChange={(page) => changePage(page)}
          checkboxSelection
          onSortModelChange={(sortValue) => {
            setSorting(sortValue);
          }}
          localeText={i18n.language === "es" ? localeEs : null}
          onSelectionModelChange={(newCheckedMeasurements) => {
            setCheckedMeasurements(newCheckedMeasurements);
          }}
        />
      </Box>
      <EditMeasurementModal
        isOpen={openModal}
        handleClose={handleCloseModals}
        measurement={selectedMeasurement}
        handleSubmit={handleSubmit}
        tagsDictionary={tagsDictionary}
      />
      {(openDetailsModal || openValidationModal) && (
        <MeasurementDetails
          isOpen={openDetailsModal || openValidationModal}
          id={selectedMeasurement.id}
          prediction={selectedMeasurement.prediction_measurements}
          handleClose={handleCloseModals}
          onSubmitTags={onSubmitTags}
          forValidation={openDetailsModal ? false : openValidationModal && true}
          selectedMeasurement={selectedMeasurement}
          filteredMeasurements={filteredMeasurements}
        />
      )}
    </Section>
  );
};
