import { useHistory } from "react-router-dom";

import { useMutation } from "react-query";
import { useAxios } from "api/Axios";
import qs from "qs";

import { useProfileQuery } from "api/queries";

export const useLoginMutation = () => {
  const axios = useAxios();
  const history = useHistory();

  const { refetch: refetchProfile } = useProfileQuery();

  const mutation = useMutation(
    ({ username, password }) => {
      const data = { username, password };
      const options = {
        method: "POST",
        headers: { "content-type": "application/x-www-form-urlencoded" },
        data: qs.stringify(data),
        url: "/app/v1/auth/login",
      };

      return axios(options).then((res) => res.data);
    },
    {
      onSuccess: (data) => {
        const accessToken = data.access_token;
        const refreshToken = data.refresh_token;
        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem("refreshToken", refreshToken);

        refetchProfile();

        history.push({
          pathname: "/dashboard",
        });
      },
    }
  );

  return mutation;
};
