import JSZip from "jszip";
import { saveAs } from "file-saver";

export const downloadImages = async (axios, idList) => {
  var zip = new JSZip();

  const getData = async (idList) => {
    let response = await axios.get(
      `/app/v1/plant_measurements/with_images?measurements_ids=${idList}`
    );
    return response?.data;
  };
  const measurementsWithImages = await getData(idList);

  if (measurementsWithImages.length === 0) {
    return;
  }
  for (let measurement of measurementsWithImages) {
    if (measurement.rgb_image) {
      var img = zip.folder("images - " + measurement.custom_id);
      img.file(
        "rgb_image - " + measurement.timestamp + ".png",
        measurement.rgb_image.replace("data:image/png;base64,", ""),
        { base64: true }
      );
    }
  }
  zip.generateAsync({ type: "blob" }).then(function (content) {
    saveAs(content, "Measurements.zip");
  });
};
