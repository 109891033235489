import { useAxios } from "api/Axios";
import { useQuery } from "react-query";
import { useAuthStore } from "stores";

export const useProfileQuery = () => {
  const axios = useAxios();

  const setCurrentUser = useAuthStore((store) => store.setCurrentUser);
  const query = useQuery(
    "/app/v1/users/me",
    () => axios.get("/app/v1/users/me").then((res) => res.data),
    {
      enabled: false,
      onSuccess: (data) => {
        setCurrentUser(data);
      },
    }
  );

  return query;
};
