import { useTranslation } from "react-i18next";
import Isotipo from "../../assets/images/isotipo/PNG/modal.png";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
//styles import
import {classes} from '../../utils/customClasses';

const styles = {
  modal: {
    ...classes.modal,
  },
  img: {
    width: "50%",
    height: "auto",
    paddingBottom: "20px",
  },
  h3Alt: {
    ...classes.h3Alt,
  },
  modalButtons: {
    ...classes.defaultProps,
    width: "100%",
    height: "calc(100vh/24.275)",
    marginTop: "calc(100vh / 31.36)",
  },
  cancelButton: {
    ...classes.okButtonAlt,
    backgroundColor: "white",
    border: "1px solid #a4a4c2",
    color: "#a4a4c2",
  },
  okButtonAlt: {
    ...classes.okButtonAlt,
  },
};

export const NoSelectedElemetsModal = (props) => {
  const { t } = useTranslation();
  const { open = false, handleClose = () => {}} = props;

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styles.modal}>
          <div>
            <img src={Isotipo} style={styles.img} alt="" />
          </div>

          <h3 style={styles.h3Alt}>{t("measurements_NoMeasurementsSelected")}</h3>
          <div style={styles.modalButtons}>
            <div style={styles.okButtonAlt} onClick={() => props.handleClose()}>
              {t("measurements_ShowMeasurement_return")}
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};
