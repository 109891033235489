import { saveAs } from "file-saver";

import { buildXLSX } from "./";

const plantHeaders = {
  custom_id: "Plant ID",
  planting_date: "Planting Date",
  lot: "Lot",
  facility: "Facility",
  genetic: "Genetic",
  zone: "Zone",
  table: "Table",
  plate: "Plate",
  stage: "Stage",
  sex: "Sex",
  treatment: "Treatment",
  description: "Description",
};

export const downloadPlants = async (plants) => {
  const plantsWithFixedDate = plants.map((elem) => {
    if (!elem.planting_date) {
      return {
        ...elem,
      };
    }
    return {
      ...elem,
      planting_date: new Date(
        Date.parse(elem.planting_date)
      ).toLocaleDateString("es-AR", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      }),
    };
  });
  const xlsx = await buildXLSX(plantsWithFixedDate, plantHeaders);
  saveAs(xlsx, "Plants.xlsx");
};
