import { useState } from "react";
import { Box } from "@mui/system";
import Cropper from "react-easy-crop";

import { useCropImage } from "pages/profile/hooks";

export const ImageCropper = (props) => {
  const { newImage, setCroppedImage } = props;

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  const { cropImage } = useCropImage();

  const onCropComplete = async (_, croppedAreaPixels) => {
    const cropped = await cropImage(newImage, croppedAreaPixels);

    const reader = new FileReader();
    reader.readAsDataURL(cropped);
    reader.onloadend = function () {
      const croppedImage = reader.result;
      setCroppedImage(croppedImage);
    };
  };

  return (
    <Box
      sx={{
        position: "relative",
        minHeight: 400,
      }}
    >
      <Cropper
        image={newImage}
        crop={crop}
        zoom={zoom}
        aspect={1}
        onCropChange={setCrop}
        onCropComplete={onCropComplete}
        onZoomChange={setZoom}
      />
    </Box>
  );
};
