import { Section } from "components";
import { RecordsTable } from "./RecordsTable";
import { useTranslation } from "react-i18next";

export const RecordsTableSection = (props) => {
  const {
    setDateRangeValue,
    dateRangeValue,
    filteredRecords,
    totalRecords,
    currentPage,
    setCurrentPage,
    setSelectionModel,
    setSorting
  } = props;
  const { t, i18n } = useTranslation();
  return (
    <Section title={t("records_RecordsTableSection_title")}>
      <RecordsTable
        setDateRangeValue={setDateRangeValue}
        dateRangeValue={dateRangeValue}
        filteredRecords={filteredRecords}
        totalRecords={totalRecords}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setSelectionModel={setSelectionModel}
        setSorting={setSorting}
      />
    </Section>
  );
};
