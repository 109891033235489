import create from "zustand";
import { devtools } from "zustand/middleware";

export const useRecordsStore = create(
  devtools(
    (set) => ({
      filteredRecords: [],
      setFilteredRecords: (filteredRecords) =>
      set((state) => ({ filteredRecords })),

      timeInterval: {},
      setTimeInterval: (start, end) =>
        set((state) => ({
          timeInterval: {
            start,
            end,
          },
        })
      ),
      resetTimeInterval: () => set(state => ({ timeInterval: {} })),
      
      totalRecords: 0,
      setTotalRecords: (totalRecords) => set((state) => ({ totalRecords })),

      sortingValue: [],
      setSortingValue: (sortedV) => set((state) => ({ sortingValue: sortedV })),

      checkedRecords: [],
      setCheckedRecords: (selectedRecordsIds) =>
      set((state) => ({
        checkedRecords: state.filteredRecords.filter((measurement) =>
        selectedRecordsIds.includes(measurement.id)
        ),
      })),
      resetCheckedRecords: () => set((state) => ({ checkedRecords: [] })),

      currentPage: 0,
      setCurrentPage: (currentPage) => set((state) => ({ currentPage })),
      resetCurrentPage: () => set((state) => ({ currentPage: 0 })),
    }),

    { name: "RecordsStore " }
  )
);
