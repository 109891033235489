import { useEffect } from "react";
import { styled, Box, CircularProgress, Typography } from "@mui/material";

import { useAuthStore } from "stores";
import { useProfileQuery } from "api/queries";
import { useHistory } from "react-router-dom";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import { useTranslation } from "react-i18next";
//css import
import './profilecard.css';

const Avatar = styled("img")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  minHeight: "4rem",
  minWidth: "4rem",
  height: "4rem",
  width: "4rem",
  borderRadius: ".5rem",
}));

const AvatarLoading = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  minHeight: "4rem",
  minWidth: "4rem",
  height: "4rem",
  width: "4rem",
  borderRadius: ".5rem",

  backgroundColor: theme.palette.background.default,
}));

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  flex: 1,
  flexDirection: "row",
  background: theme.palette.primary.dark,
  padding: "1rem",
  borderRadius: ".5rem",
}));

export const ProfileCard = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const currentUser = useAuthStore((store) => store.currentUser);

  const { refetch: refetchProfile } = useProfileQuery();

  useEffect(() => {
    if (!currentUser) {
      refetchProfile();
    }
  }, [currentUser, refetchProfile]);

  const onUserClick = () => {
    history.push({
      pathname: "/profile",
    });
  };

  return (
    <div
      className="inner-profilecard-container p-1r"
      onClick={onUserClick}
    >
      <Container>
        {currentUser?.avatar ? (
          <Avatar src={currentUser.avatar} alt="Daiana Logo" />
        ) : (
          <AvatarLoading>
            <CircularProgress
             className="circular-progress"
            />
          </AvatarLoading>
        )}
        <div
        className="profile-card-text-container"
        >
          <div className="profile-card-text-inner-container">
            <Typography
              className="profile-card-greetings"
            >
              {t("common_ProfileCard_hello")}
            </Typography>
            <Typography
              className="profile-card-username"
            >
              {currentUser?.username}
            </Typography>
          </div>
          <ModeEditOutlineOutlinedIcon className="profile-card-editIcon"  />
        </div>
      </Container>
    </div>
  );
};
