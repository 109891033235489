import { useAxios } from "api/Axios";
import { useMutation, useQueryClient } from "react-query";

export const useUpdateNotificationsMutation = () => {
  const axios = useAxios();
  const queryClient = useQueryClient();

  const mutation = useMutation((data) => {
    const { read, id } = data;
    const payload = { read };
    const options = {
      method: "PUT",
      data: JSON.stringify(payload),
      url: `/app/v1/notifications/${id}`,
    };
    return axios(options).then((res) => res.data);
  },
  {
    onSuccess: (data) => {
      queryClient.fetchQuery({ queryKey: "/app/v1/notifications/" });
    },
  });
  return mutation;
};
