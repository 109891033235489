import React, { useState } from "react";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { useTranslation } from "react-i18next";

import { AddCSVModal } from "components/modals/AddCSVModal";

export const CsvButton = (props) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const onClick = async () => {
    setOpen(true);
  };
  return (
    <>
      <div onClick={onClick} className="cancelButton, plantButtons">
        <FileUploadIcon></FileUploadIcon>
        <p className="pl-08r">{t("plants_CsvButton_upload")}</p>
      </div>

      <AddCSVModal open={open} handleClose={handleClose} />
    </>
  );
};
