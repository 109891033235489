import { Typography } from "@mui/material";
import { Box } from "@mui/system";

import { PieChart, Pie, Cell } from "recharts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle as icon } from "@fortawesome/free-solid-svg-icons";

const COLORS = ["#c6d600", "#b80088", "#f2f0f2"];

const Legend = (props) => {
  const { data } = props;
  return (
    <Box {...props}>
      {data.map((entry, index) => (
        <Box display="flex" alignItems="center" gap="5px" key={`item-${index}`}>
          <FontAwesomeIcon icon={icon} size="xs" color={COLORS[index]} />
          <Typography
            color="primary.gradientDark"
            component="span"
            fontSize="0.9rem"
            fontWeight={500}
          >
            {`${entry.value}% ${entry.name}`}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

const Label = (props) => {
  const { label, value } = props;

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100%"
      width="100%"
    >
      <Typography
        color="primary.gradientDark"
        component="span"
        fontSize="2.5rem"
        marginBottom="-5px"
        fontWeight="bold"
      >
        {value + "%"}
      </Typography>
      {label && (
        <Typography
          color="primary.gradientDark"
          component="span"
          fontSize="0.75rem"
          fontWeight="400"
        >
          {label}
        </Typography>
      )}
    </Box>
  );
};

const Donut = (props) => {
  const { data, size } = props;

  const outerRadius = size / 2;
  const innerRadius = outerRadius - 8;

  return (
    <PieChart width={size} height={size}>
      <Pie
        data={data}
        outerRadius={outerRadius}
        innerRadius={innerRadius}
        startAngle={90}
        endAngle={90 - 360}
        dataKey="value"
        // isAnimationActive={false}
        animationDuration={700}
      >
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
    </PieChart>
  );
};

const PercentageChart = (props) => {
  const { percentage, data, label, size } = props;
  return (
    <Box display="flex" justifyContent="center">
      <Box position="relative" height={size} width={size}>
        <Box position="absolute" height="100%" width="100%">
          <Donut data={data} size={size} />
        </Box>
        <Box position="absolute" height="100%" width="100%">
          <Label value={percentage} label={label} />
        </Box>
      </Box>
    </Box>
  );
};

export const PercentageWidget = (props) => {
  const {
    legend1 = "-",
    legend2 = "-",
    legend3 = "-",
    label = "",
    title = "",
    mainPercentages = undefined,
    horizontal = false,
    onlyChart = false,
  } = props;

  const healthy = Math.round(mainPercentages?.healthy * 100) || 0;
  const disease = Math.round(mainPercentages?.disease * 100) || 0;
  const untagged = Math.round(mainPercentages?.untagged * 100) || 0;

  const data = [
    { name: legend1, value: healthy },
    { name: legend2, value: disease },
    { name: legend3, value: untagged },
  ];
  const size = 148;

  if (onlyChart) {
    return (
      <PercentageChart
        percentage={healthy}
        data={data}
        label={label}
        size={size}
      />
    );
  }

  return horizontal ? (
    <Box display="flex" flexDirection="column" gap="2rem">
      {title && (
        <Typography
          color="primary.gradientDark"
          component="span"
          variant="body"
        >
          {title}
        </Typography>
      )}
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        gap="1rem"
        paddingX="1rem"
      >
        <PercentageChart
          percentage={healthy}
          data={data}
          label={label}
          size={size}
        />
        <Legend data={data} padding="1rem" />
      </Box>
    </Box>
  ) : (
    <Box display="flex" flexDirection="column" gap="1rem">
      <PercentageChart
        percentage={healthy}
        data={data}
        label={label}
        size={size}
      />

      <Box paddingTop="1rem">
        {title && (
          <Typography
            color="primary.gradientDark"
            component="span"
            variant="body"
          >
            {title}
          </Typography>
        )}
        <Legend data={data} padding="1rem" />
      </Box>
    </Box>
  );
};
