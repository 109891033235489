import { useCallback } from "react";
import * as jwt from "jsonwebtoken";

import { useLogout } from ".";
import { useRefresh } from "./useRefresh";

const isTokenExpired = (token) => {
  if (!token) return true;
  const tokenDecoded = jwt.decode(token);
  if (!tokenDecoded?.exp) return true;
  return Date.now() >= tokenDecoded.exp * 1000;
};

export const useTokens = () => {
  const { logout } = useLogout();
  const { refresh } = useRefresh();

  const getTokens = useCallback(async () => {
    let accessToken = localStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");

    if (isTokenExpired(accessToken)) {
      if (isTokenExpired(refreshToken)) {
        logout();
        return {};
      }

      const { access_token } = await refresh();
      accessToken = access_token;
      localStorage.setItem("accessToken", accessToken);

      return { accessToken, refreshToken };
    }

    return { accessToken, refreshToken };
  }, [logout, refresh]);

  return { getTokens };
};
