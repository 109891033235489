import { useAxios } from "api/Axios";
import { useQuery } from "react-query";

import { useFilterStore, useMeasurementsStore } from "stores";
import { useSortingParamsHelper } from "../../helpers/sortingparams";

export const useFilteredMeasurementsQuery = (timeInterval = {}) => {
  const axios = useAxios();
  const { setFilteredMeasurements, currentPage, setTotalMeasurements } =
    useMeasurementsStore();

  const { dropdownPlantFilter: filterId } = useFilterStore();
  const { validationFilter: validationFilter } = useFilterStore();

  let skip = currentPage * 10;

  const start = timeInterval?.start || 0;
  const end = timeInterval?.end || new Date().getTime();

  let filter = {
    timestamp: {
      ">": start,
      "<": end,
    },
  };
  if (filterId) {
    filter.custom_id = filterId;
  }
  filter.validated = validationFilter;

  const { finalSort, finalOrder } = useSortingParamsHelper(
    "measurements",
    "timestamp"
  );
  const query = useQuery(
    "filteredMeasurements",
    () =>
      axios
        .get("/app/v1/plant_measurements/", {
          params: {
            skip,
            limit: "10",
            sort: finalSort,
            order: finalOrder,
            filter: JSON.stringify(filter),
          },
        })
        .then((res) => res),
    {
      enabled: false,
      onSuccess: (res) => {
        setFilteredMeasurements(res.data);
        setTotalMeasurements(res.headers["content-range"]);
      },
      onError: (res) => {
        setTotalMeasurements(0);
        setFilteredMeasurements([]);
      },
    }
  );
  return query;
};
