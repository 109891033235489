import { useEffect, useState } from "react";

import { useAuthStore, useIndicatorsStore } from "stores";
import { useIndicatorsMutation, useUpdateProfileMutation } from "api/mutations";
import { useIndicatorsQuery, useProfileQuery } from "api/queries";
import { ProfileView } from "./ProfileView";

export const ProfileContainer = () => {
  const { mutate: mutateProfile, isLoading: isLoadingMutationProfile } =
    useUpdateProfileMutation();
  const { mutate: mutateIndicators, isLoading: isLoadingIndicators } =
    useIndicatorsMutation();
  const { refetch: refetchTagIndicators } = useIndicatorsQuery();
  const { refetch: refetchProfile, isLoading: isLoadingProfile } =
    useProfileQuery();
  const [croppedImage, setCroppedImage] = useState(undefined);
  const { currentUser } = useAuthStore();
  const { indicators } = useIndicatorsStore();

  useEffect(() => {
    refetchTagIndicators();
    refetchProfile();
  }, [refetchTagIndicators, refetchProfile]);

  const onProfileSave = async (data) => {
    const mutationData = {};

    if (data?.password) {
      mutationData["password"] = data?.password;
    }
    if (data?.email) {
      mutationData["email"] = data?.email;
    }
    if (croppedImage !== currentUser?.avatar) {
      mutationData["avatar"] = croppedImage;
    }

    if (Object.keys(mutationData)?.length) {
      mutateProfile(mutationData);
    }
  };

  const onIndicatorsSave = (values) => {
    
    const notifications = values["notifications"];
    delete values.notifications;

    if (notifications !== currentUser.notifications_enabled) {
      mutateProfile({ notifications_enabled: notifications });
    }

    let indicatorsToUpdate = [];
    for (const tagIndicator in values) {
      if (values[tagIndicator]) {
        indicatorsToUpdate.push(tagIndicator);
      }
    }
    const indicatorsFiltered = indicatorsToUpdate.filter((elem) => elem !== 'disease') 
    if (values.all){
      mutateIndicators(['disease']);
      return;
    }
    if (notifications) {
      mutateIndicators(indicatorsFiltered);
    }
  };

  return (
    <ProfileView
      currentUser={currentUser}
      initialIndicators={indicators}
      croppedImage={croppedImage}
      setCroppedImage={setCroppedImage}
      onProfileSave={onProfileSave}
      onIndicatorsSave={onIndicatorsSave}
      isLoadingProfile={isLoadingMutationProfile || isLoadingProfile}
      isLoadingIndicators={isLoadingIndicators}
    />
  );
};
