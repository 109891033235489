import { createTheme } from "@mui/material/styles";

// Edit theme here -> https://bareynol.github.io/mui-theme-creator/

export const theme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#4e5f82",
      gradientLight: "rgb(106 124 161)",
      gradientDark: "rgb(59 75 109)",
    },
    dataGrid: {
      header: "rgb(164, 164, 194)",
    },
    secondary: {
      main: "#82c64e",
    },
    background: {
      default: "#f0f3f8",
    },
    success: {
      main: "#66BB6A",
    },
    warning: {
      main: "#FFA726",
    },
    info: {
      main: "#42A5F5",
    },
  },
  typography: {
    fontFamily: "Lato",
    component: "span", // REVIEW
    userSelect: "none",
    msUserSelect: "none",
    h1: {
      fontFamily: "Lato",
      fontSize: "2.125rem",
      fontWeight: 400,
    },
    h2: {
      fontFamily: "Lato",
      fontSize: "1.5rem",
      fontWeight: 400,
    },
    h3: {
      fontFamily: "Lato",
      fontSize: "1.25rem",
      fontWeight: 500,
    },
    h4: {
      fontFamily: "Dosis",
      fontSize: "2.125rem",
      fontWeight: 400,
    },
    h5: {
      fontFamily: "Dosis",
      fontSize: "1.5rem",
      fontWeight: 400,
    },
    h6: {
      fontFamily: "Dosis",
      fontSize: "1.25rem",
      fontWeight: 500,
    },
    subtitle1: {
      fontFamily: "Lato",
      fontSize: "1rem",
      fontWeight: 400,
    },
    subtitle2: {
      fontFamily: "Dosis",
      fontSize: "1rem",
      fontWeight: 400,
    },
  },
});
