import { useAxios } from "api/Axios";
import { useQuery } from "react-query";
import { useStatisticsStore } from "stores";

export const useDiscrepanciesTagsPredicions = () => {
  const axios = useAxios();
  const setDiscrepanciesTagsPredicions = useStatisticsStore(
    (store) => store.setDiscrepanciesTagsPredicions
  );
  const resetDiscrepanciesTagsPredicions = useStatisticsStore(
    (store) => store.resetDiscrepanciesTagsPredicions
  );

  const query = useQuery(
    "/app/v1/statistics/measurements_with_discrepancies/healthy_disease",
    () =>
      axios
        .get("/app/v1/statistics/measurements_with_discrepancies/healthy_disease")
        .then((res) => res.data),
    {
      enabled: false,
      onSuccess: (data) => {
        setDiscrepanciesTagsPredicions(data);
      },
      onError: () => {
        resetDiscrepanciesTagsPredicions();
      },
    }
  );

  return query;
};
