import { useEffect, useState } from "react";

import { useFilteredNotificationsQuery } from "api/queries";
import { useUnreadNotificationsQuery } from '../../api/queries/useUnreadNotificationsQuery';
import { useFilterStore, useNotificationsStore } from "stores";
import { useSortingStore } from "stores/useSortingStore";
import { NotificationsView } from "./NotificationsView";
import { useUpdateNotificationsMutation } from "api/mutations";

export const NotificationsContainer = () => {
  const {
    filteredNotifications,
    currentPage,
    setCurrentPage,
    totalNotifications,
    setCheckedNotifications,
  } = useNotificationsStore();
  const { sortingValue, setSortingValue } = useSortingStore();
  const [selectionModel, setSelectionModel] = useState([]);
  const [sortedValue, setSorting] = useState([]);
  const { dropdownPlantFilter } = useFilterStore();
  const { refetch: refetchNotifications } = useFilteredNotificationsQuery();
  const { refetch: refetchUnreadNotifications } = useUnreadNotificationsQuery();
  const { mutate: updateNotification } = useUpdateNotificationsMutation();

  useEffect(() => {
    setCheckedNotifications(selectionModel);
  }, [selectionModel, setCheckedNotifications]);

  useEffect(() => {
    setSortingValue(sortedValue, 'notifications');
  }, [sortedValue, setSortingValue]);

  useEffect(() => {
    refetchNotifications();
  }, [dropdownPlantFilter, currentPage, refetchNotifications, sortingValue]);

  useEffect(() => {
    refetchUnreadNotifications();
  }, [filteredNotifications, refetchUnreadNotifications]);

  const markNotificationsAsRead = ({ notifications, read }) => {
    if (notifications.length > 0) {
      notifications.map(async (id) => {
       updateNotification({ read, id });
      });
    }
  };

  return (
    <NotificationsView
      filteredNotifications={filteredNotifications}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      totalNotifications={totalNotifications}
      selectedNotifications={selectionModel}
      setSelectionModel={setSelectionModel}
      markNotificationsAsRead={markNotificationsAsRead}
      setSorting={setSorting}
    />
  );
};
