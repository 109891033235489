import { useQuery } from "react-query";

import { useAxios } from "api/Axios";
import { useIndicatorsStore } from "stores";

export const useIndicatorsQuery = () => {
  const axios = useAxios();

  const setIndicators = useIndicatorsStore((store) => store.setIndicators);
  const resetIndicators = useIndicatorsStore((store) => store.resetIndicators);
  const defaultIndicators = useIndicatorsStore(
    (store) => store.defaultIndicators
  );

  const tagsAuxMapping = [
    {
      title: "healthy",
      options: ["min_damage"],
      all: false,
    },
    {
      title: "sick",
      options: [
        "deficiencies",
        "virus",
        "fungi",
        "caterpillars",
        "red_spider",
        "whitefly",
        "aphids",
        "trips",
        "mites",
      ],
      all: true,
    },
  ];

  const query = useQuery(
    "/app/v1/tag_indicators",
    () => axios.get("/app/v1/tag_indicators").then((res) => res.data),
    {
      enabled: false,
      onSuccess: (data) => {
        const userIndicators = data.map((elem) => elem.name);
        const tempIndicators = { ...defaultIndicators };
        for (let index in userIndicators) {
          const indicator = userIndicators[index];
          if (defaultIndicators && defaultIndicators[indicator] !== undefined) {
            tempIndicators[indicator] = true;
          }
        }
        
        if (tempIndicators.disease) {
          const newIndicators = { ...defaultIndicators };
          for (let j in tempIndicators){
            const i = tempIndicators[j];
            if (tagsAuxMapping[1].options.includes(j)){
              newIndicators[j] = true;
            }
          }
          newIndicators.disease = true;
          setIndicators(newIndicators);
        } else {
          setIndicators(tempIndicators);
        }
      },
      onError: () => {
        resetIndicators();
      },
    }
  );

  return query;
};
