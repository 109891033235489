import { Typography } from "@mui/material";
import { styled, Box } from "@mui/material";

import { useLogout } from "hooks";
import { useMeasurementsStore } from "../../stores/useMeasurementsStore";
import Logout from "assets/images/icons/logout-logo-light.svg";
import { useTranslation } from "react-i18next";
//css imports
import './LogoutButton.css';

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  flex: 1,
  flexDirection: "row",
  width: "100%",
  maxHeight: "5rem",
  padding: "2rem",
  "&:hover": {
    background: theme.palette.primary.light,
    cursor: "pointer",
  },
}));

export const LogoutButton = () => {
  const { t } = useTranslation();
  const { logout } = useLogout();
  const {
    resetCheckedMeasurements,
  } = useMeasurementsStore();
  const logOutHandler = () => {
    resetCheckedMeasurements();
    logout();
  };
  return (
    <Container onClick={logOutHandler}>
      <Box display="flex" alignItems="center" gap="1rem">
        <img
          src={Logout}
          className="logout-img"
          alt="Log out"
        ></img>
        <Typography
          component="div"
          variant="h5"
          display="flex"
          alignItems="center"
          sx={{
            fontFamily: "Dosis",
            color: "background.paper",
          }}
        >
          {t("common_LogoutButton_logout")}
        </Typography>
      </Box>
    </Container>
  );
};
