import { Select, MenuItem, Checkbox } from "@mui/material";
import { useEffect, useState } from "react";
import { useTagsStore } from "stores";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const TreeSelect = (props) => {
  const { customStyles, setEditedTags, currentTags } = props;
  const { allTags } = useTagsStore();
  const [partOfThePlant, setPartOfThePlant] = useState("");
  const [stateOfThePlant, setStateOfThePlant] = useState("");
  const [tags, setTags] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    if (!dataLoaded) {
      if (allTags.subtags.length < 3)
        allTags.subtags.push({
          name: "empty",
          alias: "-",
        });
      const findTags = (tagsToFilter) => {
        return tagsToFilter.filter((tag) => currentTags.includes(tag.name));
      };
      const plantPart = findTags(allTags.subtags);
      if (plantPart[0]) {
        setPartOfThePlant(plantPart[0]);
        const plantState = findTags(plantPart[0].subtags);
        if (plantState[0]) {
          setStateOfThePlant(plantState[0]);
          const plantTags = findTags(plantState[0].subtags);
          if (plantState[0].multiselection) {
            setTags(plantTags);
          } else {
            setTags(plantTags[0] || []);
          }
        }
      }
    }
    setDataLoaded(true);
  }, [allTags.subtags, currentTags, dataLoaded]);
  useEffect(() => {
    let tagsToSave = [];
    if (partOfThePlant !== "")
      tagsToSave.push(
        partOfThePlant.name === "empty" ? "" : partOfThePlant.name
      );
    if (stateOfThePlant !== "") tagsToSave.push(stateOfThePlant.name);
    if (tags.length > 0) {
      tags.map((tag) => tagsToSave.push(tag.name));
    } else {
      tagsToSave.push(tags.name || "");
    }
    setEditedTags(tagsToSave);
  }, [partOfThePlant, stateOfThePlant, tags, setEditedTags]);

  const handleChange = (event, level) => {
    const { value } = event.target;
    if (level === "root") {
      setPartOfThePlant(value);
      setStateOfThePlant("");
      setTags([]);
    } else if (level === "leaf" || level === "flower") {
      setStateOfThePlant(value);
      setTags([]);
    } else {
      setTags(value);
    }
  };

  const renderMenuItems = (items) => {
    return items.map((tag) => (
      <MenuItem key={tag.name} value={tag}>
        <label>{tag.alias}</label>
      </MenuItem>
    ));
  };

  const renderSimpleSelect = (value, level) => {
    if (level.name === "empty") return null;
    return (
      <>
        <label style={customStyles.label}>{level.title}</label>
        <Select
          onChange={(event) => handleChange(event, level.name)}
          value={value}
        >
          {renderMenuItems(level.subtags)}
        </Select>
      </>
    );
  };

  const renderMultimpleSelect = (value, level) => {
    return (
      <>
        <label style={customStyles.label}>{level.title}</label>
        <Select
          onChange={(event) => handleChange(event, level.name)}
          value={value}
          multiple={level.multiselection}
          renderValue={(selected) =>
            selected.map((option) => option.alias).join(", ")
          }
          MenuProps={MenuProps}
        >
          {level.subtags.map((tag) => (
            <MenuItem key={tag.name} value={tag}>
              <Checkbox checked={value.indexOf(tag) > -1} />
              <label>{tag.alias}</label>
            </MenuItem>
          ))}
        </Select>
      </>
    );
  };

  return (
    <>
      {renderSimpleSelect(partOfThePlant, allTags)}
      {partOfThePlant !== "" &&
        renderSimpleSelect(stateOfThePlant, partOfThePlant)}
      {stateOfThePlant !== "" &&
        (!stateOfThePlant.multiselection
          ? renderSimpleSelect(tags, stateOfThePlant)
          : renderMultimpleSelect(tags, stateOfThePlant))}
    </>
  );
};
