export const stageValues = [
  {
    name: "-",
    value: null,
  },
  {
    name: "Sowing",
    value: "sow",
  },
  {
    name: "Germination",
    value: "germination",
  },
  {
    name: "Transplant",
    value: "transplant",
  },
  {
    name: "Vegetative",
    value: "vegetative",
  },
  {
    name: "Preflowering",
    value: "preflowering",
  },
  {
    name: "Flowering",
    value: "flowering",
  },
  {
    name: "Harvest",
    value: "harvest",
  },
  {
    name: "Death",
    value: "death",
  },
];

export const sexValues = [
  {
    name: "-",
    value: null,
  },
  {
    name: "Male",
    value: "male",
  },
  {
    name: "Female",
    value: "female",
  },
  {
    name: "Hermaphrodite",
    value: "hermaphrodite",
  },
];
