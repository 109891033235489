import create from "zustand";
import { devtools } from "zustand/middleware";

export const useAuthStore = create(
  devtools(
    (set) => ({
      currentUser: null,
      setCurrentUser: (currentUser) => set((state) => ({ currentUser })),
    }),
    { name: "AuthStore" }
  )
);
