import { useEffect, useState } from "react";
import { RecordsView } from "./RecordsView";

import { useFilteredRecordsQuery } from "api/queries";
import { useRecordsStore, useFilterStore } from "stores";
import { useSortingStore } from "stores/useSortingStore";

export const RecordsContainer = () => {
  const {
    filteredRecords,
    totalRecords,
    setCheckedRecords,
    timeInterval,
    setTimeInterval,
    resetTimeInterval,
    currentPage,
    setCurrentPage,
  } = useRecordsStore();
  const { sortingValue, setSortingValue } = useSortingStore();
  const [selectionModel, setSelectionModel] = useState([]);
  const [dateRangeValue, setDateRangeValue] = useState([]);
  const [sortedValue, setSorting] = useState([]);
  const { dropdownPlantFilter } = useFilterStore();
  const { refetch: refetchRecords } = useFilteredRecordsQuery(timeInterval);

  useEffect(() => {
    setSortingValue(sortedValue, 'records');
  }, [sortedValue, setSortingValue]);

  useEffect(() => {
    const [start, end] = dateRangeValue;
    if (start && end && start <= end) {
      setTimeInterval(
        start.setHours(0, 0, 0, 0),
        end.setHours(23, 59, 59, 999)
      );
    } else if (!start && !end) {
      resetTimeInterval();
    }
    return () => {
      resetTimeInterval();
    };
  }, [setTimeInterval, dateRangeValue, resetTimeInterval]);

  useEffect(() => {
    refetchRecords();
  }, [currentPage, dropdownPlantFilter, refetchRecords, timeInterval, sortingValue]);

  useEffect(() => {
    setCheckedRecords(selectionModel);
  }, [selectionModel, setCheckedRecords]);

  return (
    <RecordsView
      setDateRangeValue={setDateRangeValue}
      dateRangeValue={dateRangeValue}
      filteredRecords={filteredRecords}
      totalRecords={totalRecords}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      setSelectionModel={setSelectionModel}
      setSorting={setSorting}
    />
  );
};
