import { Box } from "@mui/system";
import { Sidebar } from "./Sidebar";

export const PageContainer = ({ children }) => {
  return (
    <Box display="flex" height="100vh" width="100%">
      <Sidebar />
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        height="100vh"
        overflow="auto"
      >
        {children}
      </Box>
    </Box>
  );
};
