import { Dummy } from "components";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

export const NotFound = () => {
  const { t } = useTranslation();
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100vh"
    >
      <Dummy text={t("notfound_NotFound")} />
    </Box>
  );
};
