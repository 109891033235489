import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { isLogged } from '../../helpers/index'

import LoginView from "./LoginView";
import { useLoginMutation } from "api/mutations";
import { useMessagesStore } from "stores";

export const LoginContainer = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const { mutate: loginMutate, isLoading, error } = useLoginMutation();
  const addMessage = useMessagesStore((store) => store.addMessage);

  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (error) {
      addMessage(t("login_LoginContainer_invalidUserOrPass"), "error");
      setIsError(true);
    } else {
      setIsError(false);
    }
  }, [error, addMessage, t]);

  useEffect(() => {
    if (isLogged()) {
      history.push({
        pathname: "/dashboard",
      });
    }
  }, [history]);

  const handleLogin = (username, password) => {
    loginMutate({ username, password });
  };

  return (
    <LoginView
      handleLogin={handleLogin}
      isLoading={isLoading}
      isError={isError}
      setIsError={setIsError}
    />
  );
};
