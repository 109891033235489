import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";

import { AddPlantModal } from "components/modals/AddPlantModal";

export const AddPlantButton = (props) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const onClick = async () => {
    setOpen(true);
  };
  return (
    <>
      <div onClick={onClick} className="plantButtons ml-null show-imgbtns">
        <AddIcon></AddIcon>
        <p className="pl-08r">{t("plants_AddPlantButton_new")}</p>
      </div>

      <AddPlantModal open={open} handleClose={handleClose} />
    </>
  );
};
