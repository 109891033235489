import Carousel from "react-material-ui-carousel";
import { usePlantsStore } from "stores";
import { PlantCarousel } from './PlantCarousel';

export const ShowPlantsCarousel = (props) => {
    let plants = usePlantsStore((store) => store.checkedPlants);

    if (props.plant) {
    plants = [props.plant];
  }
  
  if (plants.length === 1) {
    return (
      <Carousel
        indicators={false}
        navButtonsProps={{
          style: {
            display: "none",
          },
        }}
      >
        {plants.map((item, i) => (
          <PlantCarousel key={i} item={item} />
        ))}
      </Carousel>
    );
  }
  
  return (
    <Carousel
      autoPlay={false}
      animation="slide"
      navButtonsAlwaysVisible={true}
      duration={500}
    >
      {plants.map((item, i) => (
        <PlantCarousel key={i} item={item} />
      ))}
    </Carousel>
  );
};