import { useAxios } from "api/Axios";
import { useMutation, useQueryClient } from "react-query";
import { useTranslation } from 'react-i18next';
import { useMessagesStore } from "stores";

export const useIndicatorsMutation = () => {
  const { t } = useTranslation();
  const axios = useAxios();

  const queryClient = useQueryClient();

  const { addMessage } = useMessagesStore();
  const mutation = useMutation(
    (data) => {
      const options = {
        method: "POST",
        data,
        url: "/app/v1/tag_indicators/bulk_update",
      };
      return axios(options).then((res) => res.data);
    },
    {
      onSuccess: (data) => {
        addMessage(t("mutations_indicators_success_updated"), "success");
        queryClient.fetchQuery({ queryKey: "/app/v1/tag_indicators" });
      },
    }
  );
  return mutation;
};
