import React from "react";
import Dialog from "@mui/material/Dialog";
import { useTranslation } from "react-i18next";

import { styled } from "@mui/material";
import { ShowPlantsCarousel } from "../carousel/ShowPlantsCarousel";
//style import
import { classes } from '../../utils/customClasses';

const StyledDialog = styled(Dialog)(({ theme }) => {
  return {
    ".MuiDialog-paper": {
      maxWidth: "60rem",
      width: "60rem",
      // textAlign: "center",
      display: "flex",
      justifyContent: "center",
    },
  };
});

const styles = {
  content: {
    ...classes.content,
  },
  h3: {
    ...classes.h3,
  },
  resLabel: {
    ...classes.resLabel,
  },
  okButton: {
    ...classes.okButton,
  },
  disabled: {
    ...classes.disabled,
  },
  cancelar: {
    ...classes.cancelar,
  },
  errorBox: {
    ...classes.errorBox,
  },
  errorRow: {
    ...classes.errorRow,
  },
  buttonContainer: {
    ...classes.buttonContainer,
  },
};

export const ShowPlantModal = (props) => {
  const { open = false, handleClose = () => {}, plant } = props;
  const { t } = useTranslation()
 
  return (
    <div>
      <StyledDialog open={open} onClose={handleClose} style={styles.p}>
        <ShowPlantsCarousel plant={plant}></ShowPlantsCarousel>

        <div style={styles.buttonContainer}>
          <div
            style={styles.okButton}
            onClick={() => {
              handleClose();
            }}
          >
            <p style={styles.p}>{t("common_close")}</p>
          </div>
        </div>
      </StyledDialog>
    </div>
  );
};
