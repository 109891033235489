import { useEffect } from "react";
import { Route, Redirect, useHistory } from "react-router-dom";
import { isLogged } from '../helpers'

const PrivateRoute = ({ component: RouteComponent, ...rest }) => {
  const history = useHistory();

  useEffect(() => {
    if (!isLogged()) {
      history.push({
        pathname: "/login",
      });
    }
  }, [history]);

  return (
    <Route
      {...rest}
      render={(routeProps) =>
        isLogged() ? (
          <RouteComponent {...routeProps} />
        ) : (
          <Redirect to={"/login"} />
        )
      }
    />
  );
};

export default PrivateRoute;
