import { saveAs } from "file-saver";

import { buildXLSX } from ".";
import { DateTime } from "luxon";

const recordsHeaders = {
  custom_id: "Plant ID",
  timestamp: "Edition date",
  planting_date: "Planting Date",
  lot: "Lot",
  facility: "Facility",
  genetic: "Genetic",
  zone: "Zone",
  table: "Table",
  plate: "Plate",
  stage: "Stage",
  sex: "Sex",
  treatment: "Treatment",
  description: "Description",
};

export const downloadRecords = async (records) => {
  const recordsWithFixedDate = records.map((elem) => {
    const timestamp = DateTime.fromMillis(elem.timestamp).toFormat(
      "dd/MM/yyyy"
    );
    if (!elem.planting_date) {
      return {
        ...elem,
        timestamp,
      };
    }
    return {
      ...elem,
      timestamp,
      planting_date: new Date(
        Date.parse(elem.planting_date)
      ).toLocaleDateString("es-AR", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      }),
    };
  });
  const xlsx = await buildXLSX(recordsWithFixedDate, recordsHeaders);
  saveAs(xlsx, "Records.xlsx");
};
