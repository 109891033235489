import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import DownloadIcon from "@mui/icons-material/Download";

import { NoSelectedElemetsModal } from "components/modals/NoSelectedElemetsModal";
import { downloadPlants } from "../../../services";
import { usePlantsStore } from "stores";

export const DownloadPlantsButton = () => {
  const { t } = useTranslation();
  const plants = usePlantsStore((store) => store.checkedPlants);

  const onClick = async () => {
    if (plants.length) {
      await downloadPlants(plants);
    } else setOpen(true);
  };
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  return (
    <>
      <div onClick={onClick} className="cancelButton, plantButtons">
        <DownloadIcon></DownloadIcon>
        <p className="pl-08r">{t("plants_DownloadButton_download")}</p>
      </div>
      <NoSelectedElemetsModal
        open={open}
        handleClose={handleClose}
        elementType="plants"
      />
    </>
  );
};
