import { useAxios } from "api/Axios";
import { useQuery } from "react-query";
import { useStatisticsStore } from "stores";

export const useTotalCountDiseasePercentagesQuery = (params = {}) => {
  const axios = useAxios();

  const setTotalUploadedImages = useStatisticsStore(
    (store) => store.setTotalUploadedImages
  );
  const setDiseasePercentagesTags = useStatisticsStore(
    (store) => store.setDiseasePercentagesTags
  );
  const resetTotalUploadedImages = useStatisticsStore(
    (store) => store.resetTotalUploadedImages
  );
  const resetDiseasePercentagesTags = useStatisticsStore(
    (store) => store.resetDiseasePercentagesTags
  );

  const start = new Date();
  start.setHours(0, 0, 0, 0);

  const start_timestamp = params?.start_timestamp || start.getTime();
  const end_timestamp = params?.end_timestamp || new Date().getTime();
  const timezone =
    params?.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone;

  params = {
    start_timestamp,
    end_timestamp,
    timezone,
  };
  const query = useQuery(
    "/app/v1/statistics/total_count_disease_percentages",
    () =>
      axios
        .get("/app/v1/statistics/total_count_disease_percentages", {
          params,
        })
        .then((res) => res.data),
    {
      enabled: false,
      onSuccess: (data) => {
        setTotalUploadedImages(data.total);
        setDiseasePercentagesTags(data.names);

      },
      onError: () => {
        resetTotalUploadedImages();
        resetDiseasePercentagesTags();
      },
    }
  );

  return query;
};
