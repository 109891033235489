import { useMutation, useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";

import { useAxios } from "api/Axios";
import { useMessagesStore } from "stores";

export const useBulkUpdateMeasurementTag = (data) => {
  const { t } = useTranslation();
  const axios = useAxios();
  const queryClient = useQueryClient();
  const { addMessage } = useMessagesStore();

  const mutation = useMutation(
    (data) => {
      /* console.log('data: ', data) */
      const { id, tags } = data;
      const options = {
        method: "POST",
        data: JSON.stringify(tags),
        url: `/app/v1/tag_measurements/${id}/bulk_update`,
      };

      return axios(options).then((res) => res.data);
    },
    {
      onSuccess: (data) => {
        addMessage(t("mutations_plant_success_updated"));
        queryClient.fetchQuery({ queryKey: "filteredMeasurements" });
      },
      onError: (error) => {
        addMessage(t("mutations_plants_error_deleted"), "error");
      },
    }
  );

  return mutation;
};
