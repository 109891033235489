import { useTranslation } from "react-i18next";
import { getKeyByValue } from '../../../../utils/traslate';
import { DateRangePicker } from "@mantine/dates";
import { GridOverlay, DataGrid, esES } from "@mui/x-data-grid";
import { styled, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { DateTime } from "luxon";

import Isotipo from "../../../../assets/images/isotipo/PNG/modal.png";
import { DownloadRecordsButton } from "./buttons/index.js";
//css import
import './recordsTableSection.css';

const StyledDataGrid = styled(DataGrid)(({ theme }) => {
  return {
    ".MuiDataGrid-columnHeaders": {
      color: "white",
      backgroundColor: theme.palette.dataGrid.header,
    },

    ".MuiDataGrid-sortIcon": {
      color: "white",
    },
  };
});

const StyledButtonsBox = styled(Box)(({ theme }) => {
  return {
    ".leftButtons": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-evenly",
      alignItems: "center",
    },

    ".rightButtons": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      minWidth: "fit-content",
    },
    ".recordsButtons": {
      backgroundColor: "#a4a4c2",
      marginLeft: "5px",
      width: "fit-content",
      height: "5.5vh",
      padding: "0 .5em",
      color: "white",
      borderRadius: "5px",
      cursor: "pointer",
      textAlign: "center",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "1em",
    },
  };
});

const getUIDate = (params) => {
  return DateTime.fromMillis(params.value).toFormat("yyyy-MM-dd");
};

export const RecordsTable = (props) => {
  const {
    setDateRangeValue,
    dateRangeValue,
    filteredRecords,
    totalRecords,
    currentPage,
    setCurrentPage,
    setSelectionModel,
    setSorting
  } = props;
  const { t, i18n } = useTranslation();

  const recordsTranslated = filteredRecords.map((record) => {
    const sex = getKeyByValue(record.sex)
    const stage = getKeyByValue(record.stage)   
    return { ...record, sex: t(sex), stage: t(stage)}
  })
  
  const localeEs = esES.components.MuiDataGrid.defaultProps.localeText;
  const columns = [
    {
      field: "custom_id",
      headerName: t("common_header_plantid"),
      flex: 1,
      headerClassName: "my_header",
    },
    {
      field: "timestamp",
      headerName: t("records_RecordsTable_timestamp"),
      flex: 1,
      valueGetter: getUIDate,
    },
    {
      field: "planting_date",
      headerName: t("common_header_planting_date"),
      flex: 1,
    },
    {
      field: "lot",
      headerName: t("common_header_lot"),
      flex: 0.5,
    },
    {
      field: "facility",
      headerName: t("common_header_facility"),
      flex: 0.75,
    },

    {
      field: "genetic",
      headerName: t("common_header_genetic"),
      flex: 0.75,
    },
    {
      field: "zone",
      headerName: t("common_header_zone"),
      flex: 0.75,
    },
    {
      field: "table",
      headerName: t("common_header_table"),
      flex: 0.75,
    },
    {
      field: "plate",
      headerName: t("common_header_plate"),
      flex: 0.75,
    },
    {
      field: "stage",
      headerName: t("common_header_stage"),
      flex: 0.75,
    },
    {
      field: "sex",
      headerName: t("common_header_sex"),
      flex: 0.5,
    },
    {
      field: "treatment",
      headerName: t("common_header_treatment"),
      flex: 0.75,
    },
  ];

  function CustomNoRowsOverlay() {
    return (
      <GridOverlay>
        <div className="no-records"
        >
          <Box>
            <img src={Isotipo} alt="" />
          </Box>
          <Box sx={{ mt: 1 }}>{t("records_RecordsTable_norecords")}</Box>
        </div>
      </GridOverlay>
    );
  }

  return (
    <>
      <StyledButtonsBox
        className="right-download-btns"
      >
        <div className="rightButtons">
          <DownloadRecordsButton />
        </div>
        <Box
          display="flex"
          gap="1rem"
          alignItems="center"
          justifyContent="right"
        >
          <Typography
            color="primary.gradientDark"
            fontSize="1.2rem"
            fontWeight={500}
          >
            {t("dashboard_ChartsSection_dateFilter")}
          </Typography>
          <DateRangePicker
            placeholder={t("common_RangeDayPicker_select")}
            locale={i18n.language}
            style={{ minWidth: 280 }}
            maxDate={new Date()}
            allowSingleDateInRange={true}
            value={dateRangeValue}
            onChange={setDateRangeValue}
          />
        </Box>
      </StyledButtonsBox>

      <Box height="520px">
        <StyledDataGrid
          components={{
            NoRowsOverlay: CustomNoRowsOverlay,
          }}
          rowHeight={40}
          rows={recordsTranslated}
          rowCount={parseInt(totalRecords)}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          disableSelectionOnClick
          disableColumnMenu
          pagination
          paginationMode="server"
          sortingMode="server"
          page={currentPage}
          onPageChange={(page) => setCurrentPage(page)}
          checkboxSelection
          onSortModelChange={(sortValue) => {
            setSorting(sortValue)
          }}
          localeText={i18n.language === "es" ? localeEs : null}
          onSelectionModelChange={(newSelectionModel) => {
            setSelectionModel(newSelectionModel);
          }}
        />
      </Box>
    </>
  );
};
