import { useEffect } from "react";
import { styled, Autocomplete, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

import { usePlantsStore, useFilterStore } from "stores";
import { usePlantFiltersQuery } from "api/queries";
import "./components.css";

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => {
  return {
    ".MuiOutlinedInput-notchedOutline": {
      borderWidth: "0px",
    },
    ".MuiSvgIcon-root": {
      visibility: "visible",
      padding: "2px",
      fontSize: "1.25rem",
    },
    ".MuiButtonBase-root": {
      visibility: "visible",
      width: "35%",
    },
    ".MuiAutocomplete-endAdornment": {
      display: "flex",
      justifyContent: "flex-end",
      marginRight: "2px",
    },
  };
});

export const DropPlantID = () => {
  const { t } = useTranslation();
  const { filtersData } = usePlantsStore();
  const { refetch: refetchFilters } = usePlantFiltersQuery();
  const {
    dropdownPlantFilter,
    setDropdownPlantFilter,
    resetDropdownPlantFilter,
  } = useFilterStore();

  const userPlantIds = filtersData.ids;

  useEffect(() => {
    refetchFilters();
  }, [refetchFilters, filtersData]);

  useEffect(() => {
    return () => {
      // Cleanup function -> this is called when the UI is destroyed
      resetDropdownPlantFilter();
    };
  }, [resetDropdownPlantFilter]);

  const onPlantIdchange = (selectedId) => {
    const customIdMatch = userPlantIds.find((elem) => selectedId === elem);
    customIdMatch !== undefined
      ? setDropdownPlantFilter(customIdMatch)
      : resetDropdownPlantFilter();
  };

  return (
    <>
      {userPlantIds && (
        <StyledAutocomplete
          disablePortal
          defaultValue={dropdownPlantFilter}
          id="idDropdown"
          onInputChange={(event, selectedId) => {
            onPlantIdchange(selectedId);
          }}
          options={userPlantIds}
          sx={{
            width: 300,
            backgroundColor: "white",
            border: "#c4c4c4",
            borderWidth: "1px",
            borderStyle: "solid",
            borderRadius: "4px",
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("measurements_DropPlantID_label")}
            />
          )}
        />
      )}
    </>
  );
};
