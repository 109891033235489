import { Box, Divider } from "@mui/material";
import { PageContainer } from "components";

import { MeasurementsFilterSection } from "./components";
import { MeasurementsTable } from "./components/MeasurementsTableSection";

export const MeasurementsView = (props) => {
  const {
    dateRangeValue,
    setDateRangeValue,
    filteredMeasurements,
    totalMeasurements,
    loading,
    currentPage,
    changePage,
    setCheckedMeasurements,
    tagsDictionary,
    editMeasurement,
    validateMeasurement,
    setSorting
  } = props;

  return (
    <PageContainer>
      <Box width="100%" maxHeight="100%" padding="1rem">
        <MeasurementsFilterSection />
        <Box marginY="1rem">
          <Divider variant="middle" />
        </Box>
        <MeasurementsTable
          dateRangeValue={dateRangeValue}
          setDateRangeValue={setDateRangeValue}
          filteredMeasurements={filteredMeasurements}
          totalMeasurements={totalMeasurements}
          loading={loading}
          currentPage={currentPage}
          changePage={changePage}
          setCheckedMeasurements={setCheckedMeasurements}
          tagsDictionary={tagsDictionary}
          editMeasurement={editMeasurement}
          validateMeasurement={validateMeasurement}
          setSorting={setSorting}
        />
      </Box>
    </PageContainer>
  );
};
