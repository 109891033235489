import * as React from "react";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import esLocale from "date-fns/locale/es";
import { parseISO } from "date-fns";
import { format } from "date-fns";
import { convertToLocalTime } from "date-fns-timezone";
const localeMap = {
  es: esLocale,
};
const maskMap = {
  es: "__/__/____",
};
const DEFAULT_DATE_FORMAT = "yyyy-MM-dd";
export const DayPicker = (props) => {
  const { defaultValue, setPlanting } = props;
  const [value, setValue] = React.useState(defaultValue);
  const locale = "es";

  const formatDate = (date) => {
    if (!date) return new Date().toLocaleString();

    // Get the timezone from browser using native methods
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const dateTmp = Date.parse(date.toLocaleString());

    const localDate = convertToLocalTime(dateTmp, {
      timeZone: timezone,
    });

    return format(localDate, DEFAULT_DATE_FORMAT);
  };

  const handleDateAtOnChange = (event) => {
    const newDate = formatDate(event);
    setValue(newDate);
    setPlanting(newDate);
  };

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      locale={localeMap[locale]}
    >
      <DatePicker
        mask={maskMap[locale]}
        value={parseISO(value)}
        onChange={handleDateAtOnChange}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>
  );
};
