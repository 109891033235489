import { Box, Divider } from "@mui/material";

import { PageContainer } from "components";
import { PlantsFilterSection, PlantsTableSection } from "./components";

export const PlantsView = (props) => {
  const {
    filteredPlants,
    currentPage,
    setCurrentPage,
    totalPlants,
    setSelectionModel,
    setSorting,
  } = props;
  return (
    <PageContainer>
      <Box width="100%" maxHeight="100%" padding="1rem">
        <PlantsFilterSection />
        <Box marginY="1rem">
          <Divider variant="middle" />
        </Box>
        <PlantsTableSection
          filteredPlants={filteredPlants}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          setSelectionModel={setSelectionModel}
          setSorting={setSorting}
          totalPlants={totalPlants}
        />
      </Box>
    </PageContainer>
  );
};
