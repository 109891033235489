import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { NoSelectedElemetsModal } from "components/modals/NoSelectedElemetsModal";
import { ActionsModal } from "components/modals/ActionsModal";
import { usePlantsStore } from "stores";

export const ActionsButton = (props) => {
  const { t } = useTranslation();
  const plants = usePlantsStore((store) => store.checkedPlants);

  const [open, setOpen] = useState(false);
  const [noPlantOpen, setNoPlantOpen] = useState(false);
  const onClick = async () => {
    if (plants.length > 0) setOpen(true);
    else setNoPlantOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleNoPlantClose = () => {
    setNoPlantOpen(false);
  };
  return (
    <>
      <div onClick={onClick} className="cancelButton, plantButtons">
        <p>{t("common_action")}</p>
      </div>
      <ActionsModal open={open} handleClose={handleClose} />
      <NoSelectedElemetsModal
        open={noPlantOpen}
        handleClose={handleNoPlantClose}
        elementType="plants"
      />
    </>
  );
};
