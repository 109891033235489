import create from "zustand";
import { devtools } from "zustand/middleware";

export const useTagsStore = create(
  devtools(
    (set) => ({
      tagsInternationalizationDict: {},
      setTagsInternationalizationDict: (tagsInternationalizationDict) =>
        set((state) => ({ tagsInternationalizationDict })),

      allTags: {},
      setAllTags: (allTags) => set((state) => ({ allTags })),
    }),

    { name: "TagsStore" }
  )
);
