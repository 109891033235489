import { useAxios } from "api/Axios";
import { useQuery } from "react-query";
import { useStatisticsStore } from "stores";

export const useCoincidenciesTagPrediction = () => {
  const axios = useAxios();
  const setCoincidenciesTagsPredictions = useStatisticsStore(
    (store) => store.setCoincidenciesTagPrediction
  );
  const resetCoincidenciesTagsPredictions = useStatisticsStore(
    (store) => store.resetCoincidenciesTagPrediction
  );
  /* console.log('tag: ', tag) */

  const params = {
    name: 'disease',
    skip: 0,
    limit: 100,
  };

  const query = useQuery(
    "/app/v1/statistics/measurements_with_coincident_tags_and_predictions",
    () =>
      axios
        .get('/app/v1/statistics/measurements_with_coincident_tags_and_predictions', {
            params,
        })
        .then((res) => res.data),
    {
      enabled: false,
      onSuccess: (data) => {
        setCoincidenciesTagsPredictions(data);
      },
      onError: () => {
        resetCoincidenciesTagsPredictions();
      },
    }
  );

  return query;
};