import { createContext, useMemo, useContext } from "react";
import Axios from "axios";
import { config } from "../config";
import { useLogout, useTokens } from "hooks";

export const AxiosContext = createContext(undefined);

export const AxiosProvider = ({ children }) => {
  const { getTokens } = useTokens();
  const { logout } = useLogout();

  const axios = useMemo(() => {
    const axios = Axios.create({
      baseURL: config.baseApiUrl,
      headers: {
        "Content-Type": "application/json",
      },
    });

    axios.interceptors.request.use(async (axiosConfig) => {
      const { accessToken } = await getTokens();
      const token = accessToken || localStorage.getItem("accessToken");
      if (token) {
        axiosConfig.headers.Authorization =
          axiosConfig.headers.Authorization || `Bearer ${token}`;
      }
      return axiosConfig;
    });

    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        const errorText = JSON.parse(error.request.responseText);
        if (error.request && error.request.status === 401 && errorText.detail !== "Incorrect username or password") {
          logout("Su sesión ha caducado. Vuelva a iniciar sesión");
        }
        throw error;
      }
    );

    return axios;
  }, [getTokens, logout]);

  return (
    <AxiosContext.Provider value={axios}>{children}</AxiosContext.Provider>
  );
};

export const useAxios = () => {
  return useContext(AxiosContext);
};
