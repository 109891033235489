import create from "zustand";
import { devtools } from "zustand/middleware";
import { v4 as uuidv4 } from "uuid";

export const useMessagesStore = create(
  devtools(
    (set) => ({
      messages: [],

      addMessage: (message = " ", severity = "info", timeout = 3000) =>
        set((state) => {
          if (
            !state.messages.some(
              (prevMessage) => prevMessage.message === message
            )
          )
            return {
              messages: [
                ...state.messages,
                { id: uuidv4(), message, severity, timeout },
              ],
            };
        }),

      deleteMessage: (messageId) =>
        set((state) => ({
          messages: state.messages.filter(
            (message) => message.id !== messageId
          ),
        })),
    }),
    { name: "MessagesStore" }
  )
);
