import * as translationEnglishFile from "../locales/en.json";
import * as translationSpanishFile from "../locales/es.json";

export const getKeyByValue = (diseaseName) => {
  switch (diseaseName) {
    case "trips":
      return Object.keys(translationSpanishFile.default).find(
        (translationKey) =>
          String(
            translationSpanishFile.default[translationKey]
          ).toLowerCase() === diseaseName
      );
    case "full_health":
      diseaseName = "full health";
      return makeTranslation(diseaseName);
    case "red_spider":
      diseaseName = "red spider";
      return makeTranslation(diseaseName);
    case "min_damage":
      diseaseName = "minimal damage";
      return makeTranslation(diseaseName);
    default:
      return makeTranslation(diseaseName);
  }
};

function makeTranslation(diseaseName) {
  return Object.keys(translationEnglishFile.default).find(
    (translationKey) =>
      String(translationEnglishFile.default[translationKey]).toLowerCase() ===
      diseaseName
  );
}
