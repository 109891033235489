import { useCallback } from "react";
import * as axios from "axios";
import { config } from "../config";

export const useRefresh = () => {

  const refresh = useCallback(async () => {
    const refreshToken = localStorage.getItem("refreshToken");
    const options = {
      method: "POST",
      headers: { Authorization: `Bearer ${refreshToken}` },
      baseURL: config.baseApiUrl,
      url: "/app/v1/auth/refresh",
    };

    const response = await axios(options);
    return response.data;
  }, []);

  return { refresh };
};
