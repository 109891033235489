import create from "zustand";
import { devtools } from "zustand/middleware";

const defaultIndicators = {
  min_damage: false,
  disease: false,
  deficiencies: false,
  excesses: false,
  virus: false,
  fungi: false,
  caterpillars: false,
  red_spider: false,
  whitefly: false,
  aphids: false,
  trips: false,
  mites: false,
  unknown: false,
};

export const useIndicatorsStore = create(
  devtools(
    (set) => ({
      defaultIndicators: defaultIndicators,
      indicators: defaultIndicators,
      setIndicators: (indicators) => set((state) => ({ indicators })),
      resetIndicators: () =>
        set((state) => ({ indicators: defaultIndicators })),
    }),
    { name: "IndicatorsStore " }
  )
);
