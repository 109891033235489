import { useSortingStore } from "../stores/useSortingStore";

export const useSortingParamsHelper = (page, fSort) => {
  const { sortingValue, sortingPage } = useSortingStore();
  let finalSort = (sortingPage !== page || sortingValue.length === 0) ? fSort : sortingValue[0].field;
  let finalOrder = (sortingPage !== page || sortingValue.length === 0) ? "desc" : sortingValue[0].sort;
  return {
    finalSort: finalSort,
    finalOrder: finalOrder
  };
};
