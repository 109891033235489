import { useAxios } from "api/Axios";
import { useQuery } from "react-query";
import { useTagsStore } from "stores";

export const useAllTagsQuery = (currentLanguage = 'es') => {
  const axios = useAxios();
  const { setAllTags } = useTagsStore();
  const query = useQuery(
    `/app/v1/app_conf/all_tags?lang=${currentLanguage}`,
    () => axios.get(`/app/v1/app_conf/all_tags?lang=${currentLanguage}`).then((res) => res.data),
    {
      enabled: false,
      onSuccess: (data) => {
        setAllTags(data);
      },
    }
  );

  return query;
};
