import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useAuthStore, useMessagesStore } from "stores";

export const useLogout = () => {
  const { setCurrentUser } = useAuthStore();

  const { addMessage } = useMessagesStore();
  const history = useHistory();

  const logout = useCallback(async (msg) => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    setCurrentUser(null);

    if (msg) addMessage(msg, "success");

    history.push({
      pathname: "/login",
    });

  }, [setCurrentUser, history, addMessage]);

  return { logout };
};
