import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { styled, Box, Dialog, MenuItem, FormControl, Select, Button } from "@mui/material";

import { stageValues } from "../../constants";
import { useBulkDeletePlantMutation } from "api/mutations";
import { useBulkUpdatePlantMutation } from "api/mutations";
import { usePlantsStore } from "stores";
import { classes } from '../../utils/customClasses';

const StyledDialog = styled(Dialog)(({ theme }) => {
  return {
    ".MuiDialog-paper": {
      maxWidth: "60rem",
      width: "60rem",
      display: "flex",
      justifyContent: "center",
    },
    ".MuiInputBase-root": {
      maxHeight: "32px",
      minHeight: "32px",
      color: "rgb(106, 124, 161)",
    },
    ".MuiOutlinedInput-notchedOutline": {
      border: "1px solid #cecee4",
    },
    ".MuiButton-root": {
      backgroundColor: "red",
    },
  };
});

export const ActionsModal = (props) => {
  const { open = false, handleClose = () => {} } = props;
  const { t } = useTranslation();

  const [stage, setStage] = useState(null),
    [lot, setLot] = useState(""),
    [facility, setFacility] = useState(""),
    [treatment, setTreatment] = useState(""),
    [zone, setZone] = useState(""),
    [table, setTable] = useState(""),
    [plate, setPlate] = useState("");

  let plantsIds = usePlantsStore((store) => store.checkedPlants).map(
    (elem) => elem.id
  );

  const { mutate: bulkDeletePlants } = useBulkDeletePlantMutation();
  const { mutate: bulkupdatePlants } = useBulkUpdatePlantMutation();

  const deleteSelectedPlants = () => {
    bulkDeletePlants(plantsIds);
    handleClose();
  };

  const updateSelectedPlants = () => {
    let updateData = {};
    if (lot !== "") updateData.lot = lot;
    if (facility !== "") updateData.facility = facility;
    if (zone !== "") updateData.zone = zone;
    if (table !== "") updateData.table = table;
    if (plate !== "") updateData.plate = plate;
    if (stage !== "") updateData.stage = stage;
    if (treatment !== "") updateData.treatment = treatment;
    bulkupdatePlants({ plantsIds, updateData });
    handleClose();
  };

  return (
    <StyledDialog open={open} onClose={handleClose}>
      <Box style={customStyles.wrapperBox}>
        <Box style={customStyles.topBox}>
          <Box style={customStyles.headersBox}>
            <h2 style={customStyles.h2}>{t("plants_ActionsModal_title")}</h2>
            <h3 style={customStyles.h3}>
              {t("plants_ActionsModal_description")}
            </h3>
          </Box>
          <Box style={customStyles.deleteButton}>
            <Button
              variant="contained"
              component="span"
              onClick={deleteSelectedPlants}
            >
              {t("common_delete")}
            </Button>
          </Box>
        </Box>
        <div style={customStyles.inputContainer}>
          <div style={customStyles.inputDiv}>
            <label style={customStyles.label}>{t("common_header_lot")}</label>
            <input
              style={customStyles.input}
              type="text"
              value={lot}
              onChange={(e) => {
                setLot(e.target.value);
              }}
            />
          </div>
          <div style={customStyles.inputDiv}>
            <label style={customStyles.label}>{t("common_header_facility")}</label>
            <input
              style={customStyles.input}
              type="text"
              value={facility}
              onChange={(e) => {
                setFacility(e.target.value);
              }}
            />
          </div>
          <div style={customStyles.inputDiv}>
            <label style={customStyles.label}>{t("common_header_zone")}</label>
            <input
              style={customStyles.input}
              type="text"
              value={zone}
              onChange={(e) => {
                setZone(e.target.value);
              }}
            />
          </div>
          <div style={customStyles.inputDiv}>
            <label style={customStyles.label}>{t("common_header_table")}</label>
            <input
              style={customStyles.input}
              type="text"
              value={table}
              onChange={(e) => {
                setTable(e.target.value);
              }}
            />
          </div>
          <div style={customStyles.inputDiv}>
            <label style={customStyles.label}>{t("common_header_plate")}</label>
            <input
              style={customStyles.input}
              type="text"
              value={plate}
              onChange={(e) => {
                setPlate(e.target.value);
              }}
            />
          </div>
          <div style={customStyles.inputDiv}>
            <label style={customStyles.label}>{t("common_header_stage")}</label>
            <FormControl fullWidth>
              <Select
                value={stage ? stage : ""}
                onChange={(e) => {
                  setStage(e.target.value);
                }}
              >
                {stageValues.map((item, key) => {
                  return (
                    <MenuItem key={key} value={item.value}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
          <div style={customStyles.inputDivLast}>
            <label style={customStyles.label}>{t("common_header_treatment")}</label>
            <input
              style={customStyles.input}
              type="text"
              value={treatment}
              onChange={(e) => {
                setTreatment(e.target.value);
              }}
            />
          </div>
        </div>
        <div style={customStyles.buttonContainer}>
          <div
            style={customStyles.cancelar}
            onClick={() => {
              handleClose();
            }}
          >
            <p>{t("common_cancel")}</p>
          </div>
          <div style={customStyles.okButton} onClick={updateSelectedPlants}>
            <p>{t("common_confirm")}</p>
          </div>
        </div>
      </Box>
    </StyledDialog>
  );
};

const customStyles = {
  wrapperBox: {
    padding: "2rem",
  },
  headersBox: {
    width: "60%",
  },
  topBox: {
    ...classes.topBox,
  },
  deleteButton: {
    ...classes.defaultProps,
    ...classes.bordRadFontBold,
    gap: "1rem",
  },
  h2: {
    color: "#6a7ca1",
  },
  h3: {
    color: "#ff001f",
    fontWeight: "400",
  },
  inputContainer: {
    ...classes.topBoxRow,
  },
  inputDivLast: {
    ...classes.inputDivPlus,
  },
  inputDiv: {
    ...classes.inputDivPlus,
  },
  label: {
    fontWeight: "Bold",
  },
  buttonContainer: {
    ...classes.buttonContainerPlantModal,
    paddingTop: "3em",
  },
  okButton: {
    ...classes.okButton,
  },
  input: {
    ...classes.input
  },
  cancelar: {
    ...classes.cancelar,
  },
};
