import React, { useState } from "react";
import { ShowMeasurementModal } from "components/modals/ShowMeasurementModal";
import { NoSelectedElemetsModal } from "components/modals/NoSelectedElemetsModal";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { useMeasurementsStore } from "stores";
import { useTranslation } from "react-i18next";

export const ShowImagesButton = (props) => {
  const { t } = useTranslation();
  const measurements = useMeasurementsStore(
    (store) => store.checkedMeasurements
  );

  const [open, setOpen] = useState(false);
  const [noMeasurementOpen, setNoMeasurementOpen] = useState(false);
  const onClick = async () => {
    if (measurements.length > 0) setOpen(true);
    else setNoMeasurementOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleNoMeasurementClose = () => {
    setNoMeasurementOpen(false);
  };
  return (
    <>
      <div
        onClick={onClick}
        className="cancelButton measurementsButtons ml-null show-imgbtns"
      >
        <RemoveRedEyeIcon></RemoveRedEyeIcon>
        <p className="pl-08r">{t("measurements_ShowImgesButton_viewImages")}</p>
      </div>
      <ShowMeasurementModal open={open} handleClose={handleClose} />
      <NoSelectedElemetsModal
        open={noMeasurementOpen}
        handleClose={handleNoMeasurementClose}
      />
    </>
  );
};
