import { useAxios } from "api/Axios";
import { useQuery } from "react-query";

import { useMeasurementsStore } from "stores";

export const useMeasurementImageQuery = (params) => {
  const theQueryKey = params ? params.toString() : 'measurementQueryKey';

  const axios = useAxios();
  const { setMeasurementWithImage } = useMeasurementsStore();
  const query = useQuery(
    theQueryKey,
    () => {
      return axios.get(`/app/v1/plant_measurements/${params}`).then((res) => res);
    },
    {
      enabled: false,
      onSuccess: (res) => {
        setMeasurementWithImage(res.data);
      },

    }
  );

  return query;
};
