import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

import "./validation.css";
import { classes } from "../../utils/customClasses";
import { ShowImage } from "components/carousel/ShowImage";
import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Checkbox, FormControlLabel } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { getKeyByValue } from "utils/traslate";

export const Validation = (props) => {
  const { t } = useTranslation();
  const {
    item,
    currentMeasurement,
    description,
    onSubmitTags,
    handleClose = () => {},
    filteredMeasurements,
  } = props;
  const [zoom, setZoom] = useState(false);

  const [disable, setDisable] = useState(false);
  const [disableParticularTags, setDisableParticularTags] = useState(false);
  const [isHealthy, setIsHealthy] = useState(false)
  const [isFlower, setIsFlower] = useState(false)

  const [tagsNames, setTagsNames] = useState([]);
  const [predsNames, setPredsNames] = useState([]);

  const { handleSubmit, setValue, getValues, control } = useForm({
    mode: "onBlur",
  });

  let predDisHea = false;
  let predLeafFlow = false;

  let tagDisHea = false;
  let tagLeafFlow = false;

  useEffect(() => {
    item !== undefined &&
      setTagsNames(item.tag_measurements.map((t) => t.name));
    item !== undefined &&
      setPredsNames(item.prediction_measurements.map((p) => p.name));
  }, [filteredMeasurements]);

  tagsNames &&
    tagsNames.sort((a) => {
      if (a === "healthy" || a === "disease") {
        return -1;
      } else {
        return 0;
      }
    });

  predsNames &&
    predsNames.sort((a) => {
      if (a === "healthy" || a === "disease") {
        return -1;
      } else {
        return 0;
      }
    });

  if (predsNames) {
    if (predsNames.includes("disease") || predsNames.includes("healthy")) {
      predDisHea = predsNames.includes("disease") ? "disease" : "healthy";
    }
    if (predsNames.includes("leaf") || predsNames.includes("flower")) {
      predLeafFlow = predsNames.includes("leaf") ? "leaf" : "flower";
    }
  }

  if (tagsNames) {
    if (tagsNames.includes("disease") || tagsNames.includes("healthy")) {
      tagDisHea = tagsNames.includes("disease") ? "disease" : "healthy";
    }
    if (tagsNames.includes("leaf") || tagsNames.includes("flower")) {
      tagLeafFlow = tagsNames.includes("leaf") ? "leaf" : "flower";
    }
  }

  useEffect(() => {
    if ((predsNames && predsNames.length === 0) || (tagDisHea === predDisHea && tagLeafFlow === predLeafFlow)) {
      setDisable(true);
      setDisableParticularTags(true);
    } else {
      setDisable(false);
      setDisableParticularTags(false);
    }
  }, [predsNames]);

  const tagsNamesFiltered = tagsNames.filter(
    (t) => t !== "healthy" && t !== "disease" && t !== "leaf" && t !== "flower"
  );

  const healthyTags = tagsNamesFiltered.filter(
    (t) => t === "min_damage" || t === "full_health"
  );

  const diseaseTags = tagsNamesFiltered.filter(
    (t) => t !== "min_damage" && t !== "full_health"
  );

  const flowerTags = diseaseTags.filter(
    (t) => t === "botrytis" || t === 'advanced' || t === 'initial' || t === 'intermediate'
  )
  
  const leafTags = diseaseTags.filter(
    (t) => t !== "botrytis" || t !== 'advanced' || t !== 'initial' || t !== 'intermediate'
  )

  const setParticularTagsConditionally = (x, checked) => {
    if (x === "disease" && checked) {
      setIsHealthy(false)
      if (tagDisHea === "healthy") {
        setDisableParticularTags(true);
      }
      healthyTags.map((t) => setValue(t, false));
    }
    if (x === "healthy" && checked) {
      setIsHealthy(true)
      if (tagDisHea === "disease") {
        setDisableParticularTags(true);
      }
      diseaseTags.map((t) => setValue(t, false));
    }
    if (x === "leaf" && checked) {
      setIsFlower(false)
      if (tagLeafFlow === 'flower' && !isHealthy){
        setDisableParticularTags(true);
      }
      flowerTags.map((t) => setValue(t, false));
    }
    if (x === "flower" && checked) {
      setIsFlower(true)
      if (tagLeafFlow === 'leaf' && !isHealthy){
        setDisableParticularTags(true);
      }
      leafTags.map((t) => setValue(t, false));
    }
  };

  const diseaseHealthyHandler = (checked, tagPred) => {
    if((tagLeafFlow === 'leaf' && !isFlower) || (tagLeafFlow === 'flower' && isFlower)){
      setDisableParticularTags(false);
    }
    if (tagDisHea !== predDisHea) {
      if (tagPred === "tag") {
        setValue(tagDisHea, checked);
        setParticularTagsConditionally(tagDisHea, checked);
        setValue(predDisHea, !checked);
        setParticularTagsConditionally(predDisHea, !checked);
      } else {
        setValue(predDisHea, checked);
        setParticularTagsConditionally(predDisHea, checked);
        setValue(tagDisHea, !checked);
        setParticularTagsConditionally(tagDisHea, !checked);
      }
    }
  };

  const leafFlowerHandler = (checked, tagPred) => {
    if ((tagDisHea === 'healthy' && isHealthy) || (tagDisHea === 'disease' && !isHealthy)){ 
      //si tagDisHea es 'healthy' es porque los tags son de categoria healthy, 
      //misma logica si tagDisHea es 'disease'
      setDisableParticularTags(false);
    }
    if (tagLeafFlow !== predLeafFlow) {
      if (tagPred === "tag") {
        setValue(tagLeafFlow, checked);
        setParticularTagsConditionally(tagLeafFlow, checked)
        setValue(predLeafFlow, !checked);
        setParticularTagsConditionally(predLeafFlow, !checked)
      } else {
        setValue(predLeafFlow, checked);
        setParticularTagsConditionally(predLeafFlow, checked)
        setValue(tagLeafFlow, !checked);
        setParticularTagsConditionally(tagLeafFlow, !checked)
      }
    }
  };

  function toCapitalize(word) {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }

  function bgColorLogic(t) {
    if (t === "healthy") {
      return "#DDEDDE";
    }
    if (t === "disease") {
      return "#df6363";
    }
  }

  useEffect(() => {
    setValue(tagDisHea, true);
    setValue(tagLeafFlow, true);
    if (tagDisHea !== predDisHea) {
      setValue(predDisHea, false);
    }
    if (tagLeafFlow !== predLeafFlow) {
      setValue(predLeafFlow, false);
    }

    if (tagLeafFlow === 'flower'){
      setIsFlower(true)
      flowerTags.map((t) => setValue(t, true))
    } else if (tagLeafFlow === 'leaf') {
      setIsFlower(false)
      leafTags.map((t) => setValue(t, true))
    }

    if (tagDisHea === 'healthy'){
      setIsHealthy(true)
      healthyTags.map((t) => setValue(t, true));
    } else if (tagDisHea === 'disease') {
      setIsHealthy(false)
      diseaseTags.map((t) => setValue(t, true));
    } else {
      tagsNamesFiltered.map((t) => setValue(t, true));
    }
  }, [tagsNames]);

  return (
    <>
      <form
        noValidate
        disabled={disable}
        onSubmit={handleSubmit((data) => {
          console.log('disease: ', getValues('disease'));
          console.log('healthy: ', getValues('healthy'));
          onSubmitTags({ ...data }, description, getValues('disease'));
        })}
      >
        <Box display="flex" flexDirection="column" width="80%" margin="auto">
          <h2 className="plant-details-title">{t('validation_title')}</h2>
          <ShowImage
            item={item}
            measurement={currentMeasurement}
            setZoom={setZoom}
            zoom={zoom}
          />
          {!zoom && item !== undefined && (
            <div className="validation-table-container">
              <div className={`tag-cont`}>
                <div className="tag-inner-cont">
                  <div className="t-cont">
                    <p className="validation-t">{t("validation_tags")}</p>
                    <div className={`badge-${tagDisHea}`}></div>
                  </div>
                  {tagLeafFlow && (
                    <Controller
                      control={control}
                      name={tagLeafFlow}
                      defaultValue={false}
                      label={tagLeafFlow}
                      render={({ field: { value } }) => {
                        return (
                          <Box
                            sx={{
                              width: "100%",
                              pl: 2,
                              backgroundColor: `${
                                value ? "#DDEDDE" : undefined
                              }`,
                            }}
                          >
                            <FormControlLabel
                              sx={{
                                label: {
                                  marginLeft: "20px",
                                },
                              }}
                              control={
                                <Checkbox
                                  color="primary"
                                  size="small"
                                  onChange={(event) => {
                                    leafFlowerHandler(
                                      event.target.checked,
                                      "tag"
                                    );
                                  }}
                                  checked={value}
                                  disabled={disable}
                                />
                              }
                              label={toCapitalize(t(getKeyByValue(tagLeafFlow)))}
                            />
                          </Box>
                        );
                      }}
                    />
                  )}
                  {tagLeafFlow && <div className="divider-tags" />}
                  {tagDisHea && (
                    <Controller
                      control={control}
                      name={tagDisHea}
                      defaultValue={false}
                      label={tagDisHea}
                      render={({ field: { value } }) => {
                        return (
                          <Box
                            sx={{
                              width: "100%",
                              pl: 2,
                              backgroundColor: `${
                                value ? bgColorLogic(tagDisHea) : undefined
                              }`,
                            }}
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  color="primary"
                                  size="small"
                                  onChange={(event) => {
                                    diseaseHealthyHandler(
                                      event.target.checked,
                                      "tag"
                                    );
                                  }}
                                  checked={value}
                                  disabled={disable}
                                />
                              }
                              label={toCapitalize(t(getKeyByValue(tagDisHea)))}
                            />
                          </Box>
                        );
                      }}
                    />
                  )}
                  {tagDisHea && <div className="divider-tags" />}
                </div>
              </div>
              <div className={`pred-cont`}>
                <div className="pred-inner-cont">
                  <div className="t-cont">
                    <p className="validation-t">{t('validation_predictions')}</p>
                    <div className={`badge-${predDisHea}`}></div>
                  </div>
                  {predLeafFlow && (
                    <Controller
                      control={control}
                      name={predLeafFlow}
                      defaultValue={false}
                      label={predLeafFlow}
                      render={({ field: { value } }) => {
                        return (
                          <Box
                            sx={{
                              width: "100%",
                              pl: 2,
                              backgroundColor: `${
                                value ? "#DDEDDE" : undefined
                              }`,
                            }}
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  color="primary"
                                  size="small"
                                  onChange={(event) => {
                                    leafFlowerHandler(event.target.checked);
                                  }}
                                  checked={value}
                                  disabled={disable}
                                />
                              }
                              label={toCapitalize(t(getKeyByValue(predLeafFlow)))}
                            />
                          </Box>
                        );
                      }}
                    />
                  )}
                  {predLeafFlow && <div className="divider-tags" />}
                  {predDisHea && (
                    <Controller
                      control={control}
                      name={predDisHea}
                      defaultValue={false}
                      label={predDisHea}
                      render={({ field: { value } }) => {
                        return (
                          <Box
                            sx={{
                              width: "100%",
                              pl: 2,
                              backgroundColor: `${
                                value ? bgColorLogic(predDisHea) : undefined
                              }`,
                            }}
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  color="primary"
                                  size="small"
                                  onChange={(event) => {
                                    diseaseHealthyHandler(event.target.checked);
                                  }}
                                  checked={value}
                                  disabled={disable}
                                />
                              }
                              label={toCapitalize(t(getKeyByValue(predDisHea)))}
                            />
                          </Box>
                        );
                      }}
                    />
                  )}
                  {predDisHea && <div className="divider-tags" />}
                </div>
              </div>
            </div>
          )}
          {tagsNamesFiltered.length !== 0 && (
            <div className="validation-table-container column-tags">
              <p className="validation-t">{t('validation_subtags_title')}</p>
              <div className="tags-container tag-cont">
                {tagsNamesFiltered.map((tag, index) => {
                  return (
                    <div className="particular-tag">
                      <Controller
                        key={index}
                        control={control}
                        name={tag}
                        defaultValue={false}
                        label={tag}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <Box
                              sx={{
                                width: "100%",
                                pl: 2,
                                backgroundColor: `${
                                  value ? "#DDEDDE" : undefined
                                }`,
                              }}
                            >
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color="primary"
                                    size="small"
                                    onChange={(event) => {
                                      onChange(event.target.checked);
                                    }}
                                    checked={value}
                                    disabled={disableParticularTags}
                                  />
                                }
                                label={toCapitalize(t(getKeyByValue(tag)))}
                              />
                            </Box>
                          );
                        }}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          <Box display="flex">
            <div style={customStyles.buttonContainer}>
              <div
                style={customStyles.cancelar}
                onClick={() => {
                  handleClose();
                }}
              >
                <p style={customStyles.p}>{t("common_cancel")}</p>
              </div>
            </div>
            <div style={customStyles.buttonContainer}>
              <LoadingButton
                type="submit"
                variant="contained"
                color="secondary"
                sx={{ width: 90 }}
                disabled={disable}
              >
                {t('common_validation_confirm')}
              </LoadingButton>
            </div>
          </Box>
        </Box>
      </form>
    </>
  );
};

const customStyles = {
  h3: {
    color: "#6a7ca1",
  },
  inputContainer: {
    ...classes.inputContainer,
  },
  inputDivLeft: {
    ...classes.inputDivTopBorder,
    width: "45%",
  },
  inputDivRight: {
    ...classes.inputDivTopBorder,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "50%",
  },
  pgBar: {
    width: "30%",
  },
  label: {
    ...classes.label,
  },
  buttonContainer: {
    ...classes.buttonContainerPlantModal,
    justifyContent: "center",
    paddingTop: "3em",
    paddingBottom: "1em",
  },
  cancelar: {
    ...classes.cancelar,
  },
  okButton: {
    ...classes.okButton,
  },
  cancelar: {
    ...classes.cancelar,
  },
};
