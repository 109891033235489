import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import "i18n";

import { ThemeProvider } from "@mui/material";
import { theme } from "theme";

import { MantineProvider } from "@mantine/core";

import { AxiosProvider } from "api/Axios";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

import App from "./App";

const client = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <MantineProvider
        theme={{
          colors: {
            indi: [
              "#e8eefa",
              "#dbe4ff",
              "#b2c9ed",
              "#94add4",
              "#7e94bd",
              "#7286ad",
              "#6A7CA1",
              "#5c719c",
              "#495d87",
              "#384c78",
            ],
          },
          primaryColor: "indi",
        }}
      >
        <ThemeProvider theme={theme}>
          <AxiosProvider>
            <QueryClientProvider client={client}>
              <App />
              <ReactQueryDevtools position={"bottom-right"} />
            </QueryClientProvider>
          </AxiosProvider>
        </ThemeProvider>
      </MantineProvider>
    </BrowserRouter>
  </React.StrictMode>,

  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
