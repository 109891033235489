import { GridOverlay, DataGrid, esES } from "@mui/x-data-grid";
import { styled } from "@mui/material";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import { getKeyByValue } from '../../../../utils/traslate';

import {
  RowButtons,
  DownloadPlantsButton,
  ShowPlantsButton,
  DownloadQRButton,
  AddPlantButton,
  ActionsButton,
  CsvButton,
} from "./buttons";
import Isotipo from "../../../../assets/images/isotipo/PNG/modal.png";

const StyledDataGrid = styled(DataGrid)(({ theme }) => {
  return {
    ".MuiDataGrid-columnHeaders": {
      color: "white",
      backgroundColor: theme.palette.dataGrid.header,
    },

    ".MuiDataGrid-sortIcon": {
      color: "white",
    },
  };
});

const StyledButtonsBox = styled(Box)(({ theme }) => {
  return {
    ".buttons": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      minWidth: "fit-content",
    },
    ".leftButtons": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-evenly",
      alignItems: "center",
    },

    ".rightButtons": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      minWidth: "fit-content",
    },
    ".plantButtons": {
      backgroundColor: "#a4a4c2",
      marginLeft: "5px",
      width: "fit-content",
      height: "5.5vh",
      padding: "0 .5em",
      color: "white",
      borderRadius: "5px",
      cursor: "pointer",
      textAlign: "center",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "1em",
    },
  };
});

export const PlantsTable = (props) => {
  const {
    filteredPlants,
    currentPage,
    setCurrentPage,
    totalPlants,
    setSelectionModel,
    setSorting,
  } = props;
  const { t, i18n } = useTranslation();

  const changePage = (page) => {
    setCurrentPage(page);
  };

  function CustomNoRowsOverlay() {
    return (
      <GridOverlay>
        <div className="no-records"
        >
          <Box>
            <img src={Isotipo} alt="" />
          </Box>
          <Box sx={{ mt: 1 }}>{t("plants_PlantsTable_noplants")}</Box>
        </div>
      </GridOverlay>
    );
  }

  const plantsTranslated = filteredPlants.map((plant) => {
    const sex = getKeyByValue(plant.sex)
    const stage = getKeyByValue(plant.stage) 
    return { ...plant, sex: t(sex), stage: t(stage)}
  })

  const localeEs = esES.components.MuiDataGrid.defaultProps.localeText;
  const columns = [
    {
      field: "custom_id",
      headerName: t("common_header_plantid"),
      flex: 1,
      headerClassName: "my_header",
    },
    {
      field: "planting_date",
      headerName: t("common_header_planting_date"),
      flex: 1.25,
    },
    {
      field: "lot",
      headerName: t("common_header_lot"),
      flex: 0.5,
    },
    {
      field: "facility",
      headerName: t("common_header_facility"),
      flex:1,
    },

    {
      field: "genetic",
      headerName: t("common_header_genetic"),
      flex: 0.75,
    },
    {
      field: "zone",
      headerName: t("common_header_zone"),
      flex: 0.5,
    },
    {
      field: "table",
      headerName: t("common_header_table"),
      flex: 0.75,
    },
    {
      field: "plate",
      headerName: t("common_header_plate"),
      flex: 0.75,
    },
    {
      field: "stage",
      headerName: t("common_header_stage"),
      flex: 1,
    },
    {
      field: "sex",
      headerName:  t("common_header_sex"),
      flex: 0.5,
    },
    {
      field: "treatment",
      headerName: t("common_header_treatment"),
      flex: 1,
    },
    {
      field: "description",
      headerName: t("plants_PlantsTable_description"),
      flex: 1.5,
    },

    {
      field: "actions",
      headerName:  t("common_action"),
      flex: 1.3,
      sortable: false,
      renderCell: (cellValues) => {
        return <RowButtons cellValues={cellValues}></RowButtons>;
      },
      disableClickEventBubbling: true,
    },
  ];

  return (
    <>
      <StyledButtonsBox
        className="btns-table"
      >
        <div className="buttons">
          <AddPlantButton />
          <CsvButton />
          <ActionsButton />
          <DownloadQRButton />
          <ShowPlantsButton />
          <DownloadPlantsButton />
        </div>
      </StyledButtonsBox>

      <Box height="520px">
        <StyledDataGrid
          components={{
            NoRowsOverlay: CustomNoRowsOverlay,
          }}
          rowHeight={40}
          rows={plantsTranslated}
          rowCount={parseInt(totalPlants)}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          disableSelectionOnClick
          disableColumnMenu
          pagination
          paginationMode="server"
          sortingMode="server"
          checkboxSelection
          onSortModelChange={(sortValue) => {
            setSorting(sortValue)
          }}
          page={currentPage}
          localeText={i18n.language === "es" ? localeEs : null}
          onPageChange={(page) => changePage(page)}
          onSelectionModelChange={(newSelectionModel) => {
            setSelectionModel(newSelectionModel);
          }}
        />
      </Box>
    </>
  );
};
