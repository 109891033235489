import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Checkbox, FormControlLabel, Typography, Box } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import './indicatorsForm.css';

export const IndicatorsForm = (props) => {
  const { t } = useTranslation();
  const {
    onSubmitIndicators,
    currentUser,
    initialIndicators,
    isLoadingIndicators,
  } = props;

  const tagsAuxMapping = [
    {
      title: "healthy",
      options: ["min_damage"],
      all: false,
    },
    {
      title: "sick",
      options: [
        "deficiencies",
        "virus",
        "fungi",
        "caterpillars",
        "red_spider",
        "whitefly",
        "aphids",
        "trips",
        "mites",
      ],
      all: true,
    },
  ];

  const [activeNotifications, setActiveNotifications] = useState(false);
  const [allIsChecked, setAllIsChecked] = useState(null);
  const [indCopy, setIndCopy] = useState({});
  const { handleSubmit, setValue, control } = useForm({
    mode: "onBlur",
  });

  useEffect(() => {
    let indicatorsCopy = {};
    if (!initialIndicators.disease){
      setAllIsChecked(null)
    } else {
      setAllIsChecked(true)
    }
    
    Object.keys(initialIndicators).map((key) => {
      if (tagsAuxMapping[1].options.includes(key)) {
        indicatorsCopy[key] = initialIndicators[key];
      }
    });
    setIndCopy(indicatorsCopy);
  }, [initialIndicators]);

  useEffect(() => {
    for (let indicator in initialIndicators) {
      setValue(indicator, initialIndicators[indicator]);
    }

    if (currentUser) {
      const { notifications_enabled } = currentUser;
      setActiveNotifications(notifications_enabled);
    }
  }, [initialIndicators, currentUser, setValue, setActiveNotifications]);

  const tagDictionary = {
    healthy: t("profile_IndicatorsCard_tag_healthy"),
    min_damage: t("profile_IndicatorsCard_tag_min_dammage"),
    sick: t("profile_IndicatorsCard_tag_sick"),
    deficiencies: t("profile_IndicatorsCard_tag_deficiencies"),
    virus: t("profile_IndicatorsCard_tag_viruses"),
    fungi: t("profile_IndicatorsCard_tag_fungi"),
    caterpillars: t("profile_IndicatorsCard_tag_caterpillars"),
    red_spider: t("profile_IndicatorsCard_tag_red_spider"),
    whitefly: t("profile_IndicatorsCard_tag_whitefly"),
    aphids: t("profile_IndicatorsCard_tag_aphids"),
    trips: t("profile_IndicatorsCard_tag_trips"),
    mites: t("profile_IndicatorsCard_tag_mites"),
    unknown: t("profile_IndicatorsCard_tag_unknown"),
  };

  const handleActiveNotifications = () => {
    setActiveNotifications(!activeNotifications);
  };

  const allChangeHandler = (event) => {
    setAllIsChecked(event.target.checked);
    for (let indicator in initialIndicators) {
      if (tagsAuxMapping[1].options.includes(indicator)) {
        setValue(indicator, event.target.checked);
      }
    }
    for (let j in indCopy) {
      indCopy[j] = event.target.checked;
    }
    setIndCopy(indCopy);
  };

  const checkDiseaseValue = (name, bool) => {
    Object.keys(indCopy).find(() => {
      if (tagsAuxMapping[1].options.includes(name)) {
        indCopy[name] = bool;
        setIndCopy({ ...indCopy });
      }
    });
    for (let indicator in indCopy) {
      if (!indCopy[indicator]) {
        return;
      }
    }
    setAllIsChecked(true);
  };

  return (
    <Box width="90%">
      <form
        noValidate
        onSubmit={handleSubmit((data) =>
          onSubmitIndicators({ ...data, notifications: activeNotifications })
        )}
      >
        <Box display="flex" flexDirection="column" alignItems={"center"}>
          <Box display="flex" flexDirection="column" gap="1rem">
            <Box
              padding="1rem"
              display="flex"
              flexDirection="column"
              width="100%"
              alignItems="center"
            >
              <Box display="flex" flexDirection="row" alignItems="center">
                <Checkbox
                  color="primary"
                  size="small"
                  name="notifications"
                  onChange={handleActiveNotifications}
                  checked={activeNotifications}
                />
                <Typography>{t("profile_IndicatorsCard_label")}</Typography>
              </Box>
            </Box>
            {tagsAuxMapping.map((obj, catIndex) => {
              return (
                <>
                  <Box
                    display="flex"
                    flexDirection="column"
                    flex={1}
                    key={obj.title + catIndex}
                  >
                    <div className="checkbox-disease-title">
                      <Typography
                        className="profile-form, profile-indicator-title"
                        variant="h6"
                        color="primary"
                        style={
                          activeNotifications
                            ? { fontWeight: 600, fontSize: "1.1rem" }
                            : {
                                fontWeight: 600,
                                fontSize: "1.1rem",
                                opacity: 0.7,
                              }
                        }
                      >
                        {tagDictionary[obj.title]}
                      </Typography>
                      {obj.all && (
                        <Controller
                          key={obj.all + catIndex}
                          control={control}
                          name={"all"}
                          defaultValue={false}
                          label={"All"}
                          render={({ field: { onChange, value } }) => {
                            return (
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color="primary"
                                    size="small"
                                    onChange={(event) => {
                                      onChange(event.target.checked);
                                      allChangeHandler(event)
                                    }}
                                    checked={allIsChecked}
                                    disabled={!activeNotifications}
                                  />
                                }
                                label={"All"}
                              />
                            );
                          }}
                          borderBottom="1px solid #4f5c77"
                        />
                      )}
                    </div>
                    <Box
                      display="flex"
                      flexDirection="row"
                      maxHeight={200}
                      flexWrap="wrap"
                    >
                      {obj.options.map((indicator, indIndex) => {
                        return (
                          <>
                            <Controller
                              key={indicator + indIndex}
                              control={control}
                              name={indicator}
                              defaultValue={false}
                              label={tagDictionary[indicator]}
                              render={({ field: { onChange, value } }) => {
                                return (
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        color="primary"
                                        size="small"
                                        onChange={(event) => {
                                          setAllIsChecked(null);
                                          setValue('all', false);
                                          onChange(event.target.checked);
                                          checkDiseaseValue(
                                            indicator,
                                            event.target.checked
                                          );
                                        }}
                                        checked={
                                          obj.title !== "sick"
                                            ? value
                                            : !allIsChecked
                                            ? value
                                            : allIsChecked
                                        }
                                        disabled={!activeNotifications}
                                      />
                                    }
                                    label={tagDictionary[indicator]}
                                  />
                                );
                              }}
                            />
                          </>
                        );
                      })}
                    </Box>
                  </Box>
                </>
              );
            })}
          </Box>
          <Box display="flex">
            <LoadingButton
              type="submit"
              variant="contained"
              color="secondary"
              sx={{ width: 90 }}
              loading={isLoadingIndicators}
            >
              {t("common_save")}
            </LoadingButton>
          </Box>
        </Box>
      </form>
    </Box>
  );
};
