import { Box } from "@mui/system";
import { DropPlantID, DropPlantLot } from "../../../../components";

export const PlantsFilterSection = () => {
  return (
    <Box display="flex" justifyContent="space-evenly ">
      <DropPlantID />
      <DropPlantLot />
    </Box>
  );
};
