import create from "zustand";
import { devtools } from "zustand/middleware";

export const useMeasurementsStore = create(
  devtools(
    (set) => ({
      filteredMeasurements: [],
      setFilteredMeasurements: (filteredMeasurements) =>
        set((state) => ({ filteredMeasurements })),

      timeInterval: {},
      setTimeInterval: (start, end) =>
        set((state) => ({
          timeInterval: {
            start,
            end,
          },
        })),
      resetTimeInterval: () => set((state) => ({ timeInterval: {} })),

      totalMeasurements: 0,
      setTotalMeasurements: (totalMeasurements) =>
        set((state) => ({ totalMeasurements })),

      checkedMeasurements: [],
      setCheckedMeasurements: (selectedMeasurementsIds) =>
        set((state) => ({
          checkedMeasurements: state.filteredMeasurements.filter(
            (measurement) => selectedMeasurementsIds.includes(measurement.id)
          ),
        })),
      resetCheckedMeasurements: () =>
        set((state) => ({ checkedMeasurements: [] })),

      sortingValue: [],
      setSortingValue: (sortedV) => set((state) => ({ sortingValue: sortedV })),

      measurementWithImage: {},
      setMeasurementWithImage: (measurementWithImage) =>
        set((state) => ({ measurementWithImage })),

      measurementsWithImages: [],
      setMeasurementsWithImages: (measurementsWithImages) =>
        set((state) => ({ measurementsWithImages })),

      currentPage: 0,
      setCurrentPage: (currentPage) => set((state) => ({ currentPage })),
      resetCurrentPage: () => set((state) => ({ currentPage: 0 })),
    }),

    { name: "MeasurementsStore " }
  )
);
