import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import { ImageCropper } from "./ImageCropper";

export const ImageCropDialog = (props) => {
  const { open, newImage, setCroppedImage, onAccept, onRevert } = props;
  const { t } = useTranslation();

  return (
    <Dialog fullWidth={true} maxWidth="md" open={open} onClose={onAccept}>
      <Box paddingX="1rem" paddingBottom="1rem">
        <DialogTitle>{t("profile_ImageCropDialog_title")}</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              minHeight: 400,
            }}
          >
            <ImageCropper
              newImage={newImage}
              setCroppedImage={setCroppedImage}
            />
          </Box>
        </DialogContent>
        <DialogActions sx={{ paddingX: "1.5rem" }}>
          <Button variant="outlined" onClick={onRevert}>
            {t("common_revert")}
          </Button>
          <Button variant="contained" color="secondary" onClick={onAccept}>
            {t("common_accept")}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};
