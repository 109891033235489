const classDefaultProps = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
};
const classPaddingY2 = {
  paddingTop: "2em",
  paddingBottom: "2em",
} 
const classContainer = {
  ...classDefaultProps,
  position: "fixed",
  width: "15vw",
  height: "7vh",
  justifyContent: "space-evenly",
  backgroundColor: "white",
  left: "83vw",
  top: "88vh",
  borderRadius: "1vw",
  boxShadow: "0px 1px 10px 0px rgba(157,157,157,.8)",
  zIndex: 100,
  transition: "all .3s",
};
const classTopBox = {
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "space-between",
  height: "60%",
};
const classInputDiv = {
  color: "#6a7ca1",
  paddingTop: ".5em",
  paddingBottom: "1em",
};
const btnContainerCommonStyles = {
  ...classDefaultProps,
  ...classPaddingY2,
  justifyContent: "space-between",
};
const bordRadFontBold = {
  borderRadius: "3px",
  fontWeight: "bold",
};
const classHeightPadding = {
  height: "40px",
  padding: "5px 10px 5px 10px",
}
const classCursorHeightP = {
  ...classHeightPadding,
  cursor: "pointer",
  textAlign: "center",
};

export const classes = {
  container: {
    ...classContainer,
  },
  heightPadding: {
    ...classHeightPadding,
  },
  defaultProps: {
    ...classDefaultProps,
  },
  hideContainer: {
    ...classContainer,
    top: "110vh",
  },
  paddingY2: {
    ...classPaddingY2,
  },
  cursorHeightP: {
    ...classCursorHeightP,
  },
  topBox: {
    ...classTopBox,
  },
  btnContainerCommonStyles: {
    ...btnContainerCommonStyles,
  },
  topBoxRow: {
    ...classTopBox,
    flexDirection: "row",
  },
  bordRadFontBold: {
    ...bordRadFontBold,
  },
  cancelar: {
    ...classDefaultProps,
    ...bordRadFontBold,
    ...classCursorHeightP,
    backgroundColor: "#fff",
    color: "#a4a4c2",
    border: "solid 1px #a4a4c2",
  },
  input: {
    width: "100%",
    height: "32px",
    border: "1px solid #cecee4",
    borderRadius: "5px",
    cursor: "pointer",
    lineHeight: "30px",
    outline: "none",
    paddingLeft: "10px",
    color: "#6a7ca1",
    fontSize: "17px",
  },
  okButton: {
    ...classDefaultProps,
    ...bordRadFontBold,
    ...classCursorHeightP,
    gridRow: "2/3",
    gridColumn: "3/4",
    backgroundColor: "#82c64e",
  },
  okButtonAlt: {
    ...classDefaultProps,
    ...bordRadFontBold,
    height: "100%",
    width: "calc(100vw / 16)",
    fontSize: "calc((100vw + 100vh) / 187.5)",
    cursor: "pointer",
    padding: "calc((100vh + 100vw) / 300)",
    backgroundColor: "#82c64e",
  },
  content: {
    minWidth: "800px",
    width: "80vw",
    maxWidth: "80vw",
    height: "fit-content",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    backgroundColor: "#fff",
    zIndex: 100,
    overflow: "hidden",
    fontFamily: "Lato",
  },
  h3: {
    color: "#6a7ca1",
    padding: "2em 0",
  },
  h3Alt: {
    marginTop: "calc(100vh/40)",
    color: "#4f5c77",
    fontSize: "calc((100vh + 100vw) / 150)",
  },
  resLabel: {
    color: "#6a7ca1",
    padding: "1em 0",
  },
  disabled: {
    ...classDefaultProps,
    ...bordRadFontBold,
    ...classHeightPadding,
    backgroundColor: "rgba(190, 251, 144, 0.5)",
    color: "rgba(31, 31, 47, 0.4)",
    textAlign: "center",
  },
  errorBox: {
    width: "100%",
    maxHeight: "6em",
    overflowY: "scroll",
  },
  errorRow: {
    width: "100%",
    height: "2em",
  },
  buttonContainer: {
    ...classDefaultProps,
    paddingTop: "3em",
    paddingBottom: "1em",
    minWidth: "70%",
  },
  buttonContainerPlantModal: {
    ...btnContainerCommonStyles,
    width: "60%",
    margin: "auto",
  },
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
    textAlign: "center",
  },
  formMainBox: {
    width: "80%",
    margin: "auto",
  },
  inputContainer: {
    ...classTopBox,
    borderBottom: "1px solid #4f5c77",
  },
  inputDivTopBorder: {
    ...classInputDiv,
    borderTop: "1px solid #4f5c77",
  },
  inputDiv: {
    ...classInputDiv,
  },
  inputDivPlus: {
    ...classInputDiv,
    width: "45%",
    display: "flex",
    flexDirection: "column",
    margin: "auto",
  },
  label: {
    float: "right",
    fontWeight: "Bold",
  },
};
