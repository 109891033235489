import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { Typography } from "@mui/material";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 8,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#d2d2d2",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#b1008b",
  },
}));

export const PercentageLinearProgress = (props) => {
  const { value = 0, title = "" } = props;
  const percentage = Math.round(value * 100);

  return (
    <Box
      display="flex"
      flexDirection="column"
      padding="0.5rem"
      minWidth="160px"
    >
      <Box display="flex" flexDirection="column" padding="3px">
        <Typography
          color="primary.gradientDark"
          component="span"
          fontSize="0.9rem"
        >
          {title}
        </Typography>

        <Typography
          color="primary.gradientDark"
          component="span"
          fontSize="1.25rem"
          fontWeight={600}
        >
          {percentage}%
        </Typography>
      </Box>

      <BorderLinearProgress variant="determinate" value={percentage} />
    </Box>
  );
};
