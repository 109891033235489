import { saveAs } from "file-saver";

import { buildXLSX } from "./";
import { DateTime } from "luxon";
const measurementsHeaders = {
  custom_id: "Plant ID",
  timestamp: "Measurement date",
  planting_date: "Planting Date",
  development_state: "Phenotype",
  salubridad: "Salubrity",
  tags: "Tags",
  description: "Description",
};

export const downloadMeasurements = async (measurements) => {
  const measurementsWithFixedDate = measurements.map((elem) => {
    const timestamp = DateTime.fromMillis(elem.timestamp).toFormat(
      "dd/MM/yyyy"
    );
    if (!elem.planting_date) {
      return {
        ...elem,
        timestamp,
      };
    }
    return {
      ...elem,
      timestamp,
      planting_date: new Date(
        Date.parse(elem.planting_date)
      ).toLocaleDateString("es-AR", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      }),
    };
  });
  const xlsx = await buildXLSX(measurementsWithFixedDate, measurementsHeaders);
  saveAs(xlsx, "Measurements.xlsx");
};
