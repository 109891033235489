import create from "zustand";
import { devtools } from "zustand/middleware";

export const useFilterStore = create(
  devtools(
    (set) => ({
      dropdownPlantFilter: "",
      lotFilter: "",
      validationFilter: undefined,
      setValidationFilter: (validationFilter) =>
        set((state) => ({ validationFilter })),
      resetValidationFilter: () =>
        set((state) => ({ validationFilter: undefined })),
      setDropdownPlantFilter: (dropdownPlantFilter) =>
        set((state) => ({ dropdownPlantFilter })),
      resetDropdownPlantFilter: () =>
        set((state) => ({ dropdownPlantFilter: "" })),
      setDropdownLotFilter: (lotFilter) => set((state) => ({ lotFilter })),
      resetDropdownLotFilter: () => set((state) => ({ lotFilter: "" })),
    }),

    { name: "FilterStore " }
  )
);
