import React, { useState } from "react";
import { NoSelectedElemetsModal } from "components/modals/NoSelectedElemetsModal";

import DownloadIcon from "@mui/icons-material/Download";
import { downloadImages } from "../../../services";

import { useMeasurementsStore } from "stores";
import { useAxios } from "api/Axios";
import { useTranslation } from "react-i18next";

export const DownloadImagesButton = () => {
  const { t } = useTranslation();
  const axios = useAxios();
  const measurements = useMeasurementsStore(
    (store) => store.checkedMeasurements
  );

  const [noMeasurementOpen, setNoMeasurementOpen] = useState(false);
  const onClick = async () => {
    if (measurements.length > 0) {
      downloadImages(
        axios,
        measurements.map((elem) => elem.id)
      );
    } else setNoMeasurementOpen(true);
  };

  const handleNoMeasurementClose = () => {
    setNoMeasurementOpen(false);
  };
  return (
    <>
      <div onClick={onClick} className="cancelButton, measurementsButtons">
        <DownloadIcon></DownloadIcon>
        <p className="pl-08r">{t("measurements_DownloadImagesButton_download")}</p>
      </div>
      <NoSelectedElemetsModal
        open={noMeasurementOpen}
        handleClose={handleNoMeasurementClose}
      />
    </>
  );
};
