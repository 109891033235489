import { useState } from "react";
import { Box, styled, alpha, Divider } from "@mui/material";
import { GridOverlay, DataGrid, esES } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { getKeyByValue } from "../../utils/traslate";
import { DateTime } from "luxon";
import BeenhereIcon from "@mui/icons-material/Beenhere";
import MarkAsUnreadIcon from "@mui/icons-material/MarkAsUnread";

import { DropPlantID, PageContainer, Section } from "components";
import Isotipo from "../../assets/images/isotipo/PNG/modal.png";
import { MeasurementDetails } from "components/modals/MeasurementDetails";
import { ToogleNotificationButton } from "./components/MarkAsReadButton";
import { ActionButtons } from "./components/ActionButtons";

const getUIDate = (params) => {
  return DateTime.fromMillis(params.value).toFormat("yyyy-MM-dd");
};

const StyledDataGrid = styled(DataGrid)(({ theme }) => {
  return {
    ".MuiDataGrid-columnHeaders": {
      color: "white",
      backgroundColor: theme.palette.dataGrid.header,
    },
    ".MuiDataGrid-sortIcon": {
      color: "white",
    },
    ".MuiDataGrid-row": {
      cursor: "pointer",
    },
    ".MuiDataGrid-row.read-false": {
      backgroundColor: alpha(theme.palette.secondary.main, 0.3),
      "&:hover": {
        backgroundColor: alpha(theme.palette.secondary.main, 0.4),
      },
      "&.Mui-selected": {
        backgroundColor: alpha(theme.palette.secondary.main, 0.5),
        "&:hover": {
          backgroundColor: alpha(theme.palette.secondary.main, 0.6),
        },
      },
    },
  };
});

const StyledButtonsBox = styled(Box)(({ theme }) => {
  return {
    ".buttons": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      minWidth: "fit-content",
    },

    ".plantButtons": {
      backgroundColor: "#a4a4c2",
      marginLeft: "5px",
      width: "fit-content",
      height: "5.5vh",
      padding: "0 .5em",
      color: "white",
      borderRadius: "5px",
      cursor: "pointer",
      textAlign: "center",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "1em",
    },
  };
});

export const NotificationsView = (props) => {
  const { t, i18n } = useTranslation();
  const {
    filteredNotifications,
    currentPage,
    setCurrentPage,
    totalNotifications,
    selectedNotifications,
    setSelectionModel,
    markNotificationsAsRead,
    setSorting,
  } = props;
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [selectedMeasurement, setSelectedMeasurement] = useState();
  const notificationsTranslated = filteredNotifications.map((notif) => {
    const name = getKeyByValue(notif.name);
    return { ...notif, name: t(name) };
  });

  function CustomNoRowsOverlay() {
    return (
      <GridOverlay>
        <div className="no-records">
          <Box>
            <img src={Isotipo} alt="" />
          </Box>
          <Box sx={{ mt: 1 }}>{t("notifications_table_noNotifications")}</Box>
        </div>
      </GridOverlay>
    );
  }

  const localeEs = esES.components.MuiDataGrid.defaultProps.localeText;
  const columns = [
    {
      field: "custom_id",
      headerName: t("common_header_plantid"),
      flex: 1,
      headerClassName: "my_header",
    },
    {
      field: "timestamp",
      headerName: t("notifications_header_measurementDate"),
      flex: 1,
      valueGetter: getUIDate,
    },
    {
      field: "name",
      headerName: t("notifications_header_reason"),
      flex: 1,
      headerClassName: "my_header",
    },
    {
      field: "actions",
      headerName: t("common_action"),
      flex: 1.3,
      sortable: false,
      renderCell: (cellValues) => {
        return (
          <ActionButtons
            cellValues={cellValues}
            setSelectedMeasurement={setSelectedMeasurement}
            setOpenDetailsModal={setOpenDetailsModal}
            markNotificationsAsRead={markNotificationsAsRead}
          />
        );
      },
      disableClickEventBubbling: true,
    },
  ];

  return (
    <PageContainer>
      <Box width="100%" maxHeight="100%" padding="1rem">
        <Box display="flex" justifyContent="space-evenly ">
          <DropPlantID />
        </Box>
        <Box marginY="1rem">
          <Divider variant="middle" />
        </Box>
        <Section title={t("notifications_title")}>
          <StyledButtonsBox className="btns-table">
            <div className="buttons">
              <ToogleNotificationButton
                notifications={selectedNotifications}
                markNotificationsAsRead={markNotificationsAsRead}
                read={true}
                title={t("notifications_view_read")}
                Icon={<BeenhereIcon />}
              />
              <ToogleNotificationButton
                notifications={selectedNotifications}
                markNotificationsAsRead={markNotificationsAsRead}
                read={false}
                title={t("notifications_view_unread")}
                Icon={<MarkAsUnreadIcon />}
              />
            </div>
          </StyledButtonsBox>

          <Box height="520px">
            <StyledDataGrid
              components={{
                NoRowsOverlay: CustomNoRowsOverlay,
              }}
              rowHeight={40}
              rows={notificationsTranslated}
              rowCount={parseInt(totalNotifications)}
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[10]}
              disableSelectionOnClick
              disableColumnMenu
              pagination
              paginationMode="server"
              sortingMode="server"
              checkboxSelection={true}
              page={currentPage}
              onSortModelChange={(sortedV) => {
                setSorting(sortedV);
              }}
              localeText={i18n.language === "es" ? localeEs : null}
              onPageChange={(page) => setCurrentPage(page)}
              onSelectionModelChange={(newSelectionModel) => {
                setSelectionModel(newSelectionModel);
              }}
              getRowClassName={(params) => `read-${params.row.read}`}
            />
          </Box>
          {openDetailsModal && (
            <MeasurementDetails
              isOpen={openDetailsModal}
              id={selectedMeasurement.measurement_id}
              handleClose={() => setOpenDetailsModal(false)}
            />
          )}
        </Section>
      </Box>
    </PageContainer>
  );
};
