import { useAxios } from "api/Axios";
import { useQuery } from "react-query";
import { usePlantsStore } from "stores";

export const usePlantFiltersQuery = () => {
  const axios = useAxios();

  const setFiltersData = usePlantsStore((store) => store.setFiltersData);
  const query = useQuery(
    "/app/v1/plants/lotsAndIds",
    () => axios.get("/app/v1/plants/lotsAndIds").then((res) => res.data),
    {
      enabled: false,
      onSuccess: (data) => {
        setFiltersData(data);
      },
      onError: () => {},
    }
  );
  return query;
};
