import { useState } from "react";
import IconButton from "@mui/material/IconButton";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";

import { ShowPlantModal } from "components/modals/ShowPlantModal";
import { EditPlantModal } from "components/modals/EditPlantModal";
import { DeletePlantModal } from "components/modals/DeletePlantModal";

export const RowButtons = (props) => {
  const [showPlantsOpen, setShowPlantsOpen] = useState(false);
  const [editPlantOpen, setEditPlantOpen] = useState(false);
  const [deletePlantOpen, setDeletePlantOpen] = useState(false);

  const plant = props.cellValues.row;

  const handleCloseModals = () => {
    setShowPlantsOpen(false);
    setEditPlantOpen(false);
    setDeletePlantOpen(false);
  }

  const handleOpenModal = (funct) => {
    funct(prevState => {
      return { prevState: !prevState };
    });
  };

  return (
    <div>
      <IconButton
        color="primary"
        component="span"
        onClick={() => {
          handleOpenModal(setShowPlantsOpen);
        }}
      >
        <ZoomInIcon />
      </IconButton>

      <IconButton
        color="primary"
        aria-label="upload picture"
        component="span"
        onClick={() => {
          handleOpenModal(setEditPlantOpen);
        }}
      >
        <EditIcon />
      </IconButton>

      <IconButton
        color="primary"
        aria-label="upload picture"
        component="span"
        onClick={() => {
          handleOpenModal(setDeletePlantOpen);
        }}
      >
        <DeleteForeverIcon />
      </IconButton>
      <ShowPlantModal
        open={showPlantsOpen}
        handleClose={handleCloseModals}
        plant={plant}
      />
      <EditPlantModal
        open={editPlantOpen}
        handleClose={handleCloseModals}
        plant={plant}
      />
      <DeletePlantModal
        open={deletePlantOpen}
        handleClose={handleCloseModals}
        plant={plant}
      />
    </div>
  );
};
