import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { PercentageLinearProgress } from "./PercentageLinearProgress";

const Total = ({ total, totalLabel }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyItems="center"
      alignItems="center"
    >
      <Typography
        color="primary.gradientDark"
        component="span"
        variant="h1"
        fontWeight={600}
        fontSize="2.4rem"
        textAlign={"center"}
      >
        {total}
      </Typography>
      {totalLabel && (
        <Typography
          color="primary.gradientDark"
          component="span"
          variant="body2"
          fontSize="0.9rem"
        >
          {totalLabel}
        </Typography>
      )}
    </Box>
  );
};

export const TagsWidget = (props) => {
  const {
    title = "",
    total = 0,
    totalLabel = "",
    tags = [],
    horizontal = false,
  } = props;
  
  const [tag1, tag2, tag3, tag4] = tags;

  let showTag1 = tag1 || !tags.length;
  let showTag2 = tag2 || !tags.length;
  let showTag3 = tag3 || !tags.length;
  let showTag4 = tag4 || !tags.length;

  return (
    <Box display="flex" flexDirection="column" gap="1rem">
      {title && (
        <Typography
          color="primary.gradientDark"
          component="span"
          variant="body"
        >
          {title}
        </Typography>
      )}

      <Box
        display="flex"
        {...(horizontal
          ? {
              flexDirection: "row",
            }
          : {
              flexDirection: "column",
              maxWidth: 350,
            })}
        alignItems="center"
        gap="1rem"
        paddingX="1rem"
      >
        <Total total={total} totalLabel={totalLabel} />
        <Box display="flex" flexDirection="column" minHeight={165}>
          <Box display="flex" flexDirection="row" gap="1rem">
            {showTag1 && (
              <PercentageLinearProgress
                title={tag1?.name || ""}
                value={tag1?.value || 0}
              />
            )}
            {showTag2 && (
              <PercentageLinearProgress
                title={tag2?.name || ""}
                value={tag2?.value || 0}
              />
            )}
          </Box>
          <Box display="flex" flexDirection="row" gap="1rem">
            {showTag3 && (
              <PercentageLinearProgress
                title={tag3?.name|| ""}
                value={tag3?.value || 0}
              />
            )}
            {showTag4 && (
              <PercentageLinearProgress
                title={tag4?.name || ""}
                value={tag4?.value || 0}
              />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
