import { styled, Box, Dialog } from "@mui/material";
import { useTranslation } from "react-i18next";

import { useDeletePlantMutation } from "api/mutations";
import Isotipo from "../../assets/images/isotipo/PNG/modal.png";
import { classes } from "../../utils/customClasses";

const customStyles = {
  formBox: {
    ...classes.topBox,
    marginTop: "5%",
  },
  formMainBox: {
    ...classes.formMainBox,
  },
  img: {
    width: "calc(100vw / 6.2)",
    height: "calc((100vw / 6.2) * 0.676)",
    marginTop: "calc(100vh/40)",
  },
  h3Alt: {
    ...classes.h3Alt,
  },
  buttonContainer: {
    ...classes.formMainBox,
    ...classes.btnContainerCommonStyles,
  },
  cancelar: {
    ...classes.cancelar,
    height: "100%",
  },
  okButtonAlt: {
    ...classes.okButtonAlt,
  },
};

const StyledDialog = styled(Dialog)(({ theme }) => {
  return {
    ".MuiDialog-paper": {
      display: "flex",
      justifyContent: "center",
      maxWidth: "500px",
      width: "500px",
      textAlign: "center",
      alignItems: "center",
    },
    ".MuiInputBase-root": {
      maxHeight: "32px",
      minHeight: "32px",
      color: "rgb(106, 124, 161)",
    },
    ".MuiOutlinedInput-notchedOutline": {
      border: "1px solid #cecee4",
    },
  };
});

export const DeletePlantModal = (props) => {
  const { open = false, handleClose = () => {}, plant } = props;
  const { t } = useTranslation();

  const { mutate: deletePlant } = useDeletePlantMutation();
  const erasePlant = async () => {
    const data = {
      id: plant.id,
    };
    try {
      await deletePlant(data);

      handleClose();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div>
      <StyledDialog
        open={open}
        onClose={handleClose}
        style={customStyles.modal}
      >
        <Box style={customStyles.formMainBox}>
          <div>
            <img src={Isotipo} style={customStyles.img} alt="" />
          </div>

          <Box style={customStyles.formBox}>
            <h3 style={customStyles.h3Alt}>
              {t("plants_DeletePlantModal_message")}
              {plant.custom_id}?
            </h3>
          </Box>
        </Box>
        <div style={customStyles.buttonContainer}>
          <div
            style={customStyles.cancelar}
            onClick={() => {
              handleClose();
            }}
          >
            <p>{t("common_cancel")}</p>
          </div>
          <div style={customStyles.okButtonAlt} onClick={erasePlant}>
            <p>{t("common_confirm")}</p>
          </div>
        </div>
      </StyledDialog>
    </div>
  );
};
