import { useEffect } from "react";

import { Alert } from "@mui/material";
import { useMessagesStore } from "stores";
import { Box } from "@mui/system";

export const CustomAlert = ({ message }) => {
  const deleteMessage = useMessagesStore((store) => store.deleteMessage);

  useEffect(() => {
    setTimeout(function () {
      deleteMessage(message.id);
    }, message.timeout);
  }, [message, deleteMessage]);

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    deleteMessage(message.id);
  };

  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <Alert
        onClose={handleAlertClose}
        elevation={6}
        variant="filled"
        severity={message.severity}
        sx={{ minWidth: "24rem", maxWidth: "50%" }}
      >
        {message.message}
      </Alert>
    </Box>
  );
};
