import { useAxios } from "api/Axios";
import { useMutation, useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import { useMessagesStore } from "stores";

export const useUpdateProfileMutation = () => {
  const { t } = useTranslation();
  const axios = useAxios();

  const queryClient = useQueryClient();

  const addMessage = useMessagesStore((store) => store.addMessage);

  const mutation = useMutation(
    (data) => {
      const options = {
        method: "PUT",
        data,
        url: "/app/v1/users/me",
      };

      return axios(options).then((res) => res.data);
    },
    {
      onSuccess: (data, variables) => {
        const updated = [];
        if (Object.keys(variables).includes("email")) updated.push("email");
        if (Object.keys(variables).includes("password"))
          updated.push(t("common_profile_passwordLabel"));
        if (Object.keys(variables).includes("avatar"))
          updated.push(t("common_profile_profilePicture"));

        addMessage(
          `${t("mutations_profile_success")} ${updated.join(", ")}`,
          "success"
        );

        queryClient.fetchQuery({ queryKey: "/app/v1/users/me" });
      },
    }
  );

  return mutation;
};
