import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { MeasurementsView } from "./MeasurementsView";
import {
  useFilterStore,
  useMeasurementsStore,
  useStatisticsStore,
} from "stores";
import { useSortingStore } from "stores/useSortingStore";
import {
  useFilteredMeasurementsQuery,
  useTagsInternationalizationQuery,
} from "api/queries";
import {
  useUpdatePlantMeasurement,
  useBulkUpdateMeasurementTag,
} from "api/mutations";
import { useAllTagsQuery } from "api/queries/useAllTagsQuery";
import { useCoincidenciesTagPrediction } from "api/queries/useCoincidenciesTagPrediction";
import { useDiscrepanciesTagsPredicions } from "api/queries/useDiscrepanciesTagsPredicions";

export const MeasurementsContainer = () => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const {
    filteredMeasurements,
    totalMeasurements,
    setCheckedMeasurements,
    currentPage,
    setCurrentPage,
    timeInterval,
    setTimeInterval,
    resetTimeInterval,
  } = useMeasurementsStore();

  const { sortingValue, setSortingValue } = useSortingStore();

  const [dateRangeValue, setDateRangeValue] = useState([]);
  const [sortedValue, setSorting] = useState([]);
  const { mutate: updateMeasurement } = useUpdatePlantMeasurement();
  const { mutate: updateMeasurementTags } = useBulkUpdateMeasurementTag();
  const { refetch: refetchAllTags } = useAllTagsQuery(currentLanguage);
  const { dropdownPlantFilter, validationFilter } = useFilterStore();

  const { refetch: refetchCoinc } = useCoincidenciesTagPrediction();
  const coincidenciesTagPrediction = useStatisticsStore(
    (store) => store.coincidenciesTagPrediction
  );
  const { refetch: refetchDiscr } = useDiscrepanciesTagsPredicions();
  const discrepanciesTagsPredicions = useStatisticsStore(
    (store) => store.discrepanciesTagsPredicions
  );

  useEffect(() => {
    refetchDiscr();
    refetchCoinc();
  }, [refetchDiscr, refetchCoinc]);

  /* console.log('coincidenciesTagPrediction: ', coincidenciesTagPrediction)
  console.log('discrepanciesTagsPredicions: ', discrepanciesTagsPredicions) */

  const {
    refetch: refetchTagsDict,
    data: tagsDictionary,
    isLoading: loadingDictionary,
  } = useTagsInternationalizationQuery();

  const { refetch: refetchMeasurements, isLoading: loadingMeasurements } =
    useFilteredMeasurementsQuery(timeInterval);

  const changePage = (page) => {
    setCurrentPage(page);
    setCheckedMeasurements([]);
  };

  useEffect(() => {
    setSortingValue(sortedValue, "measurements");
  }, [sortedValue, setSortingValue]);

  useEffect(() => {
    const [start, end] = dateRangeValue;
    if (start && end && start <= end) {
      setTimeInterval(
        start.setHours(0, 0, 0, 0),
        end.setHours(23, 59, 59, 999)
      );
    } else if (!start && !end) {
      resetTimeInterval();
    }
    return () => {
      resetTimeInterval();
    };
  }, [setTimeInterval, dateRangeValue, resetTimeInterval]);

  useEffect(() => {
    refetchTagsDict();
    refetchAllTags();
  }, [refetchTagsDict, refetchAllTags, currentLanguage]);

  useEffect(() => {
    if (tagsDictionary && filteredMeasurements) {
      filteredMeasurements.forEach((measurement) => {
        const { data: dictionary } = tagsDictionary;
        measurement.tags = measurement.tag_measurements
          .map((tag) => {
            return dictionary[tag.name]
              ? dictionary[tag.name][currentLanguage]
              : tag.name;
          })
          .join(", ");
        measurement.custom_id = measurement.plant.custom_id;
      });
    }
  }, [filteredMeasurements, currentLanguage, tagsDictionary]);

  useEffect(() => {
    if (tagsDictionary) refetchMeasurements();
  }, [
    currentPage,
    dropdownPlantFilter,
    validationFilter,
    refetchMeasurements,
    tagsDictionary,
    timeInterval,
    sortingValue,
  ]);

  const editMeasurement = async (values) => {
    const { editedTags, description, id } = values;
    const measurement = filteredMeasurements.find(
      (measurement) => measurement.id === id
    );

    if (measurement.description !== description) {
      await updateMeasurement({
        id,
        description: description.trim(),
        validated: false,
      });
    }

    const { tag_measurements } = measurement;
    const previousTags = JSON.stringify(
      tag_measurements.map((tag) => tag.name).sort()
    );
    const tagsToUpdate = JSON.stringify(editedTags.sort());
    if (previousTags !== tagsToUpdate) {
      await updateMeasurementTags({ id, tags: editedTags });
    }
    if (tagsDictionary) refetchMeasurements();

  };

  const validateMeasurement = async (
    values,
    measurId,
    description,
    diseaseBoolean
  ) => {
    console.log("values: ", values);
    const tagsObject = { ...values };
    const tags = [];
    for (const key in tagsObject) {
      if (tagsObject[key] && key !== "false") {
        tags.push(key);
      }
    }
    console.log("tags: ", tags);
    console.log('boolean: ', diseaseBoolean)
    let tagsFiltered = []
    if (diseaseBoolean) {
      tagsFiltered = tags.filter((t) =>  t !== "min_damage" && t !== "full_health"); //quitar los tags de healthy y dejar el resto
    } else {
      tagsFiltered = tags.filter((t) => t === "min_damage" || t === "healthy" || t === "full_health" || t === "leaf" || t === "flower"); //quitar los tags de disease y dejar el resto
    }
    console.log('tags filtrados: ', tagsFiltered)
    await updateMeasurementTags({
      id: measurId,
      tags: diseaseBoolean === undefined ? tags : tagsFiltered,
    });
    await updateMeasurement({
      id: measurId,
      description: description.trim(),
      validated: true,
    });
  };

  return (
    <MeasurementsView
      dateRangeValue={dateRangeValue}
      setDateRangeValue={setDateRangeValue}
      filteredMeasurements={filteredMeasurements}
      totalMeasurements={totalMeasurements}
      loading={loadingMeasurements || loadingDictionary}
      currentPage={currentPage}
      changePage={changePage}
      setCheckedMeasurements={setCheckedMeasurements}
      tagsDictionary={tagsDictionary?.data}
      editMeasurement={editMeasurement}
      validateMeasurement={validateMeasurement}
      setSorting={setSorting}
    />
  );
};
