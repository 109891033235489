import React, { useState } from "react";
import { styled, Dialog, Box, Select, FormControl, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";

import { DayPicker } from "./DayPicker";
import { stageValues, sexValues } from "../../constants";
import { useCreatePlantMutation } from "api/mutations";
import { classes } from '../../utils/customClasses';

const StyledDialog = styled(Dialog)(({ theme }) => {
  return {
    ".MuiDialog-paper": {
      maxWidth: "60rem",
      width: "60rem",
      display: "flex",
      justifyContent: "center",
    },
    ".MuiInputBase-root": {
      maxHeight: "32px",
      minHeight: "32px",
      color: "rgb(106, 124, 161)",
    },
    ".MuiOutlinedInput-notchedOutline": {
      border: "1px solid #cecee4",
    },
  };
});

const dicMonth = {
  0: "01",
  1: "02",
  2: "03",
  3: "04",
  4: "05",
  5: "06",
  6: "07",
  7: "08",
  8: "09",
  9: "10",
  10: "11",
  11: "12",
};

const getUIDate = (date) => {
  let year = new Date(date).getFullYear();
  let month = dicMonth[new Date(date).getMonth()];
  let day = new Date(date).getDate();
  let _date = year + "-" + month + "-" + day;
  return _date;
};
export const AddPlantModal = (props) => {
  const { t } = useTranslation();
  const { open = false, handleClose = () => {} } = props;

  const [id, setId] = useState(""),
    [planting, setPlanting] = useState(""),
    [lot, setLot] = useState(""),
    [facility, setFacility] = useState(""),
    [genetic, setGenetic] = useState(""),
    [zone, setZone] = useState(""),
    [table, setTable] = useState(""),
    [plate, setPlate] = useState(""),
    [stage, setStage] = useState(null),
    [sex, setSex] = useState(null),
    [aux_description, setDescription] = useState(""),
    [treatment, setTreatment] = useState("");

  const { mutate: createPlant } = useCreatePlantMutation();
  const savePlant = async () => {
    const data = {
      custom_id: id,
      planting_date: planting === "" ? "2000-01-01" : getUIDate(planting),
      lot: lot !== "" ? lot : null,
      facility,
      genetic,
      zone,
      table,
      plate,
      stage,
      sex: sex === "-" ? null : sex,
      description: aux_description,
      treatment,
    };
    try {
      await createPlant(data);
      handleClose();
    } catch (e) {
      console.error(e);
    }
  };


  return (
    <div>
      <StyledDialog open={open} onClose={handleClose}>
        <Box style={customStyles.formMainBox}>
          <h2 style={customStyles.h2}>{t("plants_AddPlantModal_title")}</h2>
          <Box style={customStyles.formBox}>
            <div style={customStyles.inputContainer}>
              <div style={customStyles.inputDivLeft}>
                <label style={customStyles.label}>{t("common_header_plantid")}</label>
                <input
                  style={customStyles.input}
                  type="text"
                  value={id}
                  onChange={(e) => {
                    setId(e.target.value);
                  }}
                />
              </div>
              <div style={customStyles.inputDivRight}>
                <label style={customStyles.label}>{t("common_header_planting_date")}</label>
                <DayPicker
                  defaultValue={"2000-01-01"}
                  setPlanting={setPlanting}
                />
              </div>
              <div style={customStyles.inputDivLeft}>
                <label style={customStyles.label}>{t("common_header_lot")}</label>
                <input
                  style={customStyles.input}
                  type="text"
                  value={lot}
                  onChange={(e) => {
                    setLot(e.target.value);
                  }}
                />
              </div>
              <div style={customStyles.inputDivRight}>
                <label style={customStyles.label}>{t("common_header_facility")}</label>
                <input
                  style={customStyles.input}
                  type="text"
                  value={facility}
                  onChange={(e) => {
                    setFacility(e.target.value);
                  }}
                />
              </div>
              <div style={customStyles.inputDivLeft}>
                <label style={customStyles.label}>{t("common_header_genetic")}</label>
                <input
                  style={customStyles.input}
                  type="text"
                  value={genetic}
                  onChange={(e) => {
                    setGenetic(e.target.value);
                  }}
                />
              </div>
              <div style={customStyles.inputDivRight}>
                <label style={customStyles.label}>{t("common_header_zone")}</label>
                <input
                  style={customStyles.input}
                  type="text"
                  value={zone}
                  onChange={(e) => {
                    setZone(e.target.value);
                  }}
                />
              </div>
              <div style={customStyles.inputDivLeft}>
                <label style={customStyles.label}>{t("common_header_table")}</label>
                <input
                  style={customStyles.input}
                  type="text"
                  value={table}
                  onChange={(e) => {
                    setTable(e.target.value);
                  }}
                />
              </div>
              <div style={customStyles.inputDivRight}>
                <label style={customStyles.label}>{t("common_header_plate")}</label>
                <input
                  style={customStyles.input}
                  type="text"
                  value={plate}
                  onChange={(e) => {
                    setPlate(e.target.value);
                  }}
                />
              </div>
              <div style={customStyles.inputDivLeft}>
                <label style={customStyles.label}>{t("common_header_stage")}</label>
                <FormControl fullWidth>
                  <Select
                    value={stage ? stage : "-"}
                    onChange={(e) => {
                      setStage(e.target.value);
                    }}
                  >
                    {stageValues.map((item, key) => {
                      return (
                        <MenuItem key={key} value={item.value}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
              <div style={customStyles.inputDivRight}>
                <label style={customStyles.label}>{t("common_header_sex")}</label>
                <FormControl fullWidth>
                  <Select
                    value={sex ? sex : ""}
                    onChange={(e) => {
                      setSex(e.target.value);
                    }}
                  >
                    {sexValues.map((item, key) => {
                      return (
                        <MenuItem key={key} value={item.value}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
              <div style={customStyles.inputDivLeft}>
                <label style={customStyles.label}>{t("common_header_treatment")}</label>
                <input
                  style={customStyles.input}
                  type="text"
                  value={treatment}
                  onChange={(e) => {
                    setTreatment(e.target.value);
                  }}
                />
              </div>
              <div style={customStyles.inputDivRight}>
                <label style={customStyles.label}>{t("plants_PlantsTable_description")}</label>
                <input
                  style={customStyles.input}
                  type="text"
                  value={aux_description}
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                />
              </div>
            </div>
          </Box>
        </Box>

        <div style={customStyles.buttonContainer}>
          <div
            style={customStyles.cancelar}
            onClick={() => {
              handleClose();
            }}
          >
            <p>{t("common_cancel")}</p>
          </div>
          <div style={customStyles.okButton} onClick={savePlant}>
            <p>{t("common_confirm")}</p>
          </div>
        </div>
      </StyledDialog>
    </div>
  );
};

const customStyles = {
  formBox: {
    ...classes.topBox,
    marginTop: "5%",
  },
  formMainBox: {
    ...classes.formMainBox,
  },
  h2: {
    color: "#6a7ca1",
    textAlign: "center",
  },
  inputContainer: {
    ...classes.topBoxRow,
  },
  inputDivLeft: {
    ...classes.inputDivPlus,
  },
  inputDivRight: {
    ...classes.inputDivPlus,
  },
  label: {
    fontWeight: "Bold",
  },

  buttonContainer: {
    ...classes.buttonContainerPlantModal,
  },
  okButton: {
    ...classes.okButton,
  },
  input: {
    ...classes.input
  },
  cancelar: {
    ...classes.cancelar,
  },
};
