import { useAxios } from "api/Axios";
import { useQuery } from "react-query";
import { useFilterStore, useRecordsStore } from "stores";
import { useSortingParamsHelper } from "../../helpers/sortingparams";

export const useFilteredRecordsQuery = (
  params = {},
) => {
  const axios = useAxios();
  const setFilteredRecords = useRecordsStore(
    (store) => store.setFilteredRecords
  );

  const filterId = useFilterStore((store) => store.dropdownPlantFilter);
  const currentPage = useRecordsStore((store) => store.currentPage);
  const setTotalRecords = useRecordsStore((store) => store.setTotalRecords);
  let skip = currentPage * 10;

  const start = params?.start || 0;
  const end = params?.end || new Date().getTime();
  
  let filter = {
    timestamp: {
      ">": start,
      "<": end,
    },
  };
  if (filterId) {
    filter.custom_id = filterId;
  }
  
  const { finalSort, finalOrder } = useSortingParamsHelper(
    "records",
    "timestamp"
  );
  const query = useQuery(
    "filteredRecords",
    () =>
      axios
        .get(`/app/v1/plant_records`, {
          params: {
            skip,
            limit: "10",
            sort: finalSort,
            order: finalOrder,
            filter: JSON.stringify(filter),
          },
        })
        .then((res) => res),
    {
      enabled: false,
      onSuccess: (res) => {
        setFilteredRecords(res.data);
        setTotalRecords(res.headers["content-range"]);
      },
      onError: (res) => {
        setTotalRecords(0);
        setFilteredRecords([]);
      },
    }
  );
  return query;
};
