import { useAxios } from "api/Axios";
import { useQuery } from "react-query";

import { useFilterStore, useNotificationsStore } from "stores";
import { useSortingParamsHelper } from "../../helpers/sortingparams";

export const useFilteredNotificationsQuery = (

) => {
  const axios = useAxios();
  const { setFilteredNotifications, setTotalNotifications, currentPage } = useNotificationsStore();
  const { dropdownPlantFilter: filterId } = useFilterStore();

  let start = currentPage * 10;
  let filter = {};
  if (filterId) {
    filter = {
      custom_id: filterId,
    };
  }

  const { finalSort, finalOrder } = useSortingParamsHelper(
    "notifications",
    "id"
  );
  const query = useQuery(
    "/app/v1/notifications/",
    () =>
      axios
        .get(`/app/v1/notifications/`, {
          params: {
            skip: start,
            limit: "10",
            sort: finalSort,
            order: finalOrder,
            filter: JSON.stringify(filter),
          },
        })
        .then((res) => res),
    {
      enabled: false,
      onSuccess: (res) => {
        setFilteredNotifications(res.data);
        setTotalNotifications(res.headers["content-range"]);
      },
    }
  );
  return query;
};
