import { useAxios } from "api/Axios";
import { useQuery } from "react-query";
import { useMeasurementsStore, useTagsStore } from "stores";

export const useMeasurementsWithImagesQuery = (checkedMeasurements) => {
  const axios = useAxios();

  const setMeasurementsWithImages = useMeasurementsStore(
    (store) => store.setMeasurementsWithImages
  );

  const tagsInternationalizationDict = useTagsStore(
    (store) => store.tagsInternationalizationDict
  );

  const userLang = navigator.language.includes("en") ? "en" : "es";

  const query = useQuery(
    "filteredMeasurementsWithImages",
    () => {
      const idList = checkedMeasurements.map((elem) => elem.id);
      return axios
        .get(
          `/app/v1/plant_measurements/with_images?measurements_ids=${idList}`
        )
        .then((res) => {
          return res;
        });
    },
    {
      enabled: false,
      onSuccess: (res) => {
        res.data.forEach((measurement) => {
          measurement.tags = measurement.tag_measurements
            .map((tag) => {
              return tagsInternationalizationDict[tag.name] ?
                tagsInternationalizationDict[tag.name][userLang]
              : tag.name;
            })
            .join(", ");
          measurement.custom_id = measurement.plant.custom_id;
        });
        setMeasurementsWithImages(res.data);
      },
    }
  );

  return query;
};
