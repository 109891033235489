import { Section } from "components";
import { useTranslation } from "react-i18next";
import { PlantsTable } from "./PlantsTable";

export const PlantsTableSection = (props) => {
  const { t } = useTranslation();
  const {
    filteredPlants,
    currentPage,
    setCurrentPage,
    totalPlants,
    setSelectionModel,
    setSorting,
  } = props;
  return (
    <Section title={t("plants_PlantsTableSection_title")}>
      <PlantsTable
        filteredPlants={filteredPlants}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPlants={totalPlants}
        setSelectionModel={setSelectionModel}
        setSorting={setSorting}
      />
    </Section>
  );
};
