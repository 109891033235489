import { Box, CssBaseline, Stack } from "@mui/material";
import { CustomAlert } from "components";

import { Router } from "router";
import { useMessagesStore } from "stores";

const App = () => {
  const messages = useMessagesStore((store) => store.messages);
  return (
    <Box>
      <CssBaseline />
      <Box>
        <Router />
      </Box>
      <Stack
        sx={{
          position: "absolute",
          bottom: 24,
          left: 0,
          right: 0,
          margin: "auto",
        }}
        spacing={2}
      >
        {messages.map((message) => (
          <CustomAlert message={message} key={message.id} />
        ))}
      </Stack>
    </Box>
  );
};

export default App;
