import { useAxios } from "api/Axios";
import { useQuery } from "react-query";

import { useFilterStore, usePlantsStore } from "stores";
import { useSortingParamsHelper } from "../../helpers/sortingparams";

export const useFilteredPlantsQuery = (
) => {
  const axios = useAxios();
  const { setFilteredPlants, setTotalPlants, currentPage, checkedPlants, setCheckedPlants } = usePlantsStore();
  const { dropdownPlantFilter: filterId, lotFilter: lot } = useFilterStore();
  
  let start = currentPage * 10;

  let filter = {};
  if (filterId) {
    filter = {
      custom_id: filterId,
    };
  }
 
  const { finalSort, finalOrder } = useSortingParamsHelper(
    "plants",
    "planting_date"
  );
  const query = useQuery(
    "filteredPlants",
    () =>
      axios
        .get(`/app/v1/plants/`, {
          params: {
            skip: start,
            limit: "10",
            sort: finalSort,
            order: finalOrder,
            filter: JSON.stringify(filter),
            lot: lot !== "" ? lot : undefined,
          },
        })
        .then((res) => res),
    {
      enabled: false,
      onSuccess: (res) => {
        setFilteredPlants(res.data);
        setTotalPlants(res.headers["content-range"]);
        const idsChecked = checkedPlants.map((plant) => {
          return plant.id
        })
        setCheckedPlants(idsChecked)
      },
    }
  );
  return query;
};
