import { Box } from "@mui/system";
import { IndicatorsCard } from "./IndicatorsCard";

export const AlertsSection = (props) => {
  const { currentUser, initialIndicators,  onIndicatorsSave, isLoadingIndicators } = props;

  return (
    <Box display="flex" width={"70%"} margin="auto">
      <IndicatorsCard
        currentUser={currentUser}
        onSubmitIndicators={onIndicatorsSave}
        initialIndicators={initialIndicators}
        isLoadingIndicators={isLoadingIndicators}
      />
    </Box>
  );
};
