import { useEffect, useState } from "react";
import {
  styled,
  TextField,
  Select,
  MenuItem,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { usePlantsStore, useFilterStore } from "stores";
import { usePlantFiltersQuery } from "api/queries";
import "./components.css";
import ClearIcon from "@mui/icons-material/Clear";
import CloseIcon from "@mui/icons-material/Close";

const StyledTextFieldSelect = styled(TextField)(({ theme }) => {
  return {
    ".MuiSvgIcon-root": {
      /* user-select: "none", */
      width: "1em",
      height: "1em",
      display: "inline-block",
      fill: "currentColor",
      fontSize: "1.25rem",
      padding: "2px",
    },
    ".MuiOutlinedInput-notchedOutline": {
      borderWidth: "0px",
    },
    ".MuiOutlinedInput-root": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    ".MuiSelect-iconOutlined": {
      fontSize: "1.25rem",
    },
    /* ".MuiSelect-icon": {
      margin: "3px"
    } */
    /* ".MuiSvgIcon-root": {
      "&:hover": {
        visibility: "visible",
        cursor: "pointer",
      }
    }, */
  };
});

export const ValidationFilter = () => {
  const { t } = useTranslation();
  const { filtersData } = usePlantsStore();
  const { refetch: refetchFilters } = usePlantFiltersQuery();
  const { validationFilter, setValidationFilter, resetValidationFilter } =
    useFilterStore();
  const [clearIconVisibility, setClearIconVisibility] = useState("visible");

  useEffect(() => {
    refetchFilters();
  }, [refetchFilters, filtersData]);

  useEffect(() => {
    return () => {
      // Cleanup function -> this is called when the UI is destroyed
      resetValidationFilter();
    };
  }, [resetValidationFilter]);

  const roleOnChangeHandler = (validationValue) => {
    setValidationFilter(validationValue);
  };

  const roles = [
    {
      name: "Validated",
      boolean: true,
      id: 1,
    },
    {
      name: "Not validated",
      boolean: false,
      id: 2,
    },
  ];

  return (
    <>
      {filtersData && (
        <StyledTextFieldSelect
          select
          disablePortal
          id="validation-filter"
          label="Validation filter"
          placeholder="Validation filter"
          onChange={(e) => {
            roleOnChangeHandler(e.target.value);
            setClearIconVisibility("visible");
          }}
          value={validationFilter === undefined ? "" : validationFilter}
          defaultValue={validationFilter === undefined ? "" : validationFilter}
          InputProps={{
            endAdornment: (
              <IconButton
                variant="outlined"
                sx={{ visibility: `${clearIconVisibility}` }}
                onClick={() => {
                  resetValidationFilter();
                  setClearIconVisibility("hidden");
                }}
              >
                <ClearIcon />
              </IconButton>
            ),
          }}
          sx={{
            width: 300,
            backgroundColor: "white",
            border: "#c4c4c4",
            borderWidth: "1px",
            borderStyle: "solid",
            borderRadius: "4px",
          }}
          renderInput={(params) => (
            <TextField {...params} label={"Validation filter"} />
          )}
        >
          {roles.map((rol) => {
            return (
              <MenuItem key={rol.id.toString()} value={rol.boolean}>
                {rol ? rol.name : "Sin descripción"}
              </MenuItem>
            );
          })}
        </StyledTextFieldSelect>
      )}
    </>
  );
};
