import { Box, Grid, Paper, Typography } from "@mui/material";

export const Dummy = ({ text, children }) => {
  return (
    <Paper>
      <Box padding="2rem" minWidth={300}>
        <Typography component="h1" variant="h4" align="center">
          {text}
        </Typography>
        {!!children && (
          <Grid container justifyContent="center">
            <Box mt={3}>{children}</Box>
          </Grid>
        )}
      </Box>
    </Paper>
  );
};
