import { styled, Dialog, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import { Measurement } from "../carousel/Measurement";
import { classes } from "../../utils/customClasses";
import {
  useMeasurementImageQuery,
  useTagsInternationalizationQuery,
} from "api/queries";
import { useEffect, useState } from "react";
import { Validation } from "components/carousel/Validation";

const StyledDialog = styled(Dialog)(({ theme }) => {
  return {
    ".MuiDialog-paper": {
      maxWidth: "60rem",
      width: "60rem",
      display: "flex",
      justifyContent: "center",
    },
    ".MuiInputBase-root": {
      maxHeight: "32px",
      minHeight: "32px",
      color: "rgb(106, 124, 161)",
    },
    ".MuiOutlinedInput-notchedOutline": {
      border: "1px solid #cecee4",
    },
  };
});

export const MeasurementDetails = (props) => {
  const {
    isOpen,
    handleClose = () => {},
    onSubmitTags,
    id,
    prediction,
    forValidation,
    selectedMeasurement,
    filteredMeasurements
  } = props;
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [currentMeasurement, setCurrentMeasurement] = useState();
  const { refetch: refetchTagsDict, data: tagsDictionary } =
    useTagsInternationalizationQuery();
  const {
    refetch: refetchMeasurement,
    isLoading,
    data,
  } = useMeasurementImageQuery(id);

  useEffect(() => {
    if (id) refetchMeasurement();
    refetchTagsDict();
  }, [refetchMeasurement, id, refetchTagsDict]);

  useEffect(() => {
    if (data) {
      const { data: measurement } = data;
      const { data: dictionary } = tagsDictionary;
      measurement.tags = measurement.tag_measurements
        .map((tag) => {
          return dictionary[tag.name]
            ? dictionary[tag.name][currentLanguage]
            : tag.name;
        })
        .join(", ");
      measurement.custom_id = measurement.plant.custom_id;
      setCurrentMeasurement({ ...measurement });
    }
  }, [setCurrentMeasurement, data, tagsDictionary, currentLanguage, selectedMeasurement]);

  return (
    <>
      {!isLoading ? (
        <StyledDialog open={isOpen} onClose={handleClose}>
          {!forValidation && currentMeasurement !== undefined ? (
            <Measurement item={currentMeasurement} prediction={prediction} />
          ) : forValidation && currentMeasurement !== undefined ? (
            <Validation item={currentMeasurement} description={selectedMeasurement.description} onSubmitTags={onSubmitTags} handleClose={handleClose} filteredMeasurements={filteredMeasurements} />
          ) : (
            <h3 style={customStyles.h3Alt}>
              {t("notifications_modal_noMeasurementAssociated")}
            </h3>
          )}
          {!forValidation && (<div style={customStyles.buttonContainer}>
            <div
              style={customStyles.okButton}
              onClick={() => {
                handleClose();
              }}
            >
              <p style={customStyles.p}>{t("common_close")}</p>
            </div>
          </div>)}
        </StyledDialog>
      ) : (
        <StyledDialog open={isOpen} onClose={handleClose}>
          <div className="measurement-image-container">
            <Box>
              <h2 className="measurement-loading">
                {t("measurements_ShowMeasurement_loadingOneImg")}
              </h2>
            </Box>
            <Stack
              sx={{ color: "#6f7e90", justifyContent: "center" }}
              spacing={2}
              direction="row"
            >
              <CircularProgress color="inherit" />
            </Stack>
          </div>
          <div style={customStyles.buttonContainer}>
            <div
              style={customStyles.okButton}
              onClick={() => {
                handleClose();
              }}
            >
              <p style={customStyles.p}>{t("common_close")}</p>
            </div>
          </div>
        </StyledDialog>
      )}
    </>
  );
};

const customStyles = {
  formMainBox: {
    ...classes.formMainBox,
  },
  buttonContainer: {
    ...classes.buttonContainerPlantModal,
    justifyContent: "center",
    paddingTop: "3em",
    paddingBottom: "1em",
  },
  cancelar: {
    ...classes.cancelar,
  },
  okButton: {
    ...classes.okButton,
  },
  h3Alt: {
    ...classes.defaultProps,
    ...classes.h3Alt,
  },
};
