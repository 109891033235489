import { useEffect, useState } from "react";
import { styled, Box, CircularProgress, Button } from "@mui/material";
import { useTranslation } from "react-i18next";

import { useProfileQuery } from "api/queries";
import { ImageCropDialog } from "./ImageCropDialog";

export const UpdateAvatarCard = (props) => {
  const { currentUser, croppedImage, setCroppedImage } = props;
  const { t } = useTranslation();

  const { refetch: refetchProfile } = useProfileQuery();

  const [newImage, setNewImage] = useState(undefined);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!currentUser) {
      refetchProfile();
    } else {
      setNewImage(currentUser.avatar);
      setCroppedImage(currentUser.avatar);
    }
  }, [currentUser, refetchProfile, setCroppedImage]);

  const onImageChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setNewImage(reader.result);
    };
    reader.readAsDataURL(file);
    setOpen(true);
  };

  return (
    <Box sx={{ minWidth: "19rem", marginBottom: "1rem" }}>
      <Container>
        {currentUser?.avatar ? (
          <Avatar
            src={croppedImage}
            alt="Avatar"
            onClick={() => setOpen(true)}
          />
        ) : (
          <AvatarLoading>
            <CircularProgress
              className="update-avatar-card"
            />
          </AvatarLoading>
        )}
        <Box
          marginLeft="1rem"
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="center"
        >
          <input
            className="no-display-inputforfile"
            type="file"
            multiple
            accept="image/*"
            id="button-file"
            onChange={onImageChange}
            onClick={(event) => {
              event.target.value = null;
            }}
          />
          <label htmlFor="button-file">
            <Button variant="contained" component="span" color="secondary">
              {t("common_upload")}
            </Button>
          </label>
        </Box>
      </Container>

      <ImageCropDialog
        open={open}
        newImage={newImage}
        setCroppedImage={setCroppedImage}
        onAccept={() => {
          setOpen(false);
        }}
        onRevert={() => {
          setOpen(false);
          setNewImage(currentUser.avatar);
          setCroppedImage(currentUser.avatar);
        }}
      />
    </Box>
  );
};

const Avatar = styled("img")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "6rem",
  width: "6rem",
  borderRadius: ".5rem",
  "&:hover": {
    cursor: "pointer",
    backgroundColor: "#000",
    opacity: "0.7",
  },
}));

const AvatarLoading = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "6rem",
  width: "6rem",
  borderRadius: ".5rem",

  backgroundColor: theme.palette.background.default,
}));

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  flex: 1,
  flexDirection: "row",
  background: theme.palette.primary.dark,
  padding: "1rem",
  borderRadius: ".5rem",
}));
