import { useAxios } from "api/Axios";
import { useQuery } from "react-query";
import { useStatisticsStore } from "stores";

export const useHealthyPercentagesQuery = (params = {}) => {
  const axios = useAxios();

  const setHealthyPercentagesChartData = useStatisticsStore(
    (store) => store.setHealthyPercentagesChartData
  );
  const resetHealthyPercentagesChartData = useStatisticsStore(
    (store) => store.resetHealthyPercentagesChartData
  );

  const start = new Date();
  start.setHours(0, 0, 0, 0);

  const start_timestamp = params?.start_timestamp || start.getTime();
  const end_timestamp = params?.end_timestamp || new Date().getTime();
  const timezone =
    params?.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone;

  params = {
    start_timestamp,
    end_timestamp,
    timezone,
  };

  const query = useQuery(
    "/app/v1/statistics/healthy_percentages",
    () =>
      axios
        .get("/app/v1/statistics/healthy_percentages", {
          params,
        })
        .then((res) => res.data),
    {
      enabled: false,
      onSuccess: (data) => {
        setHealthyPercentagesChartData(data);
      },
      onError: () => {
        resetHealthyPercentagesChartData();
      },
    }
  );

  return query;
};
