import { useEffect, useState } from "react";
import * as yup from "yup";
import { setLocale } from "yup";
import { useTranslation } from "react-i18next";
import { styled, Dialog, Box, TextField } from "@mui/material";

import { classes } from "../../utils/customClasses";
import { useTagsStore } from "stores";
import { TreeSelect } from "./TreeSelect";
import { useMeasurementImageQuery } from "api/queries";
import { ShowImage } from "components/carousel/ShowImage";
import "../carousel/measurement.css";

const StyledDialog = styled(Dialog)(({ theme }) => {
  return {
    ".MuiDialog-paper": {
      maxWidth: "60rem",
      width: "60rem",
      display: "flex",
      justifyContent: "center",
    },
    ".MuiInputBase-root": {
      maxHeight: "32px",
      minHeight: "32px",
      color: "rgb(106, 124, 161)",
    },
    ".MuiOutlinedInput-notchedOutline": {
      border: "1px solid #cecee4",
    },
  };
});

export const EditMeasurementModal = (props) => {
  const {
    isOpen = false,
    handleClose = () => {},
    measurement,
    handleSubmit,
    tagsDictionary,
  } = props;

  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [description, setDescription] = useState("");
  const [editedTags, setEditedTags] = useState([]);
  const [error, setError] = useState("");
  const [zoom, setZoom] = useState(false);
  const { allTags } = useTagsStore();
  const { id } = measurement;
  const {
    refetch: refetchMeasurement,
    isLoading,
    data: measurementImage,
  } = useMeasurementImageQuery(id);

  useEffect(() => {
    if (id) refetchMeasurement();
  }, [refetchMeasurement, id]);

  setLocale({
    string: {
      max: t("measurement_edit_warning"),
    },
  });

  const schema = yup.object().shape({
    description: yup.string().max(300),
  });

  useEffect(() => {
    if (measurement) {
      setEditedTags(measurement.tag_measurements.map((tag) => tag.name));
      setDescription(measurement.description || "");
      setError("");
    }
  }, [measurement, currentLanguage, tagsDictionary]);

  const handleChange = (event) => {
    const { value } = event.target;
    schema
      .validate({ description: value })
      .then(setError(""))
      .catch((err) => {
        setError(err.errors);
      });
    setDescription(value);
  };

  return (
    <div>
      <StyledDialog open={isOpen} onClose={handleClose}>
        <Box style={customStyles.formMainBox}>
          <h2 className="plant-details-title">
            {t("measurements_edit_title")}
          </h2>
          {!isLoading && measurementImage && (
            <ShowImage
              item={measurementImage.data}
              setZoom={setZoom}
              zoom={zoom}
            />
          )}
          {!zoom && (
            <Box style={customStyles.formBox}>
              <div style={customStyles.inputContainer}>
                <div style={customStyles.inputDivLeft}>
                  {allTags && (
                    <TreeSelect
                      customStyles={customStyles}
                      setEditedTags={setEditedTags}
                      currentTags={measurement.tag_measurements.map(
                        (tag) => tag.name
                      )}
                    />
                  )}
                </div>
                <div style={customStyles.inputDivRight}>
                  <label style={customStyles.label}>
                    {t("measurements_headers_description")}
                  </label>
                  <TextField
                    type="text"
                    name="description"
                    value={description || ""}
                    onChange={handleChange}
                    error={error !== ""}
                    helperText={error !== "" ? error : ""}
                    variant="outlined"
                  />
                </div>
              </div>
            </Box>
          )}
        </Box>

        <div style={customStyles.buttonContainer}>
          <div
            style={customStyles.cancelar}
            onClick={() => {
              handleClose();
            }}
          >
            <p>{t("common_cancel")}</p>
          </div>
          <div
            disabled={error !== ""}
            style={customStyles.okButton}
            onClick={() => handleSubmit({ editedTags, description })}
          >
            <p>{t("common_confirm")}</p>
          </div>
        </div>
      </StyledDialog>
    </div>
  );
};

const customStyles = {
  formBox: {
    ...classes.classTopBox,
    marginTop: "5%",
  },
  formMainBox: {
    ...classes.formMainBox,
  },
  h2: {
    color: "#6a7ca1",
    textAlign: "center",
  },
  inputContainer: {
    ...classes.inputContainer,
    flexDirection: "row",
    borderBottom: "0",
  },
  inputDivLeft: {
    ...classes.inputDivPlus,
  },
  inputDivRight: {
    ...classes.inputDivPlus,
  },
  label: {
    fontWeight: "Bold",
  },

  buttonContainer: {
    ...classes.buttonContainerPlantModal,
  },
  okButton: {
    ...classes.okButton,
  },
  cancelar: {
    ...classes.cancelar,
  },
};
