import { Box, Typography } from "@mui/material";

export const ProfileSubsection = ({ title, children }) => {
  return (
    <Box
      flexBasis="30%"
      display="flex"
      flexDirection="column"
      gap="1.5rem"
      minWidth={280}
    >
      <Typography
        variant="h4"
        color="primary"
        fontWeight="500"
        fontSize="1.5rem"
      >
        {title}
      </Typography>

      {children}
    </Box>
  );
};
