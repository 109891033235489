import React, { useState } from "react";
import { NoSelectedElemetsModal } from "components/modals/NoSelectedElemetsModal";
import { useTranslation } from "react-i18next";

import DownloadIcon from "@mui/icons-material/Download";
import { downloadMeasurements } from "../../../services";
import { useMeasurementsStore } from "stores";

export const DownloadMeasurementsButton = () => {
  const { t } = useTranslation(); 
  const [noMeasurementOpen, setNoMeasurementOpen] = useState(false);
  const measurements = useMeasurementsStore(
    (store) => store.checkedMeasurements
  );
  const onClick = async () => {
    if (measurements.length > 0) {
      downloadMeasurements(measurements);
    } else setNoMeasurementOpen(true);
  };

  const handleNoMeasurementClose = () => {
    setNoMeasurementOpen(false);
  };

  return (
    <>
      <div onClick={onClick} className="cancelButton, measurementsButtons">
        <DownloadIcon></DownloadIcon>
        <p className="pl-08r">{t("measurements_DownloadMeasurementsButton_download")}</p>
      </div>
      <NoSelectedElemetsModal
        open={noMeasurementOpen}
        handleClose={handleNoMeasurementClose}
      />
    </>
  );
};
