import { DateRangePicker } from "@mantine/dates";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import "dayjs/locale/es";

import { PageContainer } from "components";
import { LineChartCard } from "./components/LineChartCard";
import { CurrentMetricsCard } from "./CurrentMetricsCard";

export const DashboardView = (props) => {
  const { t, i18n } = useTranslation();
  const {
    mainPercentages,
    totalUploadedImages,
    diseasePercentagesTags,
    totalNumberOfImagesUploadedChartData,
    healthyPercentagesChartData,
    dateRangeValue,
    setDateRangeValue,
  } = props;

  return (
    <PageContainer>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="100%"
        padding="1rem"
      >
        <Box
          display="flex"
          flexDirection="column"
          gap="1rem"
          maxWidth="1200px"
          width="100%"
        >
          <Box display="flex" gap="1rem">
            <CurrentMetricsCard
              mainPercentages={mainPercentages}
              totalUploadedImages={totalUploadedImages}
              diseasePercentagesTags={diseasePercentagesTags}
            />
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              gap="1rem"
              width="100%"
            >
              <Box
                display="flex"
                gap="1rem"
                alignItems="center"
                justifyContent="left"
              >
                <Typography
                  color="primary.gradientDark"
                  fontSize="1.2rem"
                  fontWeight={500}
                >
                  {t("dashboard_ChartsSection_dateFilter")}
                </Typography>
                <DateRangePicker
                  placeholder="Date range"
                  locale={i18n.language}
                  clearable={false}
                  style={{ minWidth: 280 }}
                  value={dateRangeValue}
                  onChange={setDateRangeValue}
                />
              </Box>
              <LineChartCard
                title={t("dashboard_ChartsSection_totalTitle")}
                data={totalNumberOfImagesUploadedChartData}
                valueLabel={t("dashboard_ChartsSection_total")}
              />
              <LineChartCard
                title={t("dashboard_ChartsSection_percentageTitle")}
                data={healthyPercentagesChartData.map(({ date, value }) => {
                  return { date, value: Math.round(value * 100) };
                })}
                valueLabel={t("dashboard_ChartsSection_percentage")}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </PageContainer>
  );
};
