import { Route, Switch, Redirect } from "react-router-dom";

import {
  Login,
  NotFound,
  Dashboard,
  Measurements,
  Plants,
  Profile,
  Records,
  Notifications,
} from "pages";
import PrivateRoute from "./PrivateRoute";

export const Router = () => {
  return (
    <Switch>
      <Redirect exact path="/" to="/profile" />
      <Route exact path="/login" component={Login} />
      <PrivateRoute exact path="/profile" component={Profile} />
      <PrivateRoute exact path="/dashboard" component={Dashboard} />
      <PrivateRoute exact path="/measurements" component={Measurements} />
      <PrivateRoute exact path="/plants" component={Plants} />
      <PrivateRoute exact path="/records" component={Records} />
      <PrivateRoute exact path="/notifications" component={Notifications} />
      <Route path="/notfound" component={NotFound} />
      <Redirect to="/notfound" />
    </Switch>
  );
};
