import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { styled, Dialog, Button, Box } from "@mui/material";

import { useUploadCSVMutation } from "api/mutations";
//css imports
import './addCSVModal.css';
import { classes } from '../../utils/customClasses';

const StyledDialog = styled(Dialog)(({ theme }) => {
  return {
    ".MuiDialog-paper": {
      maxWidth: "500px",
      width: "500px",
      textAlign: "center",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  };
});

const styles = {
  content: {
    ...classes.content,
    fontFamily: "Dosis",
  },
  h3: {
    ...classes.h3,
  },
  resLabel: {
    ...classes.resLabel,
  },
  okButton: {
    ...classes.okButton,
  },
  disabled: {
    ...classes.disabled,
  },
  cancelar: {
    ...classes.cancelar
  },
  errorBox: {
    ...classes.errorBox,
  },
  errorRow: {
    ...classes.errorRow,
  },
};

export const AddCSVModal = (props) => {
  const { t } = useTranslation();
  const { open = false, handleClose = () => {} } = props;
  const [isLoading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const { mutate: uploadCsv } = useUploadCSVMutation();
  const [res, setRes] = useState(null);

  const post = async () => {
    if (file) {
      setLoading(true);
      try {
        let response = await uploadCsv(file);
        setRes(response);
        handleClose();
      } catch (e) {
        console.error(e);
      }
      setLoading(false);
    }
  };

  const handleFileUpload = (event) => {
    if (event.target.files[0]) {
      if (
        event.target.value.substring(event.target.value.length - 4) === ".csv"
      ) {
        setFile(event.target.files[0]);
      } else {
        event.target.value = "";
        setFile(null);
      }
    } else {
      setFile(null);
    }
  };

  return (
    <div>
      <StyledDialog open={open} onClose={handleClose} style={styles}>
        {res ? (
          <>
            <h3 style={styles.resLabel}>
              {t("plants_AddCSVModal_success")} {res.rows_success}
            </h3>
            <h3 style={styles.resLabel}>
              {t("plants_AddCSVModal_fileErrors")} {res.parsing_errors.length}
            </h3>
            <div style={styles.errorBox}>
              {res.parsing_errors.map((e, key) => {
                return <div key={key} style={styles.errorRow}></div>;
              })}
            </div>
            <h3 style={styles.resLabel}>{t("plants_AddCSVModal_ohterErrors")} {res.errors.length}</h3>
            <div style={styles.errorBox}>
              {res.errors.map((e, key) => {
                return (
                  <div key={key} style={styles.errorRow}>
                    {t("plants_AddCSVModal_row")} {e.line_number} - {e.detail}
                  </div>
                );
              })}
            </div>
            <div className="accept-cancel-buttons-container"
            >
              <div
                style={styles.cancelar}
                onClick={() => {
                  setRes(null);
                  setFile(null);
                }}
              >
                <p style={styles.p}>{t("common_cancel")}</p>
              </div>
              <div
                style={styles.okButton}
                onClick={() => {
                  setRes(null);
                  setFile(null);
                }}
              >
                <p style={styles.p}>{t("common_accept")}</p>
              </div>
            </div>
          </>
        ) : (
          <>
            <h3 style={styles.h3}>{t("plants_CsvButton_upload")}</h3>
            <input
              className="csv-upload-input"
              type="file"
              id="upload-csv-button"
              onChange={handleFileUpload}
              onClick={(event) => {
                event.target.value = null;
              }}
            />
            <label htmlFor="upload-csv-button">
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                gap="1rem"
              >
                <Button variant="contained" component="span" color="secondary">
                  {t("plants_CsvButton_upload")}
                </Button>
                <p>{file?.name || t("plants_AddCSVModal_file")}</p>
              </Box>
            </label>

            <div className="cancel-button"
            >
              <div
                style={styles.cancelar}
                onClick={() => {
                  setRes(null);
                  setFile(null);
                  handleClose();
                }}
              >
                <p style={styles.p}>{t("common_cancel")}</p>
              </div>
              <div
                style={file ? styles.okButton : styles.disabled}
                onClick={file ? post : null}
              >
                <p style={styles.p}>{isLoading ? t("common_loading") : t("common_confirm")}</p>
              </div>
            </div>
          </>
        )}
      </StyledDialog>
    </div>
  );
};
