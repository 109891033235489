import "./progressbar.css";
import { useTranslation } from "react-i18next";
import { getKeyByValue } from "utils/traslate";

const ProgressBar = (props) => {
  const { predictions } = props;
  const { t } = useTranslation();

  const predictionsMapped = predictions.find((pred) => {
    if (pred.name === "disease" || pred.name === "healthy") {
      return pred;
    }
  });

  if (predictionsMapped === undefined) {
    return <div></div>;
  }

  const customStyles = {
    barWidth: {
      width: predictionsMapped.probability + "%",
      paddingTop: "3px",      
    },
  };

  const progression = `${t(getKeyByValue(predictionsMapped.name))} ${
    predictionsMapped.probability + " %"
  }`;
  return (
    <div className="bg-pbar">
      <div
        className={`p-bar-${predictionsMapped.name} p-bar-common`}
        style={customStyles.barWidth}
      >
        <span className={`p-bar-text text-${predictionsMapped.name}`}>{progression}</span>
      </div>
    </div>
  );
};

export default ProgressBar;
