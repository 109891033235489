import { Box } from "@mui/material";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";

import "./measurement.css";
import { classes } from "../../utils/customClasses";
import { ShowImage } from "components/carousel/ShowImage";
import { useState } from "react";
import ProgressBar from "components/ProgressBar";

export const Measurement = (props) => {
  const { t } = useTranslation();
  const { item, currentMeasurement, prediction } = props;
  const [zoom, setZoom] = useState(false);

  /* console.log(item) */

  return (
    <Box display="flex" flexDirection="column" width="80%" margin="auto">
      <h2 className="plant-details-title">{t("measurement_details_title")}</h2>
      <ShowImage
        item={item}
        measurement={currentMeasurement}
        setZoom={setZoom}
        zoom={zoom}
      />
      {!zoom && (
        <div className="measurement-table-container">
          <div style={customStyles.inputContainer}>
            <div style={customStyles.inputDivLeft}>
              {t("measurements_headers_custom_id")}
              <label style={customStyles.label}>{item.custom_id}</label>
            </div>
            <div style={customStyles.inputDivRight}>
              {t("measurements_headers_timestamp")}
              <label style={customStyles.label}>
                {DateTime.fromMillis(item.timestamp).toFormat("yyyy-MM-dd")}
              </label>
            </div>
            <div style={customStyles.inputDivLeft}>
              {t("measurements_headers_development_state")}
              <label style={customStyles.label}>{item.development_state}</label>
            </div>
            <div style={customStyles.inputDivRight}>
              {t("measurements_headers_salubrity")}
              <div style={customStyles.pgBar}>
                <ProgressBar
                  component="span"
                  predictions={
                    prediction ? prediction : item.prediction_measurements
                  }
                />
              </div>
            </div>
            <div style={customStyles.inputDivLeft}>
              {t("measurements_headers_tags")}
              <label style={customStyles.label}>{item.tags}</label>
            </div>
            <div style={customStyles.inputDivRight}>
              {t("measurements_headers_description")}
              <label style={customStyles.label}>{item.description}</label>
            </div>
          </div>
        </div>
      )}
    </Box>
  );
};

const customStyles = {
  h3: {
    color: "#6a7ca1",
  },
  inputContainer: {
    ...classes.inputContainer,
  },
  inputDivLeft: {
    ...classes.inputDivTopBorder,
    width: "45%",
  },
  inputDivRight: {
    ...classes.inputDivTopBorder,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "50%",
  },
  pgBar: {
    width: "25%",
  },
  label: {
    ...classes.label,
  },
};
