import create from "zustand";
import { devtools } from "zustand/middleware";

const today = new Date();
today.setHours(0, 0, 0, 0);

const sevenDaysAgo = new Date(today.getTime());
sevenDaysAgo.setHours(today.getHours() - 6 * 24);

export const useStatisticsStore = create(
  devtools(
    (set) => ({
      coincidenciesTagPrediction: [],
      setCoincidenciesTagPrediction: (data) =>
      set((state) => ({ coincidenciesTagPrediction: data })),
      resetCoincidenciesTagPrediction: () =>
      set((state) => ({coincidenciesTagPrediction: []})),

      discrepanciesTagsPredicions: [],
      setDiscrepanciesTagsPredicions: (data) =>
      set((state) => ({ discrepanciesTagsPredicions: data })),
      resetDiscrepanciesTagsPredicions: () =>
      set((state) => ({discrepanciesTagsPredicions: []})),

      mainPercentages: {},
      setMainPercentages: (mainPercentages) =>
        set((state) => ({ mainPercentages })),
      resetMainPercentages: () =>
        set((state) => ({
          disease: 0,
          healthy: 0,
          untagged: 0,
        })),

      totalUploadedImages: 0,
      setTotalUploadedImages: (totalUploadedImages) =>
        set((state) => ({
          totalUploadedImages,
        })),
      resetTotalUploadedImages: () =>
        set((state) => ({
          totalUploadedImages: 0,
        })),

      diseasePercentagesTags: [],
      setDiseasePercentagesTags: (diseasePercentagesTags) =>
        set((state) => ({ diseasePercentagesTags })),
      resetDiseasePercentagesTags: () =>
        set((state) => ({ diseasePercentagesTags: [] })),

      totalNumberOfImagesUploadedChartData: [],
      setTotalNumberOfImagesUploadedChartData: (
        totalNumberOfImagesUploadedChartData
      ) => set((state) => ({ totalNumberOfImagesUploadedChartData })),
      resetTotalNumberOfImagesUploadedChartData: () =>
        set((state) => ({ totalNumberOfImagesUploadedChartData: [] })),

      healthyPercentagesChartData: [],
      setHealthyPercentagesChartData: (healthyPercentagesChartData) =>
        set((state) => ({ healthyPercentagesChartData })),
      resetHealthyPercentagesChartData: () =>
        set((state) => ({ healthyPercentagesChartData: [] })),

      timeInterval: {
        start_timestamp: sevenDaysAgo.getTime(),
        end_timestamp: today.getTime(),
      },
      setTimeInterval: (start_timestamp, end_timestamp) =>
        set((state) => ({
          timeInterval: {
            start_timestamp,
            end_timestamp,
          },
        })),
    }),
    { name: "StatisticsStore " }
  )
);
