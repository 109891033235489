import { saveAs } from "file-saver";
var JSZip = require("jszip");

export const downloadQR = async (plants) => {
  var zip = new JSZip();

  let QRs = [];
  plants.forEach((plant) => {
    try {
      QRs.push(plant.qr);
      zip.file(
        "QR - " + plant.custom_id + ".png",
        plant.qr.replace("data:image/png;base64,", ""),
        { base64: true }
      );
    } catch (e) {
      console.error(e);
    }
  });

  zip.generateAsync({ type: "blob" }).then(function (content) {
    saveAs(content, "QRs.zip");
  });
};
