import { IconButton } from "@mui/material";
import { Beenhere, MarkAsUnread, ZoomIn } from "@mui/icons-material";

export const ActionButtons = (props) => {
  const {
    cellValues,
    setSelectedMeasurement,
    setOpenDetailsModal,
    markNotificationsAsRead,
  } = props;

  return (
    <>
      <IconButton
        color="primary"
        component="span"
        onClick={() => {
          setSelectedMeasurement(cellValues.row);
          setOpenDetailsModal(true);
          markNotificationsAsRead({
            notifications: [cellValues.row.id],
            read: true,
          });
        }}
      >
        <ZoomIn />
      </IconButton>
      <IconButton
        color="primary"
        component="span"
        onClick={() => {
          markNotificationsAsRead({
            notifications: [cellValues.row.id],
            read: true,
          });
        }}
      >
        <Beenhere />
      </IconButton>
      <IconButton
        color="primary"
        component="span"
        onClick={() => {
          markNotificationsAsRead({
            notifications: [cellValues.row.id],
            read: false,
          });
        }}
      >
        <MarkAsUnread />
      </IconButton>
    </>
  );
};
