import { useEffect, useState } from "react";

import { PlantsView } from "./PlantsView";
import { usePlantsStore, useFilterStore } from "stores";
import { useSortingStore } from "stores/useSortingStore";
import { useFilteredPlantsQuery } from "api/queries";

export const PlantsContainer = () => {
  const {
    filteredPlants,
    currentPage,
    setCurrentPage,
    totalPlants,
    setCheckedPlants,
  } = usePlantsStore();
  const { sortingValue, setSortingValue } = useSortingStore();
  const [selectionModel, setSelectionModel] = useState([]);
  const [sortedValue, setSorting] = useState([]);
  const { dropdownPlantFilter, lotFilter } = useFilterStore();
  const { refetch: refetchPlants } = useFilteredPlantsQuery();

  useEffect(() => {
    setCheckedPlants(selectionModel);
  }, [selectionModel, setCheckedPlants]);

  useEffect(() => {
    setSortingValue(sortedValue, 'plants');
  }, [sortedValue, setSortingValue]);

  useEffect(() => {
    refetchPlants();
  }, [sortingValue, dropdownPlantFilter, lotFilter, currentPage, refetchPlants]);

  return (
    <PlantsView
      filteredPlants={filteredPlants}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      totalPlants={totalPlants}
      setSelectionModel={setSelectionModel}
      setSorting={setSorting}
    />
  );
};
