import { useAxios } from "api/Axios";
import { useMutation, useQueryClient } from "react-query";
import { useMessagesStore } from "stores";

export const useUploadCSVMutation = (csv) => {
  const axios = useAxios();

  const queryClient = useQueryClient();

  const addMessage = useMessagesStore((store) => store.addMessage);

  const mutation = useMutation(
    (csv) => {
      const body = new FormData();
      body.append("csv_file", csv);
      const options = {
        method: "POST",
        data: body,
        url: "/app/v1/plants/uploadfile",
      };

      return axios(options).then((res) => res.data);
    },
    {
      onSuccess: (data) => {
        addMessage("Successfully uploaded");

        queryClient.fetchQuery({ queryKey: "filteredPlants" });
        queryClient.fetchQuery({ queryKey: "/app/v1/plants/lotsAndIds" });
      },
    }
  );

  return mutation;
};
