import { Box, Paper, Typography } from "@mui/material";
import getUserLocale from "get-user-locale";
import { useEffect, useState } from "react";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

export const LineChartCard = (props) => {
  const { title = "", valueLabel = "value", data } = props;

  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    const newChartData = data.map((point) => {
      let customPoint = {};
      customPoint[`${valueLabel}`] = point.value;
      customPoint[`date`] = new Date(point.date).toLocaleDateString(
        getUserLocale(),
        {
          month: "numeric",
          day: "numeric",
        }
      );
      return customPoint;
    });

    setChartData(newChartData);
  }, [data, valueLabel]);

  return (
    <Paper className="lineChartCard-paper">
      <Box display="flex" flexDirection="column" padding="1rem" width="100%">
        {title && (
          <Typography
            color="primary.gradientDark"
            component="span"
            variant="body"
            paddingX="1rem"
            paddingBottom="1rem"
          >
            {title}
          </Typography>
        )}
        <ResponsiveContainer width="100%" height={250}>
          <LineChart
            data={chartData}
            margin={{
              top: 5,
              bottom: 5,
              right: 30,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" interval="preserveStartEnd" />
            <YAxis></YAxis>
            <Tooltip />
            <Line
              type="monotone"
              dataKey={valueLabel}
              stroke="#c6d600"
              activeDot={{ r: 4 }}
              dot={{ strokeWidth: 1, r: 2, strokeDasharray: "" }}
            />
          </LineChart>
        </ResponsiveContainer>
      </Box>
    </Paper>
  );
};
