import { useEffect, useState } from "react";
import Carousel from "react-material-ui-carousel";
import { Box } from "@mui/system";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import { useTranslation } from "react-i18next";

import { useMeasurementsStore } from "stores";
import { useMeasurementsWithImagesQuery } from "api/queries";
import { Measurement } from "./Measurement";
import "./show-measurements-carousel.css";

export const ShowMeasurementsCarousel = (props) => {
  const { t } = useTranslation();
  const {
    checkedMeasurements,
    measurementsWithImages,
    setMeasurementsWithImages,
  } = useMeasurementsStore();
  const { refetch: refetchMeasurements } =
    useMeasurementsWithImagesQuery(checkedMeasurements);
  useEffect(() => {
    refetchMeasurements();
    return () => {
      setMeasurementsWithImages([]);
    };
  }, [refetchMeasurements, setMeasurementsWithImages]);

  useEffect(() => {
    if (measurementsWithImages?.length) {
      setCurrentMeasurement(measurementsWithImages[0]);
    }
  }, [measurementsWithImages]);

  const [currentMeasurement, setCurrentMeasurement] = useState();

  const carouselOnChange = (now) => {
    setCurrentMeasurement(measurementsWithImages[now]);
  };

  if (measurementsWithImages.length === 0) {
    return (
      <div className="measurement-image-container">
        <Box>
          <h2 className="measurement-loading">
            {t("measurements_ShowMeasurement_loading")}
          </h2>
        </Box>
        <Stack
          sx={{ color: "#6f7e90", justifyContent: "center" }}
          spacing={2}
          direction="row"
        >
          <CircularProgress color="inherit" />
        </Stack>
      </div>
    );
  } else if (measurementsWithImages.length === 1) {
    return (
      <Carousel
        indicators={false}
        swipe={false}
        navButtonsProps={{
          style: {
            display: "none",
          },
        }}
      >
        {measurementsWithImages.map((item, i) => (
          <Measurement key={i} item={item} />
        ))}
      </Carousel>
    );
  }
  return (
    <Carousel
      autoPlay={false}
      animation="slide"
      swipe={false}
      navButtonsAlwaysVisible={true}
      duration={500}
      onChange={carouselOnChange}
    >
      {measurementsWithImages.map((item, i) => (
        <Measurement
          key={i}
          item={item}
          currentMeasurement={currentMeasurement}
        />
      ))}
    </Carousel>
  );
};
