import React, { useState } from "react";
import { NoSelectedElemetsModal } from "components/modals/NoSelectedElemetsModal";

import { downloadQR } from "../../../services";
import { usePlantsStore } from "stores";
export const DownloadQRButton = () => {
  const plants = usePlantsStore((store) => store.checkedPlants);
  const onClick = async () => {
    if (plants.length > 0) downloadQR(plants);
    else setOpen(true);
  };
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  return (
    <>
      <div onClick={onClick} className="cancelButton, plantButtons">
        QRs
      </div>
      <NoSelectedElemetsModal
        open={open}
        handleClose={handleClose}
        elementType="plants"
      />
    </>
  );
};
