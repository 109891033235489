import create from "zustand";
import { devtools } from "zustand/middleware";

export const useSortingStore = create(
    devtools(
      (set) => ({
        sortingValue: [],
        sortingPage: '',
        setSortingValue: (sortedV, sortP) => set((state) => ({ sortingValue: sortedV, sortingPage: sortP })),
      }),
      { name: "SortingStore" }
    )
  );