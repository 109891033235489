import create from "zustand";
import { devtools } from "zustand/middleware";

export const useNotificationsStore = create(
  devtools(
    (set) => ({
      filteredNotifications: [],
      setFilteredNotifications: (filteredNotifications) =>
        set((state) => ({ filteredNotifications })),

      checkedNotifications: [],
      setCheckedNotifications: (selectedNotificationsIds) =>
        set((state) => ({
          checkedNotifications: state.filteredNotifications.filter((notification) =>
            selectedNotificationsIds.includes(notification.id)
          ),
        })),
      resetCheckedNotifications: () => set((state) => ({ checkedNotifications: [] })),

      totalNotifications: 0,
      setTotalNotifications: (totalNotifications) => set((state) => ({ totalNotifications })),

      sortingValue: [],
      setSortingValue: (sortedV) => set((state) => ({ sortingValue: sortedV })),
      
      unreadNotifs: 0,
      setUnreadNotifs: (queryData) =>
       set((state) => ({ unreadNotifs: queryData })),
      
      currentPage: 0,
      setCurrentPage: (currentPage) => set((state) => ({ currentPage })),
      resetCurrentPage: () => set((state) => ({ currentPage: 0 })),
    }),
    { name: "NotificationsStore" }
  )
);
