import { Paper, Typography, Box } from "@mui/material";
import { useTranslation } from "react-i18next";

import { IndicatorsForm } from "./IndicatorsForm";

export const IndicatorsCard = (props) => {
  const { t } = useTranslation();
  const { onSubmitIndicators, initialIndicators, currentUser, isLoadingIndicators } = props;

  return (
    <Paper sx={{ width: "100%" }}>
      <Box
        padding="1rem"
        display="flex"
        flexDirection="column"
        width="100%"
        alignItems="center"
      >
        <Typography variant="h6" color="primary" className="profile-indicator-title">
          {t("profile_IndicatorsCard_title")}
        </Typography>
        <IndicatorsForm
          currentUser={currentUser}
          onSubmitIndicators={onSubmitIndicators}
          initialIndicators={initialIndicators}
          isLoadingIndicators={isLoadingIndicators}
        />
      </Box>
    </Paper>
  );
};
