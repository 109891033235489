import React, { useState } from "react";
import { NoSelectedElemetsModal } from "components/modals/NoSelectedElemetsModal";

import DownloadIcon from "@mui/icons-material/Download";
import { downloadRecords } from "../../../services";
import { useRecordsStore } from "stores";
import { useTranslation } from "react-i18next";

export const DownloadRecordsButton = () => {
  const { t, i18n } = useTranslation();
  const [noRecordOpen, setNoRecordOpen] = useState(false);
  const records = useRecordsStore((store) => store.checkedRecords);
  const onClick = async () => {
    if (records.length > 0) {
      downloadRecords(records);
    } else setNoRecordOpen(true);
  };

  const handleNoRecordClose = () => {
    setNoRecordOpen(false);
  };

  return (
    <>
      <div
        onClick={onClick}
        className="cancelButton recordsButtons ml-null show-imgbtns"
      >
        <DownloadIcon></DownloadIcon>
        <p className="pl-08r">{t("records_RecordsTable_downloadButton")}</p>
      </div>
      <NoSelectedElemetsModal
        open={noRecordOpen}
        handleClose={handleNoRecordClose}
        elementType="records"
      />
    </>
  );
};
