import { Box } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ProfileSubsection } from "./ProfileSubsection";
import { UpdateAvatarCard } from "./UpdateAvatarCard";
import { MyTextField } from "components/inputs";

export const ProfileSection = (props) => {
  const { t, i18n } = useTranslation();
  const {
    currentUser,
    croppedImage,
    setCroppedImage,
    onProfileSave,
    isLoadingProfile,
  } = props;

  const schema = yup.object({
    password: yup.string(),
    passwordConfirmation: yup
    .string()
    .oneOf([yup.ref("password"), null], "profile_ProfileSection_passError"),
    email: yup.string().email("profile_ProfileSection_emailError"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  return (
    <Box width={"90%"} margin="auto">
      <form noValidate onSubmit={handleSubmit(onProfileSave)}>
        <Box display="flex" flexWrap="wrap" gap="2rem">
          <ProfileSubsection title={t("profile_ProfileSection_passwordTitle")}>
            <Box>
              <MyTextField
                variant="outlined"
                required
                fullWidth
                size="small"
                id="password"
                type="password"
                label={t("common_profile_passwordLabel")}
                autoComplete="current-password"
                disabled={isLoadingProfile}
                {...register("password")}
                error={!!errors.password}
                helperText={errors.password ? errors.password?.message : " "}
              />

              <MyTextField
                variant="outlined"
                required
                fullWidth
                size="small"
                id="passwordConfirmation"
                type="password"
                label={t("profile_ProfileSection_confirmPassLabel")}
                autoComplete="current-password"
                disabled={isLoadingProfile}
                {...register("passwordConfirmation")}
                error={!!errors.passwordConfirmation}
                helperText={
                  errors.passwordConfirmation
                    ? t(errors.passwordConfirmation?.message)
                    : " "
                }
              />
            </Box>
          </ProfileSubsection>
          <ProfileSubsection title={t("profile_ProfileSection_emailTitle")}>
            <Box>
              <MyTextField
                variant="outlined"
                required
                fullWidth
                size="small"
                id="email"
                type="email"
                defaultValue={currentUser?.email}
                label={t("profile_ProfileSection_emailLabel")}
                disabled={isLoadingProfile}
                {...register("email")}
                error={!!errors.email}
                helperText={errors.email ? t(errors.email?.message) : " "}
              />
            </Box>
          </ProfileSubsection>
          <ProfileSubsection title={t("common_profile_profilePicture")}>
            <UpdateAvatarCard
              currentUser={currentUser}
              croppedImage={croppedImage}
              setCroppedImage={setCroppedImage}
            />
          </ProfileSubsection>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center"}}>
          <LoadingButton
            type="submit"
            variant="contained"
            color="secondary"
            loading={isLoadingProfile}
            sx={{ width: 90 }}
          >
            {t("common_save")}
          </LoadingButton>
        </Box>
      </form>
    </Box>
  );
};
