import { useEffect } from "react";
import { styled, Autocomplete, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

import { usePlantsStore, useFilterStore } from "stores";
import { usePlantFiltersQuery } from "api/queries";
import "./components.css";

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => {
  return {
    ".MuiOutlinedInput-notchedOutline": {
      borderWidth: "0px",
    },
  };
});

export const DropPlantLot = () => {
  const { t } = useTranslation();
  const { refetch: refetchFilters } = usePlantFiltersQuery();
  const { setDropdownLotFilter, resetDropdownLotFilter } = useFilterStore();
  const { filtersData } = usePlantsStore();
  const userPlantLots = filtersData.lots;

  useEffect(() => {
    refetchFilters();
  }, [refetchFilters]);
  useEffect(() => {
    return () => {
      // Cleanup function -> this is called when the UI is destroyed
      resetDropdownLotFilter();
    };
  }, [resetDropdownLotFilter]);
  const onPlantLotchange = (selectedPlantLot) => {
    setDropdownLotFilter(selectedPlantLot);
  };
  return (
    <>
      {userPlantLots && (
        <StyledAutocomplete
          disablePortal
          id="lotDropdown"
          onInputChange={(event, selectedLot) => {
            onPlantLotchange(selectedLot);
          }}
          options={userPlantLots}
          noOptionsText={t("plants_DropPlantLot_noLots")}
          sx={{
            width: 300,
            backgroundColor: "white",
            border: "#c4c4c4",
            borderWidth: "1px",
            borderStyle: "solid",
            borderRadius: "4px",
          }}
          renderInput={(params) => <TextField {...params} label={t("plants_DropPlantLot_label")} />}
        />
      )}
    </>
  );
};
