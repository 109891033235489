import * as XLSX from "xlsx";
import _ from "lodash";
export const buildXLSX = async (dataToExport, customHeaders, fileName) => {
  const fields = Object.keys(customHeaders);
  const pickedData = dataToExport.map((elem) => {
    const pickedElem = _.pick(elem, fields);
    let newElem = {};
    Object.keys(pickedElem).forEach((field) => {
      newElem[customHeaders[field]] = pickedElem[field];
    });
    return newElem;
  });
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

  const ws = XLSX.utils.json_to_sheet(pickedData);
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  return data;
};
