//general imports
import { useHistory } from "react-router-dom";
import React, { useEffect } from "react";
//comps imports
import { ProfileCard } from "./ProfileCard";
import { SidebarItem } from "./SidebarItem";
//store imports
import { useNotificationsStore } from "stores";
//query imports
import { useUnreadNotificationsQuery } from "../../api/queries/useUnreadNotificationsQuery";
//css import
import "./sidebar.css";
//icons imports
import {
  faSeedling,
  faSignal,
  faProjectDiagram,
  faHistory,
  faBell,
} from "@fortawesome/free-solid-svg-icons";
import LogoFondoOscuro from "assets/images/logotipo/transparente/logo_fondo_negro.png";
//other
import { LogoutButton } from "./LogoutButton";
import { useTranslation } from "react-i18next";
import { LanguageToggler } from "./LanguageToggler";

export const Sidebar = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { filteredNotifications } = useNotificationsStore();
  const { refetch: refetchUnreadNotifications } = useUnreadNotificationsQuery();

  const goTo = (pathname) => {
    history.push({ pathname });
  };

  useEffect(() => {
    refetchUnreadNotifications();
  }, [filteredNotifications, refetchUnreadNotifications]);

  return (
    <div className="container">
      <div className="logo-container">
        <img
          className="logo-daiana"
          src={LogoFondoOscuro}
          alt="Daiana Logo"
        ></img>
      </div>
      <div className="profilecard-container">
        <ProfileCard />
      </div>
      <SidebarItem
        icon={faSignal}
        text={t("common_Sidebar_dashboard")}
        selected={history.location.pathname.toLowerCase() === "/dashboard"}
        onClick={() => goTo("/dashboard")}
      />
      <SidebarItem
        icon={faProjectDiagram}
        text={t("common_Sidebar_measurements")}
        selected={history.location.pathname.toLowerCase() === "/measurements"}
        onClick={() => goTo("/measurements")}
      />
      <SidebarItem
        icon={faSeedling}
        text={t("common_Sidebar_plants")}
        selected={history.location.pathname.toLowerCase() === "/plants"}
        onClick={() => goTo("/plants")}
      />

      <SidebarItem
        icon={faHistory}
        text={t("common_Sidebar_records")}
        selected={history.location.pathname.toLowerCase() === "/records"}
        onClick={() => goTo("/records")}
      />

      <SidebarItem
        icon={faBell}
        text={t("common_Sidebar_notifications")}
        selected={history.location.pathname.toLowerCase() === "/notifications"}
        onClick={() => goTo("/notifications")}
      />
      <div className="logout-container" />
      <LogoutButton />
      <div className="languagetoggler-container">
        <LanguageToggler />
      </div>
    </div>
  );
};
